@use "sass:math"; @import "variables.scss";
.notification {
  width: 400px;
  box-sizing: border-box;
}

.content {
  margin-bottom: grid-gap(0.5);
}

@include media(">=screen-xs") {
  .cancelModal {
    min-width: 590px;
    padding: grid-gap(1.5);
  }
}
