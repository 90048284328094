@use "sass:math"; @import "variables.scss";
.pageWrapper {
  height: 100%;
  background-color: $color-neutrals-100-white;
}

.listWrapper {
  @extend %wrapper;
}

.listHeader {
  color: $color-neutrals-800-dark;
  font-size: 28px;
  line-height: 32px;
  font-weight: $font-weight-semi-bold;
  padding-top: grid-gap(4.125);
  padding-bottom: grid-gap(3);
}

.listPagination {
  justify-content: center;
  margin-top: grid-gap(1.75);
}

.headerRow,
.bodyRow {
  padding: grid-gap(1.5) 0;
  border-bottom: 1px solid $color-neutrals-300-warm-grey;
  margin: 0;
  justify-content: space-between;

  th,
  td {
    padding: 0;
  }
}

.headerRow {
  grid-template-columns: 184px 88px;
}

.bodyRow {
  grid-template-columns: 184px 88px;
  border-radius: 0;
}

@include media(">=screen-xs") {
  .headerRow {
    grid-template-columns: 232px 145px 88px;
  }

  .bodyRow {
    grid-template-columns: 232px 145px 88px;
  }
}

@include media(">=screen-sm") {
  .headerRow {
    grid-template-columns: 232px 145px 152px 88px;
  }

  .bodyRow {
    grid-template-columns: 232px 145px 152px 88px;
  }
}

@include media(">=screen-md") {
  .headerRow {
    grid-template-columns: 280px 145px 120px 152px 138px;
  }

  .bodyRow {
    grid-template-columns: 280px 145px 120px 152px 138px;
  }
}

@include media(">=screen-lg") {
  .headerRow {
    grid-template-columns: 280px 145px 120px 152px 152px 138px;
  }

  .bodyRow {
    grid-template-columns: 280px 145px 120px 152px 152px 138px;
  }
}

@include media(">=screen-xl") {
  .listTable table {
    padding: 0 grid-gap(2);
  }
}
