@use "sass:math"; @import "variables.scss";
.timeAxis {
  background-color: $color-white;
  display: grid;
  position: sticky;
  top: 0;
  z-index: 4;
  border-bottom: solid 1px $color-neutrals-400-cold-grey;
  padding-left: 47px;
  padding-right: 0;
}
