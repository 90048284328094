@use "sass:math"; @import "variables.scss";
.button.backButton.onlyIcon {
  span[class^="icon__"] {
    margin-right: 0;
  }
}

@include media(">=screen-xs") {
  .button.backButton.onlyIcon {
    span[class^="icon__"] {
      display: none;
    }
  }
}
