@use "sass:math"; @import "variables.scss";
.imageEditorBox {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: grid-gap(0.5);
  overflow: hidden;
  flex: 1;

  &Content {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    gap: grid-gap(0.5);
    width: 100%;
    padding: grid-gap(0.75);
    padding-bottom: 0;
    flex: 1;
  }

  &Preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;
    flex: 1;
    position: relative;
    background-color: $color-brand-25-blue;
    transition: background-color 0.2s ease-in-out;

    &Active {
      background-color: $color-brand-50-blue;
    }

    >[class^="actionButtons"] {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover > [class^="actionButtons"] {
      opacity: 1;
    }

    @include media(">=screen-md") {
      width: 391px;
    }

    &UploadButton {
      margin: grid-gap(1.5) 0;

      @include media(">=screen-xs") {
        margin: grid-gap(3.5) 0;
      }

      @include media(">=screen-md") {
        margin: grid-gap(1.5) 0;
      }
    }
  }
}

.additionalInfoAboutImage {
  margin-top: grid-gap(1);
}

.actionButtons {
  display: flex;
  gap: grid-gap(0.5);
  margin-top: grid-gap(0.5);
}

.previewButtonIcon {
  g {
    stroke: $color-brand-accent-blue;
  }
}

.trashButton {
  div[class*="ripple"] {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 grid-gap(0.5) !important;

    span {
      display: flex;
      align-items: center;
    }
  }
}

.errorNotification {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: grid-gap(0.5);
  background-color: $color-warning-100;

  aside[class*="customIcon"] {
    background-color: $color-warning-100;
  }
}

.dashedBorder {
  width: 100%;
  box-sizing: border-box;
  background-image: repeating-linear-gradient(32deg, $color-cold-grey, $color-cold-grey 8px, transparent 8px, transparent 15px, $color-cold-grey 15px), repeating-linear-gradient(122deg, $color-cold-grey, $color-cold-grey 8px, transparent 8px, transparent 15px, $color-cold-grey 15px), repeating-linear-gradient(212deg, $color-cold-grey, $color-cold-grey 8px, transparent 8px, transparent 15px, $color-cold-grey 15px), repeating-linear-gradient(302deg, $color-cold-grey, $color-cold-grey 8px, transparent 8px, transparent 15px, $color-cold-grey 15px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.dashedBorderActive {
  background-image: repeating-linear-gradient(32deg, $color-brand-600-primary-blue, $color-brand-600-primary-blue 8px, transparent 8px, transparent 15px, $color-brand-600-primary-blue 15px), repeating-linear-gradient(122deg, $color-brand-600-primary-blue, $color-brand-600-primary-blue 8px, transparent 8px, transparent 15px, $color-brand-600-primary-blue 15px), repeating-linear-gradient(212deg, $color-brand-600-primary-blue, $color-brand-600-primary-blue 8px, transparent 8px, transparent 15px, $color-brand-600-primary-blue 15px), repeating-linear-gradient(302deg, $color-brand-600-primary-blue, $color-brand-600-primary-blue 8px, transparent 8px, transparent 15px, $color-brand-600-primary-blue 15px);
}

.toolboxInPreview {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  margin-top: 0;

  @include media(">=screen-sm") {
    display: flex;
  }
}

.toolboxOutsidePreview {
  display: flex;

  @include media(">=screen-sm") {
    display: none;
  }
}
