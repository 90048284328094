@use "sass:math"; @import "variables.scss";
.packageBox {
  width: 100%;
  display: flex;
  border: 1px solid $color-brand-100-blue;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: grid-gap(0.5);
  position: relative;
}

.selected {
  border: 1px solid $color-brand-600-primary-blue;
}

.disabled {
  cursor: default;
  opacity: 0.35;
}

.iconSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 56px;
  background-color: $color-brand-100-blue;
  border-radius: 6px 0 0 6px;
}

.iconLabel {
  font-size: $font-size-small;
  line-height: 16px;
  color: $color-brand-accent-blue;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: grid-gap(0.75) grid-gap(1);
  color: $color-neutrals-800-dark;

  @include media(">=screen-xs") {
    flex-direction: row;
  }
}

.reach {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  line-height: 24px;
  margin-bottom: grid-gap(0.125);
}

.impressions {
  @include source-sans-pro("regular");

  font-size: $font-size-normal;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: grid-gap(0.25);
}

.price {
  @include source-sans-pro("regular");

  display: flex;
  align-items: flex-end;
  font-size: $font-size-large;
  line-height: 24px;
  margin-top: grid-gap(0.5);
}

.svg {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 70px;
  height: 70px;
}

.text {
  position: absolute;
  transform: rotate(45deg);
  top: 18px;
  right: -6px;
  width: 60px;
  text-align: center;
  font-size: $font-size-extra-small;
  line-height: 13px;
  color: $color-brand-500-green;
}

.loader {
  margin-bottom: grid-gap(0.5);
}
