@use "sass:math"; @import "variables.scss";
.tooltip {
  .notification {
    margin-top: grid-gap(0.25);

    &.fullSize {
      max-width: none;
    }
  }
}
