@use "sass:math"; @import "variables.scss";
.button {
  position: fixed;
  right: 414px;
  top: grid-gap(2);
  z-index: 9999;
  width: 40px;
  box-sizing: border-box;
  box-shadow: -1px 4px 9px rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);

  &.btnCloseWhite {
    background-color: $color-white;
  }

  svg {
    width: 10px;
    height: 10px;

    g {
      stroke: $color-brand-600-primary-blue;
      stroke-width: 3px;
    }
  }
}
