@use "sass:math"; @import "variables.scss";
.switcher {
  display: flex;
  align-items: center;
  margin-top: grid-gap(1);
  position: relative;
  z-index: 2;

  &.modalMode {
    height: 56px;
    padding: 0 grid-gap(1);
    margin: grid-gap(1.5) 0;
    background: $color-neutrals-100-white;
    border-radius: 6px;
  }
}

.tooltip {
  margin-left: 5px;
}

.withSafeAreaContainer {
  display: flex;

  & > div {
    position: relative;
  }
}
