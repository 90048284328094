@use "sass:math"; @import "variables.scss";
.label {
  font-size: $font-size-small;
  line-height: 16px;
  margin-bottom: grid-gap(0.25);
  color: $color-neutrals-700-dark-grey;
  text-transform: uppercase;
  letter-spacing: 0.04em;

  &.bold {
    color: $color-neutrals-black;
    font-size: $font-size-large;
    line-height: 24px;
    font-weight: $font-weight-semi-bold;
    text-transform: none;
    letter-spacing: 0;
  }
}
