@use "sass:math"; @import "variables.scss";
.fieldsWrapper {
  display: flex;
  align-items: center;

  .field {
    max-width: grid-gap(3.5);
  }

  .delimiter {
    display: block;
    font-size: $font-size-large;
    color: $color-neutrals-700-dark-grey;
    margin: 0 grid-gap(0.3);
  }
}
