@use "sass:math"; @import "variables.scss";
.customTooltip {
  color: inherit;
  z-index: 1;

  &:hover {
    color: inherit;
  }
}

.linkContent {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customNotification {
  width: max-content;
  text-align: left;
  max-width: 230px;

  @include media(">=screen-xs") {
    max-width: 312px;
  }
}
