@use "sass:math"; @import "variables.scss";
.infoBox {
  max-width: 370px;
  text-align: center;
}

.icon {
  width: 32px;
  height: 32px;
  margin-bottom: grid-gap(1);
  stroke: $color-neutrals-800-dark;
}

.title {
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
  color: $color-neutrals-800-dark;
  margin-bottom: grid-gap(0.25);
}

.text {
  font-size: $font-size-large;
  line-height: 24px;
  color: $color-neutrals-700-grey;
  max-width: 400px;
  padding: 0 grid-gap(2);
}

.button {
  margin-top: grid-gap(1.5);
}
