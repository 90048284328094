@use "sass:math"; @import "variables.scss";
.singleSelect {
  [class^="header__"] {
    height: 40px;
  }

  [class^="search__"] {
    font-size: $font-size-large;
    width: 100%;

    &::placeholder {
      color: $color-neutrals-500-grey;
      text-shadow: none;
    }
  }

  [class^="searchIcon__"] {
    width: initial;
    margin: 0;
    display: flex;
  }

  [class^="showIcon__"] {
    fill: $color-neutrals-700-dark-grey;
    width: 25px;
    height: 25px;
  }

  [class^="singleItem__"] {
    font-size: $font-size-large;
  }
}

.withNoOptionsHidden {
  [class^="dropdown__"] {
    display: none;
  }
}

.singleSelectItem {
  font-size: $font-size-large;
  line-height: 22px;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
