@use "sass:math"; @import "variables.scss";
.title {
  @include f-grotesk("demi");

  font-size: 18px;
  color: $color-neutrals-800-dark;
  margin: 0 0 grid-gap(1) 0;
}

.text {
  line-height: 20px;
  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
  margin: 0 0 grid-gap(1) 0;
}

.list {
  margin: 0 0 grid-gap(1) 0;
}

.listItem {
  display: flex;
  align-items: center;
  margin: 0 0 grid-gap(0.5) 0;
}

.warningText {
  padding: 0 0 0 grid-gap(0.5);
  line-height: 20px;
  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
}

.checkIcon {
  fill: $color-system-status-bg-success;
}

.notification {
  margin: 0 0 grid-gap(1) 0;
}
