@use "sass:math"; @import "variables.scss";
.campaignNameModal {
  background: $color-neutrals-200-light-grey;
  width: calc(100% - 32px);

  &.customSize {
    max-width: 568px;
  }

  .header {
    margin-bottom: grid-gap(0.75);
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-extra-large;
    line-height: 24px;
    color: $color-neutrals-800-dark;
  }

  .description {
    margin-bottom: grid-gap(1.5);
    font-size: $font-size-normal;
    line-height: 20px;
    color: $color-neutrals-700-dark-grey;
    word-break: break-all;
  }

  .form {
    width: 100%;
    display: grid;
    grid-gap: grid-gap(1);
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: grid-gap(0.5);

    @include media(">=screen-xs") {
      justify-content: flex-end;
    }

    .cancelButton {
      margin-right: grid-gap(0.75);
    }
  }

  .notification {
    margin-bottom: grid-gap(1.5);
  }
}
