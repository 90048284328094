@use "sass:math"; @import "variables.scss";
.headerSection {
  padding: 0 grid-gap(1) grid-gap(6.5);

  @include media(">=screen-xs") {
    padding: 0 grid-gap(2.5) grid-gap(6.5);
  }

  @include media(">=screen-sm") {
    padding-bottom: grid-gap(4.5);
  }

  @include media(">=screen-md") {
    padding-bottom: grid-gap(6);
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: grid-gap(1);

    .loginButton {
      align-self: initial;
      margin: 0;
    }
  }
}

.header {
  @include media(">=screen-sm") {
    max-width: 690px;
  }
}

.description {
  margin-bottom: grid-gap(1.5);

  @include media(">=screen-sm") {
    max-width: 600px;
  }

  @include media(">=screen-xl") {
    max-width: 368px;
  }
}
