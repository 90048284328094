@use "sass:math"; @import "variables.scss";
.disasterBox {
  display: flex;
  justify-content: center;
  margin: 0 0 grid-gap(3);
}

.innerBox {
  box-sizing: border-box;
  width: 100%;
  max-width: 864px;
  border-radius: 6px;
  color: $color-neutrals-800-dark;
  background-color: $color-warning-100;
  padding: grid-gap(1.5) grid-gap(1);
  display: flex;
  align-items: center;
  flex-direction: column;

  @include media(">=screen-xs") {
    padding: grid-gap(1.5) grid-gap(1.5);
    flex-direction: row;
  }

  @include media(">=screen-sm") {
    padding: grid-gap(1.5) grid-gap(5);
  }

  .header {
    @include f-grotesk("demi");

    font-size: $font-size-extra-large;
    margin-bottom: grid-gap(0.5);

    @include media(">=screen-sm") {
      font-size: $font-size-huge;
    }
  }

  .text {
    font-size: $font-size-small;
    line-height: 16px;
    margin: 0;

    @include media(">=screen-sm") {
      font-size: $font-size-large;
      line-height: 24px;
    }
  }
}

.textBox {
  order: 1;

  @include media(">=screen-xs") {
    width: 75%;
    order: 0;
  }
}

.imageBox {
  display: flex;
  justify-content: center;
  order: 0;
  margin: 0 0 grid-gap(1);

  @include media(">=screen-xs") {
    margin: 0;
    width: 35%;
    order: 1;
  }
}

.img {
  max-width: 90px;

  @include media(">=screen-xs") {
    max-width: 121px;
  }
}
