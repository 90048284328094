@use "sass:math"; @import "variables.scss";
.withBlueIcon {
  [class^="showIcon__"] {
    fill: $color-brand-600-primary-blue;
  }
}

.withNoMainIcon {
  [class^="pillsContainer__"] {
    margin-left: 0;
  }
}

svg.searchIcon {
  fill: $color-brand-700-blue;
  width: 24px;
}

.multiselect {
  [class^="dropdownList__"] {
    left: -145px !important;
  }

  [class^="listWrapper__"] {
    left: 0 !important;
  }
}
