@use "sass:math"; @import "variables.scss";
.textInput {
  width: 100%;

  input {
    @include source-sans-pro("regular");

    min-height: 40px;
    padding: 0 grid-gap(2) 0 grid-gap(1.375);
    border-radius: 5px;
    margin: 0;
    line-height: 35px;

    &::placeholder {
      color: $color-neutrals-500-grey;
    }
  }

  [class^="showButton__"],
  [class^="closeButton__"] {
    display: none;
  }
}

.recordsListItem {
  font-size: $font-size-large;
  padding: grid-gap(0.75) 0;
}

.recordsListItemLabel {
  font-size: $font-size-normal;
}
