@use "sass:math"; @import "variables.scss";
.inputWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  .inputContainer {
    width: 100%;
    position: relative;

    &.firstInput {
      margin-bottom: grid-gap(1);
    }

    .input {
      width: 100%;
    }

    &:last-of-type {
      margin-bottom: grid-gap(1);
    }
  }
}

@include media(">=screen-xs") {
  .inputWrapper {
    flex-direction: row;

    .inputContainer {
      width: 50%;

      &.firstInput {
        margin-right: grid-gap(1);
        margin-bottom: 0;
      }
    }
  }
}
