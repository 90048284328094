@use "sass:math"; @import "variables.scss";
.actionStateWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $color-neutrals-100-white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2100;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 420px;
    width: 100%;
  }
}

.spinner {
  width: 8em;
  height: 8em;
  margin-bottom: grid-gap(1.25);

  @include spinnerLinearGradient($color-neutrals-800-dark);

  &::before {
    background: $color-neutrals-800-dark;
  }
}

.header {
  @include source-sans-pro("semi-bold");

  line-height: 24px;
  color: $color-neutrals-800-dark;
}

.description {
  line-height: 24px;
  color: $color-neutrals-700-dark-grey;
  margin-bottom: grid-gap(1.5);
  text-align: center;
}

.buttonWrapper {
  display: flex;
  width: 100%;

  .button.customButton {
    width: 50%;

    div[class^="ripple__"] {
      padding: 0 grid-gap(0.75);
    }

    &:first-child {
      margin-right: grid-gap(1);
    }
  }
}

.icon {
  margin-bottom: grid-gap(1);
}
