@use "sass:math"; @import "variables.scss";
.advertisersField {
  [class^="search__"] {
    &::placeholder {
      color: $color-neutrals-700-grey;
    }
  }

  [class^="checkboxLabel__"] {
    color: $color-neutrals-800-dark;
    max-width: 250px;
  }

  [class^="scrollableBox__"] {
    min-width: 305px;
  }

  // in order to hide pills list
  [class^="excessIndicator__"] {
    display: none;
  }

  // in order to hide pills list
  [class^="header__"] {
    [class^="dropdown__"] {
      display: none;
    }
  }

  [class^="scrollableBox__"] {
    > div {
      overflow-x: hidden;
    }
  }
}

.advertisersFieldListItem {
  margin-left: 0 !important;
  width: 100% !important;
  padding: 0 grid-gap(0.75);

  &.withUnderline {
    &:first-of-type {
      position: relative;

      &::after {
        display: block;
        content: "";
        height: 1px;
        background-color: $color-neutrals-400-cold-grey;
        position: absolute;
        left: -5px;
        right: -5px;
        bottom: 0;
      }
    }
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}
