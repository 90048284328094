@use "sass:math"; @import "variables.scss";
.campaignBlock {
  @include source-sans-pro("semi-bold");

  color: $color-neutrals-800-dark;
  font-size: $font-size-large;
  padding: grid-gap(0.5);
  margin: grid-gap(0.75) grid-gap(0.25);
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: none;
  z-index: 2;
  text-align: left;
  cursor: pointer;
  position: relative;

  @include media(">=screen-xl") {
    padding: grid-gap(0.5) grid-gap(0.75);
    margin: grid-gap(0.75) grid-gap(0.5);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
  }

  &.isInFirstRow {
    margin: grid-gap(1.5) grid-gap(0.25) grid-gap(0.75) grid-gap(0.25);

    @include media(">=screen-xl") {
      margin: grid-gap(1.5) grid-gap(0.5) grid-gap(0.75) grid-gap(0.5);
    }

    &.isInLastRow {
      margin: grid-gap(1.5) grid-gap(0.25) grid-gap(1.5) grid-gap(0.25);

      @include media(">=screen-xl") {
        margin: grid-gap(1.5) grid-gap(0.5) grid-gap(1.5) grid-gap(0.5);
      }

      &.outOfTimeRangeStart {
        margin-left: 0;
      }
    }
  }

  &.isInLastRow {
    margin: grid-gap(0.75) grid-gap(0.5) grid-gap(1.5) grid-gap(0.5);
  }

  &.neverEnding {
    margin: grid-gap(0.25) grid-gap(0.5);
  }

  // when campaign lasts only in one time item
  &.isShortTimeRangeCampaign {
    padding: grid-gap(0.5) grid-gap(0.75);

    .text {
      order: 1;
    }

    .warningIconWrapper {
      order: 0;
      margin: 0 grid-gap(0.5) 0 0;
    }

    @include media(">=screen-lg") {
      &.sixMonths,
      &.oneMonth {
        padding: grid-gap(0.5) grid-gap(0.25);
        justify-content: center;

        .campaignIconContainer {
          margin: 0;
        }

        .warningIconWrapper {
          margin: 0;
        }

        // Text should be hidden when timeline is in ONE or SIX month mode because of the single columns width.
        .text {
          display: none;
        }
      }
    }

    @include media(">=screen-lg") {
      &.oneMonth {
        .text {
          display: none;
        }
      }
    }
  }

  // when start date of campaign is out of the visible time range
  &.outOfTimeRangeStart {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // when end date of campaign is out of the visible time range
  &.outOfTimeRangeEnd {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }

  // when campaign block is in loading state
  &.loading {
    padding: 0;
    margin: grid-gap(1) grid-gap(0.5);
    outline: none;
    cursor: auto;
    box-shadow: none;
  }

  .moreInfoBox {
    display: none;

    [class^="content__"] {
      margin-right: 0;
    }

    .moreInfoBoxText {
      white-space: nowrap;
    }
  }

  &:hover {
    .moreInfoBox {
      display: block;
      position: fixed;
      transform: translateX(-50%);
      z-index: 9999;
    }
  }
}

.campaignBlockActive {
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2);

  @include z-index(timeline-campaign);
}

.campaignBlockLeft {
  margin-left: 0;
}

.campaignBlockRight {
  margin-right: 0;
}

.text {
  color: $color-neutrals-800-dark;
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaignIconContainer {
  margin: 0 grid-gap(0.5) 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkIconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background-color: $color-neutrals-400-cold-grey;
}

.warningIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 grid-gap(0.25);

  @include media(">=screen-xxl") {
    margin: 0 0 0 grid-gap(0.75);
  }
}

.warningIcon {
  path {
    fill: $color-warning-500;
  }
}

.offerBackground,
.reservedBackground {
  background: repeating-linear-gradient(
    45deg,
    $color-neutrals-175-light-grey,
    $color-neutrals-175-light-grey 3px,
    $color-brand-50-blue 3px,
    $color-brand-50-blue 6px
  );
}
