@use "sass:math"; @import "variables.scss";
.previewInContext {
  display: block !important;
  margin: grid-gap(2) 0;
}

.sectionHeader {
  @include source-sans-pro("regular");

  font-size: $font-size-large;
  line-height: grid-gap(1.5);
  margin-bottom: grid-gap(0.25);
}

.description {
  @include source-sans-pro("regular");

  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
  margin-bottom: grid-gap(0.75);
  line-height: 20px;
}

.linkSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: grid-gap(2);
  flex-wrap: wrap;
}

.publisherDropdown {
  width: 340px;
}

.publisherDropdown,
.previewButton,
.copyButton,
.qrCodeButton {
  margin: 0 grid-gap(0.75) grid-gap(0.75) 0;
}

.copyTooltip {
  padding: grid-gap(0.75);
  background-color: $color-neutrals-800-dark;
  border-radius: 5px;
  color: white;
  width: 50px;
  text-align: center;
  margin-left: -6px;
}

.QRTooltip {
  background-color: $color-neutrals-100-white;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: grid-gap(1);
  position: absolute;
  bottom: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: grid-gap(-0.5);
    bottom: grid-gap(-1);
    left: 88%;
    box-sizing: border-box;
    border: 8px solid;
    border-color: transparent transparent $color-neutrals-100-white
      $color-neutrals-100-white;
    transform-origin: 0 0;
    transform: rotate(-45deg);
  }

  &.big {
    width: 285px;
    right: -160px;

    &::before {
      left: 47%;
    }
  }

  &.small {
    width: 260px;
    right: -138px;

    &::before {
      left: 49%;
    }
  }
}

.tooltipHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-size: $font-size-normal;
}

.tooltipInstruction {
  color: $color-neutrals-700-dark-grey;
  line-height: 20px;
  font-size: $font-size-normal;
  margin-right: 30px;
}

.closeButton {
  cursor: pointer;
  margin-top: 5px;
}
