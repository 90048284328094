@use "sass:math"; @import "variables.scss";
.notificationWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  .notification {
    width: 100%;
    align-content: center;
    margin-top: grid-gap(1.5);

    section {
      width: 100%;
    }

    &::after {
      content: none;
    }

    div[class^="content__"] {
      margin-top: 0;
    }
  }
}

.inviteButton {
  display: flex;
}

.modal {
  overflow: hidden;
}

.customSize {
  max-height: 800px;
  height: 100%;
}

.inviteDescription {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
}

.link {
  text-decoration: underline;
  margin-right: grid-gap(0.25);
  font-weight: $font-weight-semi-bold;
}
