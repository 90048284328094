@use "sass:math"; @import "variables.scss";
$progress-bar-size: 8px;

.progressBar {
  width: 100%;
  height: $progress-bar-size;
  border-radius: calc($progress-bar-size / 2);
  background-color: $color-neutrals-400-cold-grey;
  overflow: hidden;

  .progress {
    height: $progress-bar-size;
    background-color: $color-brand-600-primary-blue;
  }
}
