@use "sass:math"; @import "variables.scss";
.overviewWrapper {
  @extend %ultraLargeWrapper;

  padding: grid-gap(1) 0 grid-gap(1.5);

  @include media(">=screen-xxl") {
    max-width: $max-content-width-ultra-large;
  }

  @include media(">=screen-xs") {
    padding: grid-gap(2) 0 grid-gap(1.5);
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin: 0 0 grid-gap(0.5);
}

.header {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  color: $color-neutrals-700-dark-grey;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
}

.boxesWrapper {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 25px;
  height: auto;
}

.infoIcon {
  margin: 0 0 0 grid-gap(0.5);
}

.notificationTooltip {
  min-width: 296px;
  box-sizing: border-box;
  color: $color-neutrals-400-cold-grey;

  [class^="title__"] {
    font-weight: $font-weight-normal;
    color: $color-white;
  }

  [class^="content__"] {
    margin-right: grid-gap(1);
  }
}
