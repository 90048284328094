@use "sass:math"; @import "variables.scss";
.listItemWrapper {
  border-bottom: solid 1px $color-neutrals-400-cold-grey;
  padding: 0 grid-gap(0.125);

  &:hover {
    background-color: $color-brand-50-blue;
    cursor: pointer;
  }

  &:first-child {
    padding-top: grid-gap(0.125);
  }

  &:last-child {
    padding-bottom: grid-gap(0.125);
  }
}

.listItem {
  border: none;
  padding: grid-gap(1) grid-gap(2) grid-gap(1) grid-gap(1);
  text-align: left;
  position: relative;
  background-color: transparent;
  width: 100%;
  user-select: text;

  @include media(">=screen-lg") {
    padding: grid-gap(1) grid-gap(4) grid-gap(1) grid-gap(1);
  }

  &:focus {
    box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
    outline: none;
  }

  &.listItemLoading {
    padding: grid-gap(1);

    &:hover {
      background-color: transparent;
    }
  }
}

.infoBox {
  display: flex;
  position: relative;
}

.infoBoxText {
  @include source-sans-pro("semi-bold");

  font-weight: $font-weight-normal;
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-small;
  line-height: 16px;

  &:nth-child(1) {
    padding-right: grid-gap(0.5);
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      right: 0;
      transform: translateX(100%);
      background-color: $color-neutrals-400-cold-grey;
    }
  }

  &:nth-child(2) {
    padding-left: grid-gap(0.5);
  }
}

.title {
  @include source-sans-pro("semi-bold");

  font-size: $font-size-large;
  line-height: 20px;
  color: $color-neutrals-800-dark;
  margin: grid-gap(0.25) 0;
}

.seeMoreBtn {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  padding: grid-gap(0.5);

  .seeMoreArrow {
    &::after {
      transform: rotate(-45deg);
      display: inline-block;
      position: relative;
      content: "";
      width: 8px;
      height: 8px;
      border-right: 2px solid $color-neutrals-800-dark;
      border-bottom: 2px solid $color-neutrals-800-dark;
    }
  }
}

.error {
  @include source-sans-pro("regular");

  font-size: $font-size-normal;
  color: $color-warning-500;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin: grid-gap(0.5) 0 0;
}

.errorIcon {
  height: 14px;
  width: 15px;
  fill: $color-warning-400-red;
  margin: 0 grid-gap(0.5) 0 0;
}
