@use "sass:math"; @import "variables.scss";
.errorPage {
  height: 100%;
  background-color: $color-white;
}

.error {
  @extend %mainGrid;

  grid-template-areas: "error";
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: grid-gap(2);
}

.errorWrapper {
  @extend %wrapper;

  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: grid-gap(2);
  margin: 0 auto;
  padding-top: grid-gap(2);
}

.image {
  width: 100%;
  max-width: 160px;
}

.header {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  line-height: 24px;
  color: $color-neutrals-900-black;
  margin-bottom: grid-gap(0.5);
}

.description {
  font-size: $font-size-large;
  margin-bottom: grid-gap(1);
  line-height: 24px;
  color: $color-neutrals-800-dark;
}

@include media(">=screen-xs") {
  .errorWrapper {
    flex-direction: row;
    grid-gap: grid-gap(1);
    max-width: 450px;
    max-height: 420px;
  }

  .header {
    font-size: 24px;
    line-height: 32px;
  }

  .description {
    margin-bottom: grid-gap(1.5);
  }
}

@include media(">=screen-sm") {
  .errorWrapper {
    max-width: 480px;
    flex-direction: column;
    grid-gap: grid-gap(2);
  }

  .image {
    max-width: 320px;
  }
}

@include media(">=screen-md") {
  .errorWrapper {
    padding-top: grid-gap(4);
    flex-direction: row;
    grid-gap: grid-gap(4);
    max-width: $screen-md;
  }

  .image {
    max-width: 360px;
  }
}

@include media(">=screen-lg") {
  .errorWrapper {
    padding-top: 12vw;
  }

  .image {
    max-width: 420px;
  }
}
