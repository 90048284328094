@use "sass:math"; @import "variables.scss";
.customTabs {
  height: 40px;
  width: fit-content;
  box-sizing: border-box;

  div[class^="tabItem"] {
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
