@use "sass:math"; @import "variables.scss";
.icon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  path {
    stroke: $color-neutrals-800-dark;
  }
}
