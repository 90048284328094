@use "sass:math"; @import "variables.scss";
.invoiceDetailsDrawerHeader {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.5);

  h1 {
    font-size: 24px;
    color: $color-neutrals-800-dark;
    font-weight: $font-weight-semi-bold;
    line-height: 32px;
  }

  p {
    color: $color-neutrals-700-dark-grey;
    line-height: 24px;
  }
}
