@use "sass:math"; @import "variables.scss";
.paymentMethodsWrapper {
  margin-top: grid-gap(2);
}

.paymentMethods {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: grid-gap(0.5);
}

.paymentMethodsLabel {
  font-weight: $font-weight-semi-bold;
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-large;
  line-height: 24px;
  margin-bottom: grid-gap(0.25);
  display: block;
}

.singlePaymentMethod {
  display: flex;
  justify-content: space-between;
  color: $color-neutrals-700-dark-grey;
  margin-bottom: grid-gap(1);

  &Text {
    color: $color-neutrals-800-dark;
    font-weight: $font-weight-semi-bold;
  }
}

.notification {
  max-width: 250px;

  div[class^="content__"] {
    margin: 0;
  }
}
