@use "sass:math"; @import "variables.scss";
.sectionBox {
  box-sizing: border-box;
  padding: grid-gap(2) grid-gap(1) 0 grid-gap(1);
  display: flex;
  flex-direction: column;

  @include media(">=screen-xs") {
    padding: grid-gap(3) grid-gap(3) 0 grid-gap(3);
  }

  @include media(">=screen-xl") {
    min-height: calc(100vh - 152px);
    border-left: 1px solid $color-neutrals-400-cold-grey;

    &:first-of-type {
      border-left: none;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  font-size: $font-size-extra-large;
  line-height: 24px;
  margin-bottom: grid-gap(1.5);
}

.number {
  min-height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: $color-brand-600-primary-blue;
  color: $color-neutrals-100-white;
  font-weight: $font-weight-semi-bold;
}

.title {
  margin-left: grid-gap(0.75);
  color: $color-neutrals-700-dark-grey;
  font-weight: $font-weight-semi-bold;
}
