@use "sass:math"; @import "variables.scss";
.listTitle {
  font-size: 18px;
  line-height: 24px;
  padding: 0 grid-gap(1) grid-gap(0.75);
  color: $color-neutrals-700-dark-grey;
  font-weight: $font-weight-semi-bold;
  border-bottom: 1px solid $color-neutrals-400-cold-grey;
}

.list {
  display: flex;
  flex-direction: column;
  padding: grid-gap(1);
  background-color: $color-neutrals-200-light-grey;
  gap: grid-gap(1);
}

.customPagination {
  margin-top: grid-gap(1.75);
  justify-content: center;
}

.noAdvertisersInfo {
  margin: 0 auto;
  padding: 0 0 grid-gap(2.5);
}

.infoBox {
  text-align: center;
  max-width: 350px;
  margin: grid-gap(1) auto 0;
}
