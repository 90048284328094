@use "sass:math"; @import "variables.scss";
.tooltipContent {
  max-width: 296px;
  padding: grid-gap(0.25);

  p {
    margin-bottom: grid-gap(0.5);
    font-size: $font-size-large;
  }

  span {
    font-size: $font-size-normal;
  }
}

.link {
  text-decoration: underline;

  &::after {
    content: none;
  }
}
