@use "sass:math"; @import "variables.scss";
.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    margin-bottom: grid-gap(0.5);
  }

  > p {
    @include source-sans-pro("regular");

    font-size: 16px;
    text-align: center;
    color: $color-neutrals-800-dark;
    line-height: 24px;
    margin-bottom: grid-gap(1.5);
    padding: 0 grid-gap(2);
  }

  > button {
    min-width: 184px;
    text-align: center;

    &:nth-of-type(1) {
      margin-bottom: $grid-gap;
    }
  }
}
