@use "sass:math"; @import "variables.scss";
@mixin focus-outline {
  outline: none;
  border-radius: 6px;
  box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
}

/* stylelint-disable no-descending-specificity */
.carouselModalWrapper .customCarousel,
.creativeBoxContent .customCarousel {
  margin: 0;
  padding: 0;

  :global(.slick-list):focus-within {
    @include focus-outline;
  }

  div[class="slick-track"] {
    display: flex;
  }

  :global(.slick-slide) {
    height: auto;

    /* The div element created by slick as a wrapper for each slide. */
    > div:first-child {
      height: 100%;
    }
  }

  :global(.slick-disabled) {
    display: none !important;
  }

  :global(.ad-native) {
    width: 100%;
  }
}

.carouselModalWrapper {
  min-width: 320px;
  max-width: 320px;

  @include media(">=screen-xs") {
    max-width: 420px;
  }

  @include media(">=screen-sm") {
    max-width: 580px;
  }

  div[class^="defaultImage__"] {
    cursor: default;

    &::after {
      content: none;
    }
  }
}

// nesting needed to override previewContainer styles
[class^="previewContainer"] .creativeBoxContent .notification {
  box-sizing: border-box;
  margin: 0 0 grid-gap(1);
  width: 100%;

  div[class^="iconButton"] {
    margin: 0 0 0 auto;
  }
}

.creativeBoxContent {
  flex-direction: column;

  .creative {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 60vh;
    display: flex !important;
    flex-direction: column;

    .expandIcon {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 50%;
      top: 50%;
      margin-top: -12px;
      margin-left: -12px;
      z-index: 1;
      opacity: 0;
      visibility: hidden;

      g {
        stroke: $color-neutrals-100-white;
      }
    }

    .headerWrapper {
      flex: 0;
    }

    .imageWrapper {
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      &:focus {
        outline: none;
      }

      img[class^="image__"] {
        margin: auto;
      }

      div[class^="thirdPartyWrapper__"] {
        margin: auto;
      }

      .iconWrapper {
        width: 100%;
        min-height: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-neutrals-300-warm-grey;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        .expandIcon,
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }

      &.withBorder {
        &::before {
          content: "";
          border: 2px dashed $color-neutrals-400-cold-grey;
          overflow: hidden;
          background-color: $color-neutrals-200-light-grey;
          box-sizing: border-box;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }

      &.withoutBackground::before {
        background-color: transparent;
      }

      &.modalOpen {
        cursor: default;

        &::after {
          content: none;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }

  .slickThumbnail {
    max-width: grid-gap(36.25);
    padding: grid-gap(1) 0 0 0;
    margin: 0;

    div[class^="slick-track"] {
      display: flex;
      margin: 0;
    }

    :global(.slick-current) {
      button[class^="creative__"] {
        position: relative;
        box-shadow: none;

        &::before {
          content: "";
          border-radius: 6px;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          position: absolute;
          left: 0;
          top: 0;
          border: 2px solid $color-brand-600-primary-blue;
          z-index: 1;
        }
      }
    }

    :global(.slick-disabled) {
      display: none !important;
    }

    div[class^="slick-slide"] {
      min-width: grid-gap(6);
      height: grid-gap(4.25);
      margin-right: grid-gap(1);
    }

    button[class*="slick-arrow"] {
      top: calc(50% + 8px);
    }
  }

  button[class*="slick-prev"] {
    left: -10px !important;
  }

  button[class*="slick-next"] {
    right: -10px !important;
  }

  button[class*="slick-arrow"] {
    box-shadow: 0 5px 15px 0 rgba($color-neutrals-black, 0.15) !important;
  }

  &:focus {
    outline: none;
  }
}

.galleryContainer {
  margin-bottom: 100px;
}
