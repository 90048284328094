@use "sass:math"; @import "variables.scss";
.fileUploadButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: grid-gap(0.5);

  input[type="file"] {
    display: none;
  }

  &Description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font-size-normal;
    color: $color-neutrals-700-dark-grey;
    line-height: 20px;
    gap: grid-gap(0.25);

    @include media(">screen-md") {
      flex-direction: row;
    }
  }
}

.device {
  text-transform: lowercase;
  margin: 0 grid-gap(0.125);
  font-weight: 600;
}

.addImageIcon {
  stroke: $color-neutrals-700-dark-grey;
  width: 24px;
  height: 24px;
}
