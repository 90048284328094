@use "sass:math"; @import "variables.scss";
.item {
  cursor: pointer;

  td.nameIdBodyCell {
    padding-left: grid-gap(3);

    @include media("<=screen-xs") {
    }

    [class^="container__"] {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 7px;
        height: 8px;
        border-left: 2px solid $color-brand-300-blue;
        border-bottom: 2px solid $color-brand-300-blue;
        display: inline-block;
        top: 50%;
        left: -50%;
      }
    }

    [class^="cell__"] {
      width: 315px;
      content: "";

      @include media(">=screen-xl") {
        width: 292px;
      }
    }

    [class^="content__"] {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.itemLoader {
  [class^="thumbnailAndName__"] {
    padding-left: grid-gap(3);
    padding-right: grid-gap(1.5);
  }

  [class^="quickActionButton__"] {
    margin-left: grid-gap(2.5);
  }
}

.loader {
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.stickyColumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: inherit;
  margin: grid-gap(0.75) 0;
}

@include media(">=screen-xs") {
  .isSticky {
    position: sticky;
    z-index: 10;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 5px;
      height: 100%;
      top: 0;
      right: -6px;
      background: linear-gradient(
        to right,
        rgba(100, 100, 100, 0.1) 0%,
        rgba(100, 100, 100, 0) 100%
      );
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  [class*="hasBeenScrolled__"] {
    .isSticky {
      &::after {
        opacity: 1;
        transition: opacity 0.2s;
      }
    }
  }
}
