@use "sass:math"; @import "variables.scss";
.warning {
  [class^="button__"] {
    margin-top: grid-gap(1);
    color: $color-white;
    background-color: $color-system-status-bg-500-caution;

    &:hover {
      background-color: $color-system-status-bg-400-caution;
    }
  }
}
