@use "sass:math"; @import "variables.scss";
.cell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .percentage {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-normal;
    margin-right: grid-gap(0.5);
    width: 34px;
  }

  .progressbar {
    width: 80px;
  }
}
