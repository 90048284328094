@use "sass:math"; @import "variables.scss";
.container {
  margin-top: grid-gap(3);
}

.title {
  margin-bottom: grid-gap(1.5);
  font-size: $font-size-extra-large;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
}

.boxes {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  @include media("<=screen-xs") {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex-direction: column;
  margin-right: grid-gap(1);
}

.tooltipContent {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-caution-100;
  padding: grid-gap(0.25) grid-gap(0.5);
  border-radius: 6px;
  line-height: 16px;
  font-weight: $font-weight-semi-bold;
  color: $color-neutrals-800-dark;
}

.tooltipContentNotUploaded {
  background-color: $color-warning-100;
}

.tooltipIcon {
  margin-left: grid-gap(0.5);
}

.notification {
  width: 856px;
}
