@use "sass:math"; @import "variables.scss";
.subFormSwitcher {
  background-color: $color-neutrals-100-white;
  border-top: 1px solid $color-neutrals-400-cold-grey;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  box-sizing: border-box;
  position: relative;

  div[class*="tabItemsWrapper__"] {
    width: 100%;
    display: flex;

    div[class*="tabItem__"] {
      box-sizing: border-box;
      border-radius: 0;
      width: 100%;
      min-width: 24px;
      justify-content: center;
      transition-duration: 0s;
      height: 47px;
      margin-right: 0;
      position: relative;
      box-shadow: none;
      border: none;

      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:not(:last-of-type) {
        border-right: 1px solid $color-neutrals-400-cold-grey;
      }

      svg path {
        stroke: $color-neutrals-700-dark-grey;
      }

      &[class*="active"],
      &:hover {
        svg path {
          stroke: $color-brand-600-primary-blue;
        }
      }
    }
  }

  &Option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: grid-gap(0.25);

    &Title {
      display: none;

      @include media(">=screen-xs") {
        display: inline;
      }
    }

    > svg {
      flex-shrink: 0;
    }
  }
}

.formNotReviewedIndicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  flex-shrink: 0;

  &.showError {
    background-color: $color-warning-500;
  }
}

.formatsError {
  max-width: 100%;
}

.customNotification {
  > section {
    padding-left: grid-gap(0.5);
  }
}
