@use "sass:math"; @import "variables.scss";
.expandableWrapper {
  all: unset;
  margin-right: grid-gap(0.5);
}

.pencilIcon {
  path {
    stroke: $color-brand-accent-blue;
  }
}

.editMenu {
  width: 234px;
  transform: translate(-20px, -5px);
  position: relative;
  overflow: initial;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2);

  &::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7px 0;
    border-color: $color-white transparent transparent transparent;
    content: "";
    display: block;
    left: 25px;
    position: absolute;
    bottom: -20px;
    transform: translateY(-100%);
  }
}
