@use "sass:math"; @import "variables.scss";
.notification {
  width: 400px;
  box-sizing: border-box;
  display: flex;

  aside[class^="customIcon__"] {
    margin-top: -5px;
    padding: 5px 5px 6px 6px;

    .icon {
      width: 22px;
      height: 22px;
    }
  }

  div[class^="iconButton__"] {
    margin-top: 0;
  }

  .description {
    line-height: 20px;
    margin-bottom: grid-gap(1);
    font-size: $font-size-normal;
    color: $color-neutrals-700-grey;
  }
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.strong {
  font-weight: bold;
}
