@use "sass:math"; @import "variables.scss";
.labelWrapper {
  display: flex;
  margin-bottom: grid-gap(0.75);
}

.comingSoon {
  box-sizing: border-box;
  height: 20px;
  margin: grid-gap(0.125) 0 0 grid-gap(0.5);
  padding: grid-gap(0.125) grid-gap(0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-brand-500-green;
  font-size: $font-size-small;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: $color-brand-100-green;
}

.content {
  display: flex;
  padding: grid-gap(0.75) grid-gap(1);
  border-radius: 6px;
  background-color: $color-brand-100-blue;
  opacity: 0.35;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: grid-gap(0.5);

  path {
    stroke: $color-neutrals-800-dark;
  }
}

.text {
  font-size: $font-size-large;
  line-height: 24px;
  color: $color-neutrals-700-dark-grey;
}
