@use "sass:math"; @import "variables.scss";
.listLoader {
  display: grid;
}

.rowLoader {
  display: grid;
  padding: grid-gap(1.5) 0;
  border-bottom: 1px solid $color-neutrals-300-warm-grey;
  grid-template-columns: 184px 88px;
  justify-content: space-between;

  .defaultCellContent {
    display: flex;
    flex-direction: column;
    gap: grid-gap(0.875);
  }

  .actionsCellContent {
    display: flex;
    gap: grid-gap(0.5);
  }
}

@include media(">=screen-xs") {
  .rowLoader {
    grid-template-columns: 232px 145px 88px;
  }
}

@include media(">=screen-sm") {
  .rowLoader {
    grid-template-columns: 232px 145px 152px 88px;
  }
}

@include media(">=screen-md") {
  .rowLoader {
    grid-template-columns: 280px 145px 120px 152px 145px;
  }
}

@include media(">=screen-lg") {
  .rowLoader {
    grid-template-columns: 280px 145px 120px 152px 152px 145px;
  }
}
