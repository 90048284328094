@use "sass:math"; @import "variables.scss";
.overflowingCell {
  max-width: 152px;

  > div,
  div[class^="content"] {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.button.customButton {
  margin: grid-gap(0.25) grid-gap(1);
  color: $color-brand-300-blue;

  &:hover {
    background: $color-brand-50-blue;
  }

  span[class^="zIndex__"] {
    font-weight: $font-weight-normal;
  }
}
