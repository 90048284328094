@use "sass:math"; @import "variables.scss";
/* stylelint-disable no-descending-specificity */
.info {
  [class^="button__"] {
    margin-top: grid-gap(1);
    color: $color-white;
    background-color: red;

    &:hover {
      background-color: $color-warning-400-red;
    }
  }
}

.error {
  [class^="button__"] {
    margin-top: grid-gap(1);
    color: $color-white;
    background-color: $color-system-status-bg-warning;

    &:hover {
      background-color: $color-warning-400-red;
    }
  }
}

.warning {
  [class^="button__"] {
    margin-top: grid-gap(1);
    color: $color-white;
    background-color: $color-system-status-bg-500-caution;

    &:hover {
      background-color: $color-system-status-bg-400-caution;
    }
  }
}
