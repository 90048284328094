@use "sass:math"; @import "variables.scss";
.staticBanner {
  position: fixed;
  top: 48px;
  z-index: z-index(notification);
  left: 50%;
  transform: translateX(-50%);

  @include media("<=screen-sm") {
    margin: 0 grid-gap(1);
    left: revert;
    transform: none;
  }
}
