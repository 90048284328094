@use "sass:math"; @import "variables.scss";
.termsAndConditionsHeader {
  padding-bottom: 0;
}

.languageSwitcherWrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $color-neutrals-400-cold-grey;
  padding: grid-gap(1) 0;
}

.languageSwitcher {
  margin-bottom: 0;
}

.termsAndConditionsFooter {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-top: 1px solid $color-neutrals-400-cold-grey;
}

.notification.customSize {
  display: flex;
  background-color: $color-system-status-bg-100-warning;
  margin-bottom: grid-gap(1);
  padding: grid-gap(0.75) grid-gap(1);

  .icon {
    fill: $color-warning-500;
    min-width: 16px;
    margin-right: grid-gap(0.5);
  }

  div[class^="content__"] {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-normal;
  }

  div[class^="iconButton__"] {
    margin-top: 0;

    svg[class^="closeIcon__"] {
      width: 13px;
      height: 13px;
    }
  }

  &::before {
    content: none;
  }
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@include media(">=screen-sm") {
  .termsAndConditionsHeader {
    padding-bottom: grid-gap(1.5);
  }

  .termsAndConditionsFooter {
    border-top: none;
  }
}
