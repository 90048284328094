@use "sass:math"; @import "variables.scss";
.input {
  width: 100%;

  input {
    min-height: 48px;
  }
}

.calendarWrapper {
  position: absolute;
  z-index: z-index(booking-field);
}

.notification {
  margin-top: grid-gap(0.25);
}

.loader {
  margin-bottom: grid-gap(1.5);
}
