@use "sass:math"; @import "variables.scss";
.multipleSelect {
  margin-bottom: grid-gap(1.5);

  div[class^="header__"] {
    padding: grid-gap(0.25) 0;
  }

  div[class^="searchIcon__"] {
    margin-top: grid-gap(0.25);
  }
}

.searchIcon path {
  stroke: $color-brand-700-blue;
}
