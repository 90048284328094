@use "sass:math"; @import "variables.scss";
.multiplesSelect {
  [class^="header__"] {
    height: 40px;
  }

  [class^="showButton__"] {
    margin: 0;
  }

  [class^="search__"] {
    font-size: $font-size-large;
    width: 100%;

    &::placeholder {
      color: $color-neutrals-500-grey;
    }
  }

  [class^="showIcon__"] {
    fill: $color-neutrals-700-dark-grey;
  }

  [class^="searchIcon__"] {
    svg {
      width: initial;
    }
  }
}

svg.searchIcon {
  fill: $color-brand-700-blue;
  width: 24px;
  margin-top: grid-gap(-0.375);
  margin-left: grid-gap(-0.0625);
  margin-right: grid-gap(-0.25);
}

.searchDisabled input {
  cursor: pointer;
}
