@use "sass:math"; @import "variables.scss";
.buttonText {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.75);
  width: 100%;
  min-width: 0;

  input::placeholder {
    color: $color-neutrals-500-grey;
  }
}

.buttonTextSelect {
  input[type="text"] {
    min-width: 0;
  }
}
