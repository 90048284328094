@use "sass:math"; @import "variables.scss";
.calendarWrapper {
  max-width: 100vw;
  margin: 0 auto;

  @include media(">=screen-xxl") {
    max-width: $max-content-width-ultra-large;
  }
}

.calendarInner {
  overflow-x: hidden;
  position: relative;
}

.errorBoxWrapper {
  max-width: calc(100vw - 96px);
  margin: 0 auto;

  @include media(">=screen-xxl") {
    max-width: 100%;
  }
}

.errorBox {
  width: 100%;
  margin: 0 0 grid-gap(1);
  box-sizing: border-box;
}

.timelineContentOutter {
  overflow: auto;
  max-height: calc(100vh - 500px);
  min-height: 485px;
  scrollbar-color: $color-neutrals-400-cold-grey $color-neutrals-200-light-grey;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-corner {
    background: $color-neutrals-200-light-grey;
  }

  &::-webkit-scrollbar-track {
    background: $color-neutrals-200-light-grey;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-neutrals-400-cold-grey;
    width: 2px;
    height: 2px;
    border: 5px solid rgba(0, 0, 0, 0);
    border-radius: 10px;

    &:hover {
      background: $color-neutrals-500-grey;
      width: 6px;
      height: 6px;
      border: 5px solid rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
  }

  @include media(">=screen-lg") {
    overflow-x: hidden;
  }

  &.noScroll {
    overflow: hidden;
  }
}

.calendar {
  display: grid;
  grid-template-rows: repeat(12, 65px [row1-start]);
  position: relative;
}
