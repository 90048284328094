@use "sass:math"; @import "variables.scss";
.imageWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 100%;

  &.rounded {
    border-radius: 6px;

    img[class^="image__"] {
      margin: auto;
      border-radius: 6px;
    }
  }

  :global(.slick-track) {
    align-items: center;
    display: flex;
  }

  iframe[class^="iframe__"] {
    width: 100% !important;
  }

  div[class^="thirdPartyWrapper__"] {
    margin: auto;
  }

  .headerWrapper {
    align-self: flex-start;
  }

  .contentWrapper {
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      opacity: 0;
      visibility: hidden;
    }
  }

  .iconWrapper {
    width: 100%;
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-neutrals-300-warm-grey;

    .placeholder {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      color: $color-neutrals-800-dark;
      font-weight: 600;
    }
  }

  .expandIcon {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50%;
    top: 50%;
    margin-top: -12px;
    margin-left: -12px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;

    g {
      stroke: $color-brand-200-tertiary-blue;
    }
  }

  .photo {
    width: 100%;
    min-height: 360px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:hover {
    .expandIcon,
    .contentWrapper::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &.withoutModal {
    cursor: default;

    &::after {
      content: none;
    }
  }
}
