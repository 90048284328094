@use "sass:math"; @import "variables.scss";
.wrapper {
  @extend %ultraLargeWrapper;

  margin-top: grid-gap(0.5);
  margin-bottom: grid-gap(0.5);
}

.btn {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  color: $color-neutrals-500-grey;
  font-weight: 600;
  line-height: 24px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0 grid-gap(1.5) 0 0;

  &.active {
    color: $color-neutrals-700-dark-grey;
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: -3px;
      height: 2px;
      background-color: $color-neutrals-700-dark-grey;
      left: 0;
      right: 0;
    }
  }
}
