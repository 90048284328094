@use "sass:math"; @import "variables.scss";
.websites {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: grid-gap(1.5);
}

.image {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0 grid-gap(0.25);

  @include media(">=screen-xs") {
    padding: 0 grid-gap(0.5);
  }
}

.notification {
  div[class^="content__"] {
    margin: auto;
    white-space: nowrap;
  }
}
