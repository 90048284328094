@use "sass:math"; @import "variables.scss";
.defaultCell {
  white-space: nowrap;
  line-height: 24px;
  gap: grid-gap(0.5);
}

.topNode {
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;

  & > * {
    font-weight: $font-weight-semi-bold;
  }
}

.bottomNode {
  color: $color-neutrals-700-dark-grey;
}

.customTooltip {
  div[class^="content__"] {
    line-height: 24px;
    margin: 0;
  }
}

.content {
  cursor: pointer;
}
