@use "sass:math"; @import "variables.scss";
.advertiser {
  width: 235px;

  div[class^="singleSelect__"] {
    div[class^="header__"] {
      height: 42px;
    }
  }

  input[class^="search__"] {
    max-width: 116px;
    text-overflow: ellipsis;
  }

  div[class^="dropdown__"] {
    bottom: 100%;
  }

  div[class^="container__"] {
    bottom: -39px;
  }

  div[class^="noOptionsMessage__"] {
    padding: 0 grid-gap(1);
    flex-direction: row;

    svg {
      margin: 0;
    }
  }

  div[class^="listWrapper__"] {
    div[class^="scrollableBox__"] {
      margin-bottom: 4px;
    }
  }
}

@include media(">=screen-xs") {
  .advertiser {
    input[class^="search__"] {
      max-width: 100%;
    }
  }
}
