@use "sass:math"; @import "variables.scss";
.invoiceDetailsDrawer {
  overflow: visible;

  @include media(">=screen-xs") {
    width: auto;
  }
}

.invoiceDetailsDrawerContent {
  min-width: 490px;
  display: flex;
  flex-direction: column;
  padding: grid-gap(3);
  gap: grid-gap(2);
  box-sizing: border-box;
  position: relative;
}

.drawerCloseButton {
  position: absolute;
  right: -10px;
  top: 20px;
  box-shadow: none;

  @include media(">=screen-xs") {
    left: -10px;
  }
}
