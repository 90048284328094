@use "sass:math"; @import "variables.scss";
.sortIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: grid-gap(0.25);
}

.arrowRegular {
  width: 8px;
  height: 6px;

  path {
    stroke: $color-neutrals-600-medium-grey;
  }
}

.active {
  path {
    stroke: $color-neutrals-800-dark;
  }
}
