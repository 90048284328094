@use "sass:math"; @import "variables.scss";
:global(body) {
  @include source-sans-pro("regular");

  background-color: $color-neutrals-200-light-grey;
  overflow-y: scroll;
}

.layout {
  min-width: $min-content-width;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  min-height: 100vh;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.header {
  grid-area: header;
}

.content {
  grid-area: content;
}

.footer {
  grid-area: footer;
}
