@use "sass:math"; @import "variables.scss";
.withSort {
  display: flex;
  position: relative;
  z-index: 1;

  &:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    background: $color-brand-100-blue;
    position: absolute;
    z-index: -1;
    left: -(grid-gap(0.5));
    top: -(grid-gap(0.25));
    padding: grid-gap(0.25) grid-gap(0.5);
    box-sizing: content-box;
    border-radius: 4px;
  }

  &:focus {
    outline: none;

    &::after {
      content: "";
      width: 100%;
      position: absolute;
      border-radius: 4px;
      padding: 4px 8px;
      left: -8px;
      top: -4px;
      height: 14px;
      box-shadow: 0 0 0 2px #c4dcf5;
      box-sizing: content-box;
    }
  }

  &.active {
    color: $color-neutrals-800-dark;
  }
}
