@use "sass:math"; @import "variables.scss";
.advertiserField {
  .select {
    [class^="header_"] {
      height: 56px;
    }

    [class^="listItem"] {
      border: 1px solid transparent;

      &:focus-within {
        border: 1px solid $color-brand-200-tertiary-blue;
      }
    }

    div[class^="dropdown__"] {
      background-color: white;
    }
  }
}

.advertiserField {
  .select.error {
    [class^="header_"] {
      border: 1px solid $color-warning-400-red;
    }
  }
}

.loadingOptionsMessage {
  padding-left: grid-gap(1);
}
