@use "sass:math"; @import "variables.scss";
.error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-neutrals-200-light-grey;
}

.errorContent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: grid-gap(2) grid-gap(1.5);
  line-height: 24px;
  gap: grid-gap(2);
}

.header {
  @include f-grotesk("demi");

  font-size: 18px;
  margin-bottom: grid-gap(0.5);
}

.image {
  width: 150px;
  align-self: center;
}

.button {
  margin-top: grid-gap(1);
}

@include media(">=screen-xs") {
  .errorContent {
    flex-direction: row;
    padding: grid-gap(2) grid-gap(4);
  }
}

@include media(">=screen-sm") {
  .errorContent {
    flex-direction: column;
    padding: grid-gap(6) grid-gap(5.5);
  }

  .header {
    font-size: 24px;
    line-height: 32px;
  }

  .image {
    width: 344px;
  }
}

@include media(">=screen-md") {
  .errorContent {
    flex-direction: row;
    padding: grid-gap(4.5) grid-gap(2.5);
    align-items: center;
  }
}

@include media(">=screen-lg") {
  .errorContent {
    width: 1050px;
    padding: grid-gap(6) 0;
  }
}
