@use "sass:math"; @import "variables.scss";
.button {
  background-color: transparent;

  span {
    @include source-sans-pro("semi-bold");
  }
}

.externalLink {
  display: inline-block;
  color: $color-brand-600-primary-blue;
}

.errorBoundary {
  background: $color-neutrals-100-white;
  height: 100%;
}

@include media(">=screen-xs") {
  .button.support.customSize {
    height: 40px;
    line-height: 40px;
    font-size: $font-size-large;

    div[class^="ripple"] {
      padding: 0 grid-gap(1.5);
    }
  }
}
