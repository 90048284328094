@use "sass:math"; @import "variables.scss";
.title {
  display: flex;
  align-items: center;
  gap: grid-gap(0.25);
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
  color: $color-neutrals-700-dark-grey;
  line-height: 24px;
}

.infoLink {
  &Icon g {
    stroke: $color-neutrals-700-dark-grey;
  }

  &Tooltip {
    [class*="title"] {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      color: $color-neutrals-100-white;
      line-height: 24px;
    }

    [class*="content"] {
      font-size: $font-size-normal;
      color: $color-neutrals-400-cold-grey;
      line-height: 20px;
    }
  }
}
