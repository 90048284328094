@use "sass:math"; @import "variables.scss";
.button {
  margin-left: grid-gap(-1);
  margin-bottom: grid-gap(0.75);

  &.custom {
    color: $color-neutrals-800-dark;
    font-size: $font-size-normal;

    span[class^="buttonContent__"] {
      font-weight: $font-weight-normal;
    }

    .icon {
      stroke: $color-neutrals-800-dark;

      &.unfolded {
        transform: rotate(-180deg);
      }
    }
  }
}

.bold {
  font-weight: $font-weight-bold;
}

.content {
  margin-right: grid-gap(0.5);
}

.label {
  margin-bottom: grid-gap(0.75);
}
