@use "sass:math"; @import "variables.scss";
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: grid-gap(0.25) grid-gap(0.75);
  border-radius: 100px;
  background-color: $color-system-status-bg-info;
  color: $color-system-status-bg-400-info;
  font-size: $font-size-normal;
  font-weight: $font-weight-semi-bold;
  line-height: 16px;

  p {
    margin-bottom: grid-gap(0.25);
  }
}

.icon {
  margin-right: grid-gap(0.25);

  path {
    fill: transparent;
  }
}

.textSemiBold {
  font-weight: $font-weight-semi-bold;
}

.marginTop {
  margin-top: grid-gap(0.5);
}
