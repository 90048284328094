@use "sass:math"; @import "variables.scss";
.link {
  display: flex;
  min-width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  text-align: center;
  display: block;
}

.grayBackground {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: $color-neutrals-200-light-grey;
  color: $color-neutrals-600-medium-grey;
  border: 1px dashed $color-neutrals-400-cold-grey;
  width: 100%;
  height: 100%;
  top: 0;
}

.backgroundWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 180px;
  z-index: 0;
  box-sizing: border-box;
}

.imageWrapper {
  position: absolute;
  box-sizing: border-box;
  top: 0;

  img[class^="image__"] {
    border-radius: 0;
  }

  // Percentage width and left/right position is calculated based on sketches and should be responsive
  &.rb_wallpaper_desktop_bg {
    width: 100%;
    min-height: 100%;
    left: 0;

    &::before,
    &::after {
      content: "B";
      top: 50%;
      position: absolute;
      z-index: 1;
      display: flex;
      margin-top: -8px;
      color: $color-neutrals-600-medium-grey;
    }

    &::before {
      left: 7%;
    }

    &::after {
      right: 7%;
    }

    &.hasImage {
      min-height: 0;

      &::before,
      &::after {
        content: none;
      }
    }
  }

  &.rb_wallpaper_desktop_left,
  &.rb_wallpaper_desktop_right,
  &.rb_wallpaper_desktop_top {
    z-index: 1;
  }

  &.rb_wallpaper_desktop_left {
    min-height: 75%;
    width: 11%;
    left: 15%;
  }

  &.rb_wallpaper_desktop_right {
    min-height: 75%;
    width: 11%;
    left: 74%;
  }

  &.rb_wallpaper_desktop_top {
    min-height: 18%;
    width: 48%;
    left: 26%;

    &.topWithoutLeftBorder {
      .grayBackground {
        border-left: none;
      }
    }

    &.topWithoutRightBorder {
      .grayBackground {
        border-right: none;
      }
    }
  }
}

// White background should overlap the background image and imitate page content
// Width is calculated based on the position of the background and left image
.whiteBackground {
  box-sizing: border-box;
  background-color: $color-neutrals-100-white;
  position: absolute;
  left: 26%;
  top: 0;
  width: 48%;
  height: 100%;
  z-index: 0;
  border-bottom: none;

  // Borders exist only when background image is not added, width and left property have to be adjusted
  &.withBorder {
    border: 1px dashed $color-neutrals-400-cold-grey;
    left: calc(26% - 1px);
    width: calc(48% + 2px);
  }
}
