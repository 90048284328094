@use "sass:math"; @import "variables.scss";
.websiteNameBox {
  color: $color-neutrals-800-medium-dark;
}

.websiteName {
  @include source-sans-pro("semi-bold");

  line-height: 16px;
  display: inline-block;
  margin: 0 grid-gap(0.25) 0 0;
}

.circle {
  display: inline-block;
}

.number {
  font-size: $font-size-normal;
  color: $color-white;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background-color: $color-brand-600-primary-blue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
}
