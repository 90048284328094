/* stylelint-disable */
@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url("https://s3-eu-west-1.amazonaws.com/static.mpt-adsol.schibsted.io/rose-client-app/fonts/SourceSansPro-Regular.ttf")
    format("truetype");
  font-weight: $font-weight-normal;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url("https://s3-eu-west-1.amazonaws.com/static.mpt-adsol.schibsted.io/rose-client-app/fonts/SourceSansPro-SemiBold.ttf")
    format("truetype");
  font-weight: $font-weight-semi-bold;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url("https://s3-eu-west-1.amazonaws.com/static.mpt-adsol.schibsted.io/rose-client-app/fonts/SourceSansPro-Bold.ttf")
    format("truetype");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
  url("https://s3-eu-west-1.amazonaws.com/static.mpt-adsol.schibsted.io/rose-client-app/fonts/SourceSansPro-RegularItalic.ttf")
  format("truetype");
  font-weight: $font-weight-normal;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
  url("https://s3-eu-west-1.amazonaws.com/static.mpt-adsol.schibsted.io/rose-client-app/fonts/SourceSansPro-SemiBoldItalic.ttf")
  format("truetype");
  font-weight: $font-weight-semi-bold;
  font-style: italic;
}
/* stylelint-enable */
