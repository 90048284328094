@use "sass:math"; @import "variables.scss";
.logoUpload {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.75);
  width: 100%;
  line-height: 48px;

  p[class*="label"] {
    color: $color-neutrals-500-grey;
  }

  div[class*="dropZone"] {
    line-height: 48px;
    min-height: 48px;
  }

  button[class*="buttonWithIcon"] {
    width: 32px;
    height: 32px;
  }

  @include media(">screen-xl") {
    max-width: 200px;
  }
}
