@use "sass:math"; @import "variables.scss";
.button {
  box-sizing: border-box;
  border: none;
  background: none;
  padding: grid-gap(0.25);
  margin: 0;

  &:focus {
    outline: none;
    border-radius: 6px;
    box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
  }
}

.label {
  @include source-sans-pro("semi-bold");

  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-normal;
  margin-right: grid-gap(0.25);

  &:hover {
    color: $color-neutrals-800-dark;
  }
}

.expandIcon {
  fill: $color-neutrals-700-dark-grey;

  &:hover {
    fill: $color-neutrals-800-dark;
  }
}

.successIcon {
  fill: $color-brand-300-green;
  vertical-align: middle;
  margin: 0 3px 0 0;
}
