@use "sass:math"; @import "variables.scss";
.infoBox {
  max-width: 370px;
  text-align: center;
  animation: fadeIn 1.2s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .icon {
    margin: 0 0 grid-gap(1);

    path {
      stroke: $color-neutrals-800-dark;
    }
  }

  .title {
    color: $color-neutrals-800-dark;
    font-size: $font-size-large;
    margin: 0 0 grid-gap(0.25);
    font-weight: $font-weight-semi-bold;
  }

  .text {
    color: $color-neutrals-800-dark;
    font-size: $font-size-large;
    margin: 0 0 grid-gap(1.5);
    line-height: 24px;
  }
}
