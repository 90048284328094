@use "sass:math"; @import "variables.scss";
.periodField {
  [class^="header__"] {
    height: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  [class^="search__"] {
    &::placeholder {
      color: $color-neutrals-700-grey;
    }
  }

  input {
    cursor: pointer;
  }
}

.icon {
  fill: $color-white;
}
