@use "sass:math"; @import "variables.scss";
@mixin pickerWithLabelOnTop {
  display: block;

  [class^="label"] {
    margin-bottom: grid-gap(0.5);
  }
}

.inputWrapper {
  width: 100%;

  @include media(">=screen-xs") {
    width: calc(50% - 16px);
    margin-bottom: 0;
  }
}

.calendarInputTo {
  [class^="calendarWrapper"] {
    left: auto;
    right: 0;
  }
}

.inlineLabels {
  display: flex;
  width: 100%;

  &:first-child {
    margin-right: grid-gap(1);
  }

  input {
    min-height: 32px;
  }

  @include media("<screen-xs") {
    margin-bottom: grid-gap(1);

    &:first-child {
      margin-top: grid-gap(1);
    }
  }
}

.input-inlineLabels {
  display: flex;
  margin-bottom: 0;
  align-items: center;

  input[class^="input"] {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 145px;
  }

  [class^="label"] {
    display: flex;
    align-items: center;
    margin: 0 grid-gap(0.25) 0 0;
  }

  @include media("<screen-sm") {
    @include pickerWithLabelOnTop;
  }
}
