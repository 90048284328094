@use "sass:math"; @import "variables.scss";
.container {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-left: grid-gap(0.25);
  border-radius: 50%;
  background: $color-brand-600-primary-blue;
}
