@use "sass:math"; @import "variables.scss";
.customRadioGroup {
  display: flex;

  div {
    margin-right: grid-gap(0.5);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.vertical {
    flex-direction: column;

    div {
      margin-right: 0;
      margin-bottom: grid-gap(0.5);

      span[class^="radio__"] {
        min-width: 16px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
