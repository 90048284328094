@use "sass:math"; @import "variables.scss";
.item {
  display: grid;
  grid-template-columns: 32px 1fr 1fr;
  grid-template-areas: "icon title label" "description description description";
  border-radius: 6px;
  border: 1px solid $color-neutrals-400-cold-grey;
  box-sizing: border-box;
  padding: grid-gap(1);
  grid-gap: grid-gap(0.5);
  cursor: pointer;
  position: relative;

  &:focus {
    outline: 2px solid $color-brand-300-blue;
  }

  &:hover {
    border-color: $color-brand-600-primary-blue;
  }

  &.withNavIcon {
    grid-template-columns: 32px 1fr 1fr 32px;
    grid-template-areas: "icon icon label label" "title title title title" "description description description navIcon";
  }

  &.compact {
    grid-gap: grid-gap(1);
    grid-template-columns: 40px 1fr 1fr;
    grid-template-areas: "icon title label";
  }

  &.disabled {
    border-color: $color-neutrals-400-cold-grey;
    background: $color-neutrals-200-light-grey;
    cursor: not-allowed;
  }
}

.compact {
  .navIcon,
  .description {
    display: none;
  }

  .icon {
    margin: 0;
    min-width: 40px;
    min-height: 40px;
  }

  .title {
    justify-content: start;
  }
}

.withNavIcon {
  .navIcon {
    display: flex;
    justify-self: end;
    align-self: center;
  }
}

.label {
  grid-area: label;
  display: flex;
  align-items: center;
  justify-content: end;
}

.disabled {
  .title:hover {
    div[class^="icon_"] svg g {
      stroke: $color-neutrals-800-dark;
    }
  }

  .label {
    height: 20px;
  }
}

.icon {
  grid-area: icon;
  min-width: 32px;
  min-height: 32px;
  width: 32px;
}

.arrow {
  min-width: 16px;
  min-height: 16px;
  background-color: $color-neutrals-800-dark;
  border-radius: 50%;
  padding: grid-gap(0.5);

  g {
    stroke: $color-neutrals-100-white;
    stroke-width: 2.5px;
  }
}

.title {
  @include f-grotesk("demi");

  grid-area: title;
  display: flex;
  align-items: center;
  color: $color-neutrals-800-dark;
  font-size: $font-size-extra-large;
  line-height: 24px;
}

.description {
  grid-area: description;
  color: $color-neutrals-700-dark-grey;
}

.navIcon {
  display: none;
  grid-area: navIcon;
}

.svg {
  position: absolute;
  top: -7px;
  right: -8px;
  width: 78px;
  height: 78px;
}

.text {
  position: absolute;
  transform: rotate(45deg);
  top: 18px;
  right: -3px;
  font-size: $font-size-extra-small;
  line-height: 16px;
  color: $color-brand-500-green;
  white-space: nowrap;
  z-index: 1;
}

@include media(">=screen-xl") {
  .svg.compact {
    top: -8px;
    right: -9px;
    width: 92px;
    height: 92px;
  }

  .text.compact {
    top: 25px;
    right: 0;
  }
}

@include media(">=screen-xs") {
  .item {
    grid-gap: grid-gap(0.25);
    grid-template-columns: 1fr;
    grid-template-areas: "label" "icon" "title" "description";

    &.withNavIcon {
      grid-template-columns: 1fr 32px;
      grid-template-areas: "label label" "icon icon" "title title" "description navIcon";
    }
  }

  .withNavIcon {
    .navIcon {
      display: flex;
      justify-self: end;
      align-self: center;
    }

    .icon {
      display: flex;
      justify-self: start;
      min-width: 32px;
      min-height: 32px;
      margin: grid-gap(0.75) 0;
    }

    .title {
      justify-content: start;
    }

    .description {
      text-align: start;
    }

    .navIcon {
      margin-top: grid-gap(-0.75);
    }
  }

  .description {
    text-align: center;
  }

  .title {
    justify-content: center;
  }

  .icon {
    display: flex;
    justify-self: center;
    min-width: 64px;
    min-height: 64px;
    margin: grid-gap(1.25) 0;
  }
}

@include media(">=screen-sm") {
  .withNavIcon .icon {
    min-width: 40px;
    min-height: 40px;
    margin: grid-gap(0.75) 0;
  }
}

@include media(">=screen-xl") {
  .item {
    padding: grid-gap(1.5);
  }
}
