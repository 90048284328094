@use "sass:math"; @import "variables.scss";
$font-size-extra-large: 18px;

.modal {
  width: 100%;

  .previewContainer {
    &.rounded {
      img {
        border-radius: 6px;
      }
    }

    > div {
      margin-bottom: grid-gap(2);
      display: flex;
      align-items: center;

      > div {
        margin: 0 auto;
        width: 100%;
      }
    }

    a {
      width: 100%;

      &[class^="textLink__"] {
        display: inline-block;
      }
    }

    :global(.slick-slider),
    div[class^="creativeBoxContent__"],
    div[class^="carouselContainer__"] {
      width: 100%;
    }

    div[class^="creative__"] span {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .buttons {
    width: 100%;
    margin: 0 grid-gap(-2) grid-gap(-2) grid-gap(-2);
    padding: grid-gap(1) grid-gap(2);
    background-color: $color-brand-50-blue;
    position: sticky;
    bottom: grid-gap(-2.1);
    z-index: 100;

    .editButton {
      display: inline-flex;
      margin-right: grid-gap(1);
    }

    button:last-child {
      color: $color-neutrals-700-dark-grey;
    }
  }

  .placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 311px;
    text-align: center;
    border: 1px solid $color-neutrals-400-cold-grey;
    border-radius: 6px;
    padding: grid-gap(1);
    width: 100%;

    svg {
      margin-bottom: grid-gap(0.75);
      fill: $color-neutrals-800-dark;
    }

    h4 {
      font-weight: $font-weight-semi-bold;
      margin-bottom: grid-gap(0.5);
      color: $color-neutrals-800-dark;
    }

    .info {
      max-width: 336px;
      color: $color-neutrals-700-dark-grey;
    }

    @include media(">screen-sm") {
      min-height: 385px;
    }
  }

  .headerContainer {
    display: flex;
    align-items: center;
    margin-bottom: grid-gap(2);

    .iconWrapper {
      display: flex;
      align-items: center;
      min-height: 58px;

      svg {
        margin-right: grid-gap(0.5);
      }
    }

    .headerInfo {
      position: relative;
      color: $color-neutrals-800-medium-dark;
      margin-left: grid-gap(0.5);

      > div {
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-normal;
        align-items: center;

        span {
          font-weight: 600;
        }

        .missing {
          color: $color-system-status-bg-warning;
        }

        .dot {
          display: inline-block;
          border: 1px solid $color-campaign-status-cancelled;
          background-color: $color-campaign-status-cancelled;
          border-radius: 50%;
          width: 1px;
          height: 1px;
          margin: 0 grid-gap(0.5);
        }
      }

      h4 {
        font-size: $font-size-extra-large;
        line-height: 23px;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 6px;

        > div {
          font-weight: $font-weight-semi-bold;
        }
      }
    }

    .closeButton {
      display: flex;
      padding: grid-gap(0.5);
      border-radius: 6px;
      border: 0;
      margin-left: auto;
      align-self: baseline;
      cursor: pointer;

      svg {
        width: 11px;
        height: 11px;
        fill: $color-neutrals-700-dark-grey;
      }

      &:hover {
        background-color: $color-brand-50-blue;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
      }
    }
  }

  .cancelButton {
    &:hover {
      background: $color-brand-100-blue;
    }
  }

  .previewNotAvailable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: $color-neutrals-200-light-grey;
    color: $color-neutrals-800-dark;
    min-height: 170px;
    box-sizing: border-box;
    position: relative;
    width: 100%;

    .previewNotAvailableTitle {
      @include source-sans-pro("semi-bold");

      font-size: $font-size-large;
      line-height: 24px;
      margin-bottom: grid-gap(0.25);
    }

    .previewNotAvailableText {
      line-height: 24px;
      font-size: $font-size-large;
      color: $color-neutrals-700-grey;
      text-align: center;
    }

    .icon {
      margin-bottom: grid-gap(1.25);
    }

    @include media(">=screen-xs") {
      min-height: 240px;
      padding: grid-gap(1.5) grid-gap(1.5);
    }

    @include media(">=screen-sm") {
      min-height: 345px;
      padding: grid-gap(2) grid-gap(2.5);
    }

    @include media(">=screen-md") {
      min-height: 345px;
      padding: grid-gap(3) grid-gap(4.5);
    }
  }
}

.topBorderShadow {
  box-shadow: 0 -1px 4px 0 rgba($color-neutrals-black, 0.15);
}

.itemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    margin-left: grid-gap(0.25);
  }
}

.infoLink {
  margin-left: grid-gap(0.25);
}

.capitalize {
  text-transform: capitalize;
}
