@use "sass:math"; @import "variables.scss";
.uploadImageBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: grid-gap(1.5);
  width: 100%;
  border: 1px solid $color-neutrals-400-cold-grey;
  border-radius: 6px;
  padding: grid-gap(1.5) grid-gap(1.5) grid-gap(2);
  box-sizing: border-box;
  transition: border-color 0.3s ease;

  &Description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    font-size: $font-size-normal;
    color: $color-neutrals-700-dark-grey;
    line-height: 20px;
    text-align: center;

    &Icon {
      position: relative;
      bottom: -10px;
    }
  }

  &Button {
    width: 100%;
  }
}

.uploadImageBoxAdFormatsNotLoaded {
  opacity: 0.65;
  transition: opacity 0.4s ease-in-out;
}

.bold {
  font-weight: $font-weight-semi-bold;
}

.previewImageContent {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1);

  &Box {
    max-width: 400px;
    position: relative;
    border-radius: 4px;
    border: 1px dashed $color-neutrals-400-cold-grey;
    padding: grid-gap(0.1) grid-gap(0.25);
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: $color-neutrals-500-grey;
    }

    &:hover > div {
      opacity: 1;
    }

    img {
      width: 100%;
    }

    &Buttons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: row;
      gap: grid-gap(0.5);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      div[class*="ripple"] {
        padding: 0 grid-gap(0.5) !important;
      }

      .trashButton {
        div[class*="ripple"] {
          display: flex;
          align-items: center;
          box-sizing: border-box;

          span {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  &Buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.warningNotification.override.styles {
  display: flex;
  align-items: center;
  margin-top: grid-gap(0.5);
  background-color: $color-warning-100;

  aside[class*="customIcon"] {
    background-color: $color-warning-100;
  }
}

.warningBox {
  border: 1px solid $color-warning-500;
}
