@use "sass:math"; @import "variables.scss";
.customTooltip {
  div[class^="content__"] {
    word-break: break-word;
  }
}

div[class^="cell__"] {
  .name {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-normal;
    line-height: 16px;
    color: $color-neutrals-800-dark;
    text-decoration: none;
    max-width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:focus {
      outline: none;
    }

    &::after {
      display: none;
    }
  }
}
