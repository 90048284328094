@use "sass:math"; @import "variables.scss";
.menu {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  height: 100%;

  .menuItemWrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .separator {
    &::before,
    &::after {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: $color-neutrals-200-light-grey;
    }

    &.verticalSeparator {
      &::before {
        content: "";
        width: 1px;
        height: 24px;
        margin: 0 grid-gap(2);
      }

      &:last-of-type {
        &::after {
          content: "";
          width: 1px;
          height: 24px;
          margin: 0 grid-gap(1.5) 0 grid-gap(2);
        }
      }

      &:first-of-type {
        &::before {
          content: none;
        }
      }

      &.advertiserSwitchVisible {
        &::before,
        &::after {
          margin: 0 grid-gap(1);
        }

        &:last-of-type {
          &::after {
            margin: 0 grid-gap(0.5) 0 grid-gap(1);
          }
        }
      }

      &.onlyRightSeparator {
        &::before {
          content: none;
        }

        &::after {
          margin-left: grid-gap(1.5);
          margin-right: grid-gap(1.125);

          @include media(">=screen-xl") {
            margin-left: grid-gap(2);
            margin-right: grid-gap(1.625);
          }
        }
      }

      &.withoutSeparator {
        @include media(">=screen-lg") {
          &::after {
            content: none;
          }
        }
      }
    }

    &.horizontalSeparator {
      flex-direction: column;
      align-items: flex-start;

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        margin: grid-gap(1) 0;
      }

      &.userMenu {
        &::after {
          margin: grid-gap(0.25) 0;
        }
      }
    }
  }

  .loginSide {
    a {
      width: 100%;
      margin-left: 0;
      margin-bottom: grid-gap(1);
      color: $color-neutrals-700-grey;

      div[class*="ripple__"] {
        padding: 0 grid-gap(0.75) !important;

        span {
          display: flex;
          align-items: center;
          font-weight: $font-weight-normal;
        }
      }

      svg path {
        stroke: transparent;
      }
    }

    &:hover {
      a {
        background: $color-brand-600-primary-blue;
      }

      div[class*="ripple__"] {
        span {
          color: $color-neutrals-100-white;
        }
      }

      svg path {
        fill: $color-neutrals-100-white;
      }
    }
  }
}
