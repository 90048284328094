@use "sass:math"; @import "variables.scss";
.customSize {
  max-height: 640px;
  height: 100%;
}

.text {
  display: flex;
  flex-direction: column;

  .header {
    @include f-grotesk("demi");

    font-size: 24px;
    line-height: 32px;
    margin-bottom: grid-gap(0.25);
    color: $color-neutrals-800-dark;
  }

  .description {
    font-size: $font-size-large;
    line-height: 24px;
    margin-bottom: 0;
    color: $color-neutrals-700-dark-grey;
  }
}

.groupedButtons {
  display: flex;

  .button {
    margin-right: grid-gap(1);

    &:last-of-type {
      margin-right: 0;
    }
  }
}
