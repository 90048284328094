@use "sass:math"; @import "variables.scss";
.deliveryBox {
  padding: grid-gap(1);
  background-color: $color-brand-50-blue;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;

  @include media(">=screen-xs") {
    flex-wrap: nowrap;
  }

  .graphBox {
    width: 100%;
    margin-bottom: grid-gap(1);

    @include media(">=screen-xs") {
      width: 43%;
      margin-bottom: 0;
      padding-right: grid-gap(1);
    }
  }

  .infoBox {
    width: 100%;

    @include media(">=screen-xs") {
      min-width: 57%;
    }
  }
}

.infoBoxValue {
  &:not(:last-child) {
    margin-bottom: grid-gap(1);
  }
}

.dayIndicator {
  color: $color-neutrals-700-dark-grey;
}
