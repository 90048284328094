@use "sass:math"; @import "variables.scss";
.invoiceDetailsUserAddress {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.5);

  &Value {
    color: $color-neutrals-800-dark;
  }
}
