@use "sass:math"; @import "variables.scss";
.emptyIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0, -50%);

  path {
    stroke: $color-caution-500;
  }
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  button[class^="button"] {
    width: grid-gap(1.5);
    min-width: grid-gap(1.5);
  }

  .message {
    font-size: $font-size-large;
    color: $color-neutrals-600-medium-grey;
  }

  .icon {
    &:hover {
      path {
        fill: $color-brand-500-secondary-blue;
        stroke: $color-brand-500-secondary-blue;
      }
    }
  }

  .favoriteAdvertiser:hover {
    path {
      fill: $color-neutrals-600-medium-grey;
      stroke: $color-neutrals-600-medium-grey;
    }
  }

  .favoriteAndSelectedAdvertiser:hover {
    path {
      fill: $color-neutrals-600-medium-grey;
      stroke: $color-neutrals-600-medium-grey;
    }
  }

  [class^="checkboxLabel"] {
    line-height: grid-gap(1.25);
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.listItemEmptyInner {
  font-size: $font-size-normal;
  color: $color-neutrals-800-dark;
  background-color: $color-caution-100;
  border-radius: 6px;
  width: 100%;
  line-height: 20px;
  padding: grid-gap(0.75) grid-gap(1) grid-gap(0.75) grid-gap(3);
  box-sizing: border-box;
  margin: grid-gap(0.75) 0 0;
  position: relative;
}

.listItemFeatured {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  [class^="checkbox__"] {
    min-width: 22px;
  }
}

.listItemFeaturedChild {
  padding-left: grid-gap(1.5);
}

.inner {
  display: flex;
  align-items: center;
  width: 100%;
}

svg {
  &.toggleIcon {
    fill: $color-neutrals-700-dark-grey;
  }
}

.infoIcon {
  width: 18px;
  margin: grid-gap(0.25) 0 0 grid-gap(0.5);

  g {
    stroke: $color-neutrals-700-dark-grey;
    stroke-width: 2px;
  }

  &.biggerSize {
    width: 22px;
    height: 22px;
  }
}

.tooltipBox {
  max-width: 100%;
  width: 296px;

  [class^="title__"] {
    font-weight: $font-weight-normal;
  }

  &.tooltipBoxAdvertiser {
    svg.biggerSize {
      width: 22px;
      height: 22px;
    }
  }
}
