@use "sass:math"; @import "variables.scss";
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .header {
    @include f-grotesk("demi");

    font-size: $font-size-super-huge;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: $color-neutrals-800-dark;
    margin-bottom: grid-gap(1);

    @include media(">=screen-xs") {
      @include f-grotesk("bold");

      font-size: 40px;
      line-height: 50px;
    }
  }

  .description {
    @include source-sans-pro("regular");

    font-size: $font-size-large;
    line-height: 1.5em;
    color: $color-neutrals-700-dark-grey;
  }

  .registerButton {
    margin-bottom: grid-gap(2);
  }
}
