@use "sass:math"; @import "variables.scss";
.suggestedPackagesSection {
  position: relative;
}

.label {
  margin-bottom: grid-gap(0.75);

  b[class^="boldText"] {
    margin-left: grid-gap(-0.25);
  }
}

.calendar {
  width: 100%;
  margin-bottom: grid-gap(0.5);
}

.calendarInfo div[class^="title"] {
  font-weight: $font-weight-normal;
}

.campaignLengthInfo {
  font-size: $font-size-large;
  line-height: 24px;
  color: $color-neutrals-700-grey;
  margin-bottom: grid-gap(1.5);
  gap: grid-gap(0.25);
  display: flex;
  align-items: center;
}

.campaignLength {
  font-weight: $font-weight-semi-bold;
}

.spinner {
  width: 20px;
  height: 20px;
  background-color: $color-brand-50-blue;
  margin-left: grid-gap(0.25);
}
