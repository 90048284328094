@use "sass:math"; @import "variables.scss";
.addInvoiceDetailsButton {
  span[class^="buttonContent"] {
    color: $color-brand-accent-blue;
    text-wrap: nowrap;
  }

  &Icon {
    width: 12px;
    height: 12px;

    g {
      stroke: $color-brand-accent-blue;
    }
  }
}
