@use "sass:math"; @import "variables.scss";
.modal {
  padding: grid-gap(0.75);

  &Header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: grid-gap(0.5);

    &Title {
      font-size: $font-size-normal;
      line-height: 16px;
      color: $color-neutrals-700-dark-grey;
    }

    &Button {
      border-radius: 6px;
      padding: grid-gap(0.5);
      cursor: pointer;
      border: 2px solid transparent;

      svg {
        fill: $color-brand-600-primary-blue;
      }

      &:hover {
        background-color: $color-brand-100-blue;
      }

      &:active {
        border: 2px solid $color-brand-200-tertiary-blue;
      }
    }
  }
}
