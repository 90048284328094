@use "sass:math"; @import "variables.scss";
.actionsBoxInfo {
  padding: grid-gap(1.5) grid-gap(3.5) grid-gap(1.5) grid-gap(4);
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-bottom: solid 6px $color-warning-400-red;
  position: relative;
}

.title {
  font-size: $font-size-large;
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
  margin: 0 0 grid-gap(1);
}

.text {
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-normal;
  line-height: 20px;
}

.list {
  list-style-type: disc;
  margin-top: grid-gap(0.25);
  padding-left: grid-gap(1);
}

.warningIconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: $color-system-status-bg-200-warning;
  position: absolute;
  top: 24px;
  left: 24px;
}

.warningIcon {
  path {
    fill: $color-warning-500;
  }
}

.closeBtn {
  position: absolute;
  top: 24px;
  right: 27px;
}

svg {
  &.closeIcon {
    width: 10px;
    height: 10px;
    fill: $color-neutrals-700-dark-grey;

    &.closeIconDark {
      stroke: $color-neutrals-700-dark-grey;
    }
  }
}
