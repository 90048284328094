@use "sass:math"; @import "variables.scss";
.paymentSection {
  display: none;

  @include media(">=screen-sm") {
    display: flex;
    flex-direction: row-reverse;
  }
}

.bookButton {
  width: auto;
}

.summaryInfo {
  width: 470px;
  text-align: right;
  margin-top: 0;
  margin-right: grid-gap(2);
}

.wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
