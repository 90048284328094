@use "sass:math"; @import "variables.scss";
$previewSize: 40px;

.container {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: $previewSize;
  height: $previewSize;
  margin-right: grid-gap(0.5);
}

.number {
  @include source-sans-pro("regular");

  position: absolute;
  right: 0;
  top: 2px;
  display: inline-block;
  min-width: 12px;
  min-height: 12px;
  text-align: center;
  background: $color-neutrals-200-light-grey;
  border-radius: 50%;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.12);
  font-size: $font-size-small;
  line-height: 16px;
  color: $color-neutrals-700-dark-grey;

  &.warn {
    background: $color-system-status-bg-warning;
    color: $color-neutrals-100-white;
  }
}

.preview {
  width: $previewSize;
  height: $previewSize;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.placeholder {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $previewSize;
  height: $previewSize;
  border-radius: 50%;
  background: $color-brand-600-primary-blue;
  color: $color-neutrals-100-white;
}

.uploadNotAvailable {
  background-color: grey;
}

.placeholderIcon {
  width: 18px;
  stroke: $color-neutrals-100-white;
}

.zipWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $previewSize;
  height: $previewSize;
  border-radius: 50%;
  background: $color-brand-600-primary-blue;

  .zipIcon {
    width: 24px;
    height: 24px;

    path {
      stroke: $color-neutrals-100-white;
    }
  }
}

.iconWrapper {
  position: relative;
  width: $previewSize;
  height: $previewSize;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  border-radius: 50%;
  overflow: hidden;
}

.tooltip {
  div[class^="content__"] {
    margin: auto;
  }
}
