@use "sass:math"; @import "variables.scss";
.creative-ids-horseshoe.creative-ids {
  position: absolute;
  left: 0;
  top: -40px;
  right: 0;
}

.creative-ids-skyscraperright_1 {
  position: absolute;
  right: 0;
}

.creative-ids-skyscraperleft_1 {
  position: absolute;
  left: 0;
}

.creative-wrapper {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: relative;
}

.creative-item {
  transform-origin: center;
}

.creative-item-empty {
  width: 88px;
  height: 64px;
  background-color: $color-brand-50-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.creative-topboard {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 20;
  transform: translateX(-50%) scale(0.7) !important;
  transform-origin: top center;
}

.creative-skyscraperleft_1 {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  z-index: 20;
  transform: scale(0.699) !important; //container height to creative height ratio
  transform-origin: top left;
}

.creative-skyscraperright_1 {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  z-index: 20;
  transform: scale(0.699) !important; //container height to creative height ratio
  transform-origin: top right;
}

.creative-wallpaper {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background-size: cover;
}

.creative-wrapper-video {
  pointer-events: auto;
}

.creative-wrapper-horseshoe {
  display: flex;
  position: relative;
  top: 0;
  padding-bottom: 51.12%; //master creative height to width ratio, defines creative's container height
  height: auto !important;
  min-height: 32px;

  @include media("<=screen-md") {
    min-height: 150px;
  }

  .creative-wallpaper {
    transform: none !important;
  }

  @include media("<=screen-sm") {
    transform: scale(0.25) !important;
  }

  @include media("<=screen-md") {
    transform: scale(0.8);
  }
}

.creative-wrapper-fullscreen {
  .creative-banner {
    >div {
      width: 315px !important;
      position: relative;
      height: 560px !important;
      display: block !important;

      >iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.creative-wrapper-responsive {
  .creative-banner {
    >div {
      width: 700px !important;
      position: relative;
      height: 700px !important;
      display: block !important;

      >iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.creative-wrapper-takeover {
  .creative-banner {
    >div {
      width: 576px !important;
      position: relative;
      height: 324px !important;
      display: block !important;

      >iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.creative-wrapper-responsive-mobile {
  .creative-banner {
    >div {
      width: 500px !important;
      position: relative;
      height: 900px !important;
      display: block !important;

      >iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.creative-wrapper-responsive-desktop {
  .creative-banner {
    >div {
      width: 900px !important;
      position: relative;
      height: 500px !important;
      display: block !important;

      >iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.creative-wrapper-megapanorama {
  .creative-wallpaper {
    >div {
      display: none !important;
    }
  }
}

.creative-wrapper-main {
  position: relative;
  flex-flow: column;
}

.creative-wrapper-error {
  background: #f3f3f3;
}

.creative-wrapper-thumb,
.creative-wrapper-main {
  display: grid;
  align-content: center;
  justify-content: center;
}

.creative-wrapper-round {
  .creative-banner > div {
    transform: translateX(-50%) !important;
  }
}

.creative-panorama_top {
  z-index: 30;
}

.creative-wrapper-thumb {
  pointer-events: none;
  overflow: hidden;

  .creative-topboard {
    transform: translateX(-50%) scale(0.13) !important;
  }

  .creative-skyscraperleft_1 {
    transform: scale(0.13) !important;
  }

  .creative-skyscraperright_1 {
    transform: scale(0.13) !important;
  }
}

.creative-wrapper-thumb-horseshoe {
  width: 64px;
  max-height: 88px;
  padding-bottom: 0;
  transform: none !important;
}

.creative-wrapper-native.creative-wrapper-thumb {
  .creative-banner {
    transform: scale(0.16) !important;

    :global(.ad-native) {
      width: 580px;
    }
  }
}

.creative-wrapper-native.creative-wrapper-round {
  transform: translateX(0);

  .creative-banner {
    transform: scale(1) !important;

    iframe {
      width: 70px;
      height: 48px;
    }
  }
}

.creative-wrapper-responsive-desktop.creative-wrapper-thumb {
  .creative-banner {
    transform: scale(0.19) !important;
  }
}

.creative-wrapper-responsive-mobile.creative-wrapper-thumb {
  .creative-banner {
    transform: scale(0.1) !important;
  }
}

.creative-wrapper-takeover.creative-wrapper-thumb {
  .creative-banner {
    transform: scale(0.15) !important;
  }
}

.creative-wrapper-cornerad {
  .creative-panorama_top {
    position: absolute;
    top: 0;
    left: 60px;
  }

  .creative-wallpaper {
    right: auto;
    left: -10px;
  }

  &.creative-wrapper-thumb {
    .creative-wallpaper {
      transform: translateX(-40%) scale(0.18) !important;
    }

    .creative-panorama_top {
      position: static;
      margin-left: 25px;
    }
  }
}

.selected {
  box-shadow: 0 0 0 2px $color-brand-600-primary-blue;
  border-radius: 6px;
}

.resizeLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto !important;
  min-height: 150px;
}
