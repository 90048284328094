@use "sass:math"; @import "variables.scss";
.tooltipContent {
  display: flex;
  flex-direction: column;

  span:last-child {
    color: $color-neutrals-400-cold-grey;
  }
}

.cell {
  max-width: 145px;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
