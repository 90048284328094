@use "sass:math"; @import "variables.scss";
.paymentForm {
  display: grid;
  grid-gap: grid-gap(1);
  max-width: 100%;
  box-sizing: border-box;
}

.paymentFormButton {
  width: 100%;
}

.checkbox {
  width: fit-content;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: grid-gap(0.2);
}

.infoIcon {
  width: 12px;

  g {
    stroke: $color-neutrals-700-dark-grey;
  }
}

.infoNotification {
  width: 250px;
  max-width: 250px;
}
