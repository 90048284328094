@use "sass:math"; @import "variables.scss";
.drawer {
  background: $color-white;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2);
  height: 100%;
  overflow: auto;
  position: fixed;
  transition: transform 0.3s ease;
  top: 0;
  right: 0;
  transform: translateX(100%);
  scrollbar-gutter: stable;

  @include z-index(drawer);

  @include media(">=screen-xs") {
    width: 425px;
  }
}

.drawerOpen {
  transform: translateX(0);
  transition: transform 0.3s ease;
  overflow: auto;
}

.drawerInner {
  min-height: 100vh;

  @include media(">=screen-sm") {
    max-width: 414px;
  }
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

.backdropOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;

  @include z-index(drawer-overlay);
}
