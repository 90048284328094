@use "sass:math"; @import "variables.scss";
.button.buttonNested {
  &:hover {
    background: $color-brand-100-blue;
  }

  .icon {
    stroke: $color-warning-500;
  }
}
