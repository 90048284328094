@use "sass:math"; @import "variables.scss";
.wrapper {
  display: grid;
  grid-gap: 24px;
}

.textInput {
  width: 100%;

  input {
    min-height: 48px;
  }

  label,
  div[class^="limitIndicator__"] {
    line-height: 20px;
    margin-bottom: grid-gap(0.5);
  }

  input::placeholder {
    line-height: 16px;
    color: $color-neutrals-500-grey;
  }
}

.tooltip {
  margin-bottom: grid-gap(0.125);

  div[class^="icon__"] {
    width: 15px;

    svg g {
      stroke: $color-neutrals-800-dark;
    }
  }
}

.description {
  font-size: $font-size-small;
  line-height: 16px;
  color: $color-neutrals-700-dark-grey;
  margin-top: grid-gap(0.25);
}
