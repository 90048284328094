@use "sass:math"; @import "variables.scss";
.menuWrapper {
  display: flex;
  align-items: center;
  height: $header-height;
}

.logo {
  z-index: 3;

  img {
    height: 16px;

    @include media(">=screen-sm") {
      height: 24px;
    }
  }
}
