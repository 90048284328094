@use "sass:math"; @import "variables.scss";
.paymentFormHeadline {
  @include f-grotesk("demi");

  font-size: $font-size-huge;
  line-height: 32px;
}

.paymentFormInfo {
  font-size: $font-size-large;
  font-weight: $font-weight-normal;
  line-height: 24px;
  color: $color-neutrals-700-dark-grey;
}

@include media(">=screen-xs") {
  .paymentFormHeader {
    padding: grid-gap(2);
  }
}
