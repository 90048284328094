@use "sass:math"; @import "variables.scss";
@mixin linkItemAlt {
  height: auto;

  .linkItem {
    @include source-sans-pro("regular");

    border: 2px solid transparent;

    &:hover {
      background-color: $color-brand-100-blue;
      color: $color-neutrals-800-medium-dark;

      .linkItemIcon path {
        fill: $color-neutrals-800-medium-dark;
      }
    }

    &:focus {
      border: 2px solid $color-brand-300-blue;
    }

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

/* stylelint-disable no-descending-specificity */
.navigationItem {
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  .linkItem {
    padding: grid-gap(0.5) 0;
    display: block;
    box-sizing: border-box;
    position: relative;
    border-radius: 6px;
    width: 100%;
    font-size: $font-size-large;
    line-height: 24px;
    color: $color-neutrals-700-grey;

    &:focus {
      outline: none;
    }

    .linkItemIcon {
      width: 9px;
      margin-left: grid-gap(0.5);

      :global(path) {
        fill: $color-neutrals-700-grey;
      }
    }
  }

  &.user {
    margin: 0 grid-gap(0.75);

    .linkItem .linkItemIcon {
      display: none;
    }
  }

  &.side,
  &.user {
    &.campaigns,
    &.segments {
      margin-bottom: grid-gap(0.5);
    }

    .linkItem {
      &::before {
        position: absolute;
        width: 100%;
        height: 40px;
        border-radius: 6px;
        padding: 0 grid-gap(0.75);
        left: grid-gap(-0.75);
        top: 0;
        z-index: -1;
      }

      &:focus {
        &::before {
          content: "";
          box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
        }
      }

      &:hover {
        color: $color-neutrals-100-white;

        &::before {
          content: "";
          background-color: $color-brand-600-primary-blue;
        }

        .linkItemIcon {
          :global(path) {
            fill: $color-neutrals-100-white;
          }
        }
      }
    }
  }

  &.side {
    .linkItem {
      padding: grid-gap(0.5) grid-gap(0.75);

      &::before {
        padding: 0;
        left: 0;
      }
    }

    &.linkItemAlt {
      @include linkItemAlt;

      a {
        padding: grid-gap(0.5) grid-gap(0.75);
      }

      &:first-of-type {
        margin-bottom: grid-gap(1);
      }
    }
  }

  &.main {
    height: 100%;

    .linkItem {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      &::after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        bottom: 0;
        background-color: $color-brand-600-primary-blue;
      }

      &:focus::before {
        content: "";
        width: 100%;
        position: absolute;
        border-radius: 6px;
        padding: grid-gap(0.5) grid-gap(0.25);
        left: grid-gap(-0.25);
        height: 16px;
        box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
      }

      &:hover::after {
        content: "";
        background-color: $color-brand-200-tertiary-blue;
      }

      .linkItemIcon {
        display: none;
      }
    }

    &.linkItemAlt {
      @include linkItemAlt;

      margin-left: grid-gap(2);

      a {
        padding: grid-gap(0.25) grid-gap(0.5);
      }
    }

    &.withoutMargin {
      margin: 0;
    }

    &.altMargin {
      margin-right: grid-gap(0.875);
    }
  }

  &.main {
    .linkItemV2 {
      height: auto;
      padding: grid-gap(0.25) grid-gap(0.5);
      border-radius: 6px;

      &:hover::after {
        content: none;
      }

      &:focus::before {
        left: 0;
        right: 0;
        width: auto;
      }

      &.linkItemActive {
        color: $color-neutrals-800-dark;
        background-color: $color-neutrals-200-light-grey;

        &:hover::after {
          background-color: transparent;
        }
      }
    }
  }
}
