@use "sass:math"; @import "variables.scss";
.modal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-width: 536px;
}

.modalBody {
  display: flex;
  padding: grid-gap(1) grid-gap(1.5) grid-gap(1.5);
  width: calc(100% - 2 * grid-gap(1.5));
  gap: grid-gap(1);
  flex-direction: row;

  &Centered {
    flex-direction: column;
    align-items: center;
    padding: grid-gap(1.5) grid-gap(1.5) grid-gap(1);
  }
}

.texts {
  display: flex;
  width: 100%;
  flex-direction: column;

  &Centered {
    align-items: center;
    text-align: center;
  }
}

.header {
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: $font-size-extra-large;
  line-height: 24px;
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
}

.content {
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-large;
  line-height: 24px;
}

.footer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: grid-gap(1);
  padding: grid-gap(1) grid-gap(1.5);
  justify-content: flex-end;

  &Centered {
    justify-content: space-between;

    > * {
      flex: 1;
    }
  }
}

.iconWrapper {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.checkbox {
  margin-top: grid-gap(0.5);
}

.successBackground {
  background-color: $color-brand-100-green;
}

.warningBackground {
  background-color: $color-warning-100;
}

.cautionBackground {
  background-color: $color-caution-100;
}

.infoBackground {
  background-color: $color-system-status-bg-info;
}
