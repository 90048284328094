@use "sass:math"; @import "variables.scss";
/* stylelint-disable no-descending-specificity */

.button {
  @include source-sans-pro("semi-bold");

  display: flex;
  align-items: baseline;
  flex-direction: column;
  position: relative;
  font-size: $font-size-large;
  line-height: 40px;
  height: 40px;
  color: $color-neutrals-100-white;
  background-color: $color-brand-600-primary-blue;
  padding: grid-gap(0) grid-gap(1.5);
  border-radius: 6px;
  cursor: pointer;

  @include media(">=screen-xs") {
    margin-left: grid-gap(2);
  }

  &:hover {
    background-color: $color-brand-700-blue;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
  }

  &.disable {
    cursor: not-allowed;
    background: $color-neutrals-400-cold-grey;
    color: $color-neutrals-500-grey;

    &:focus {
      box-shadow: none;
    }
  }
}

.newCampaign {
  &.button {
    display: inline-flex;
    padding: 0 grid-gap(0.75);
  }
}
