@use "sass:math"; @import "variables.scss";
.summary {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.5);
}

.paymentAndInvoice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: grid-gap(0.5);

  [class*="paymentMethodsWrapper"] {
    margin-top: 0;
  }

  @include media(">=screen-xs") {
    flex-direction: row;
    align-items: end;
  }
}

.paymentMethod {
  [class*="paymentMethodsLabel"] {
    font-size: $font-size-large;
    font-weight: $font-weight-semi-bold;
    color: $color-neutrals-800-dark;
    line-height: 24px;
  }
}
