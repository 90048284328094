@use "sass:math"; @import "variables.scss";
.notification {
  display: flex;
  align-items: center;

  section {
    width: 100%;
  }

  div[class^="content__"] {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
  }
}

.notificationContent {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
}

.link {
  text-decoration: underline;
  margin-right: grid-gap(0.25);
  font-weight: $font-weight-semi-bold;
}
