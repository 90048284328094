@use "sass:math"; @import "variables.scss";
.column {
  box-sizing: border-box;
  margin: 0 grid-gap(-0.5);
  padding: grid-gap(0.75) grid-gap(0.5) grid-gap(0.75);
  border: 1px transparent solid;
  user-select: none;
  outline: none;

  .content {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    border: 1px transparent solid;
    margin: 0 grid-gap(-0.875);
    padding: 0 grid-gap(0.5);

    .position {
      @include source-sans-pro("regular");

      width: 20px;
      font-size: $font-size-large;
      color: $color-neutrals-700-dark-grey;
      margin-right: grid-gap(1);
      text-align: center;
    }

    .icon {
      width: 24px;
      text-align: center;
      margin-right: grid-gap(1);

      .lockIcon {
        width: 16px;
        height: 16px;

        * {
          stroke: $color-neutrals-500-grey;
        }
      }

      .dragIcon {
        cursor: move;
        width: 24px;
        height: 24px;

        * {
          stroke: $color-neutrals-700-dark-grey;
        }
      }
    }

    .toggle {
      .stickyName {
        @include source-sans-pro("regular");

        font-size: $font-size-large;
        color: $color-neutrals-800-dark;
      }

      flex: 1;
      display: flex;
      justify-content: end;
    }
  }

  .removeBtn {
    background-color: transparent;
    border: none;
    margin-right: grid-gap(1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    &:hover {
      background-color: $color-warning-100;
    }

    .binIcon {
      width: 16px;
      padding: grid-gap(0.25);
      margin-right: 0;
      stroke: $color-warning-500;
    }
  }

  &.dragging {
    .content {
      border-radius: 4px;
      border: 1px $color-brand-100-blue solid;
      background: $color-brand-50-blue;

      .position,
      .icon,
      .toggle {
        visibility: hidden;
      }
    }

    &:hover,
    &:focus {
      .content {
        border: 1px $color-brand-100-blue solid;
        background: $color-brand-50-blue;
      }
    }
  }

  &:not(.sticky, .dragging, .anyDragging) {
    &:hover,
    &:focus {
      .content {
        border-radius: 4px;
        border: 1px $color-brand-300-blue solid;
        background: white;
      }
    }
  }

  /* Hide line after dragged item */
  &.dragging + &.anyDragging {
    border-top: 1px transparent solid !important;
  }

  /* Show lines between item when dragging */
  &.anyDragging:not(.sticky, .dragging) {
    border-top: 1px $color-brand-200-tertiary-blue solid;

    &:last-child {
      border-bottom: 1px $color-brand-200-tertiary-blue solid;
    }

    /* Case when hover upper part */
    &.overPrev {
      border-top: 1px $color-brand-500-secondary-blue solid;
    }

    /* Case when hover lower part */
    &.overNext + &.anyDragging {
      border-top: 1px $color-brand-500-secondary-blue solid;
    }

    &.overNext:last-child {
      border-bottom: 1px $color-brand-500-secondary-blue solid;
    }
  }
}
