@use "sass:math"; @import "variables.scss";
.container {
  background-color: $color-neutrals-100-white;
  height: 100%;
}

.content {
  @extend %baseGrid;

  max-width: 652px;
  padding-top: grid-gap(3.125);
  color: $color-neutrals-800-dark;
  margin: 0 auto;

  @include media("<=screen-sm") {
    margin: 0 grid-gap(2.5);
  }

  @include media("<=screen-xs") {
    margin: 0 grid-gap(1.5);
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title {
  font-size: $font-size-extra-huge;
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;

  @include media("<=screen-xs") {
    flex-direction: column;
    font-size: $font-size-huge;
  }
}
