@use "sass:math"; @import "variables.scss";
.progressStatus {
  display: flex;
  align-items: center;
  gap: grid-gap(1);

  &Label {
    font-size: $font-size-large;
    color: $color-neutrals-700-dark-grey;
  }
}
