@use "sass:math"; @import "variables.scss";
.icon {
  vertical-align: middle;
}

.whiteIcon {
  @extend .icon;

  fill: $color-white;
}

.brandBlueIcon {
  @extend .icon;

  fill: $color-brand-blue;
}

.blueIcon {
  @extend .icon;

  fill: $color-deep-blue;
}

.greenIcon {
  @extend .icon;

  fill: $color-green;
}

.redIcon {
  @extend .icon;

  fill: $color-red;
}

.darkGreyIcon {
  @extend .icon;

  fill: $color-dark-grey;
}

.blueButtonIcon {
  @extend .blueIcon;

  margin-right: 16px;
}
