@use "sass:math"; @import "variables.scss";
.noOptionsMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 55px;
  flex-direction: column;

  .icon {
    margin-bottom: 8px;

    path {
      stroke: $color-neutrals-700-dark-grey;
    }
  }

  .message {
    display: flex;
    text-align: center;
    font-size: $font-size-large;
    line-height: grid-gap(1.5);
    font-weight: $font-weight-normal;
    color: $color-neutrals-700-grey;
  }
}

.renderedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  button[class^="button"] {
    width: grid-gap(1.5);
    min-width: grid-gap(1.5);
  }

  .favoritesButton {
    &:hover {
      background: none;
    }

    &:focus {
      background: none;
      box-shadow: none;
      border: none;
    }
  }

  .message {
    font-size: $font-size-large;
    color: $color-neutrals-600-medium-grey;
  }

  .favoriteAdvertiser {
    path {
      fill: $color-brand-500-secondary-blue;
      stroke: $color-brand-500-secondary-blue;
    }
  }

  .favoriteAndSelectedAdvertiser {
    path {
      fill: $color-neutrals-100-white;
      stroke: $color-neutrals-100-white;
    }
  }

  .favoriteAdvertiser:hover {
    path {
      fill: $color-neutrals-600-medium-grey;
      stroke: $color-neutrals-600-medium-grey;
    }
  }

  .favoriteAndSelectedAdvertiser:hover {
    path {
      fill: $color-neutrals-600-medium-grey;
      stroke: $color-neutrals-600-medium-grey;
    }
  }

  .notFavoriteAdvertiser {
    &:hover {
      path {
        fill: $color-brand-500-secondary-blue;
        stroke: $color-brand-500-secondary-blue;
      }
    }
  }

  .text {
    line-height: grid-gap(1);
    padding-right: grid-gap(0.25);
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
