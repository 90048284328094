@use "sass:math"; @import "variables.scss";
.invoiceDetailsInputField {
  width: 100%;

  ::placeholder {
    color: $color-neutrals-600-medium-grey;
  }

  [class^="title"] {
    line-height: unset;
  }
}
