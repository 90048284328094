@use "sass:math"; @import "variables.scss";
.metricTitleBox {
  display: flex;
}

.metricTitle {
  @include source-sans-pro();

  font-size: $font-size-small;
  text-transform: uppercase;
  color: $color-neutrals-700-dark-grey;
  margin: 0 grid-gap(0.25) grid-gap(0.25) 0;
}

.tooltipInfoIcon {
  width: 11.25px;
  height: 11.25px !important;
}
