@use "sass:math"; @import "variables.scss";
.campaignName {
  width: 100%;

  ::placeholder {
    color: $color-neutrals-500-grey;
  }
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.5);
}
