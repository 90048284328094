@use "sass:math"; @import "variables.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: $font-size-extra-large;
  color: $color-neutrals-700-dark-grey;
  line-height: 24px;
  padding: grid-gap(5) 0;
  animation: fadeIn 1.2s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .title {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-large;
    line-height: 24px;
    color: $color-neutrals-800-dark;
    margin-bottom: grid-gap(0.25);
  }

  .description {
    @include source-sans-pro();

    font-size: $font-size-large;
    line-height: 24px;
    stroke: $color-neutrals-700-grey;
    max-width: 400px;
    padding: 0 grid-gap(1);

    &.narrow {
      max-width: 300px;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    margin-bottom: grid-gap(1);
    stroke: $color-neutrals-800-dark;
  }

  .button {
    margin-top: grid-gap(1.5);
  }
}

.noAdvertisersInfoBox {
  padding: grid-gap(4) 0;
  margin: 0 auto;
}
