@use "sass:math"; @import "variables.scss";
.landing {
  height: 100%;
  background-color: $color-neutrals-100-white;
}

.withArrow {
  position: relative;

  &::before {
    content: "";
    border: solid $color-neutrals-400-cold-grey;
    border-width: 0 1px 1px 0;
    display: inline-block;
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    margin-left: grid-gap(-0.5);
    bottom: -58px;
    padding: 8px;
    z-index: 9;
  }

  &::after {
    content: "";
    width: 1px;
    height: grid-gap(8);
    background-color: $color-neutrals-400-cold-grey;
    position: absolute;
    left: 50%;
    bottom: grid-gap(-3.75);
    z-index: 9;
  }
}
