@use "sass:math"; @import "variables.scss";
.publisherLogosWrapper {
  display: flex;
}

.publisherLogo {
  margin-left: -10px;
  width: 24px;
  height: 24px;

  &.iconsAlignmentRight {
    margin-left: 0;
    margin-right: -10px;
  }
}

.excessIndicator {
  margin-left: -9px;

  &.iconsAlignmentRight {
    margin-left: 0;
    margin-right: -9px;
  }

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    background-color: $color-brand-100-blue;
    color: $color-brand-accent-blue;
    font-weight: $font-weight-semi-bold;
  }

  &Tooltip {
    section {
      [class*="title"] {
        font-weight: $font-weight-normal;
      }

      [class*="content"] {
        display: flex;
        flex-direction: column;
        gap: grid-gap(0.5);
        color: $color-neutrals-400-cold-grey;
      }
    }

    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      li {
        margin-left: grid-gap(1);

        &::before {
          content: "•";
          margin-right: grid-gap(0.5);
        }
      }
    }
  }
}
