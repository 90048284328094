@use "sass:math"; @import "variables.scss";
.loaderMobile {
  display: flex;

  @include media(">=screen-xs") {
    display: none;
  }
}

.loaderDesktop {
  display: none;

  @include media(">=screen-xs") {
    display: flex;
  }
}
