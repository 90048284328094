@use "sass:math"; @import "variables.scss";
.values {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  line-height: 16px;
  margin: grid-gap(0.75) 0;

  .delivered {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-normal;
    color: $color-neutrals-800-dark;
  }

  .divider {
    color: $color-neutrals-700-dark-grey;
    margin: 0 grid-gap(0.25);
  }

  .goal {
    @include source-sans-pro("regular");

    font-size: $font-size-small;
    color: $color-neutrals-700-dark-grey;
  }
}

.emptyValue {
  font-size: $font-size-extra-large;
}
