@use "sass:math"; @import "variables.scss";
.drawer {
  width: 100vw;
  overflow: visible;

  [class*="drawerInner"] {
    height: 100%;
    max-width: none;
    position: relative;
  }

  &CloseButton {
    position: absolute;
    right: -10px;
    top: 20px;
    box-shadow: none;

    @include media(">=screen-xl") {
      left: -10px;
    }
  }

  @include media(">=screen-xl") {
    max-width: 970px;
  }
}

.adManagerTitle {
  [class*="title"] {
    font-size: $font-size-extra-large;
  }
}

.tabs {
  margin-bottom: grid-gap(1);

  [class*="tabItemsWrapper"] {
    overflow: visible;
    margin: 0 !important;
  }

  [class*="leftButton"],
  [class*="rightButton"] {
    display: none !important;
  }

  [class*="tabItem"] {
    padding-left: grid-gap(0.5);
    padding-right: grid-gap(0.5);

    [class*="active"] {
      [class*="regularText"] {
        color: $color-brand-600-primary-blue;
      }

      [class*="linkContentWrapper"] svg g {
        stroke: $color-brand-600-primary-blue;
      }
    }
  }
}

.actionButtons {
  display: flex;
  gap: grid-gap(0.5);
  margin-top: grid-gap(1.5);
  margin-bottom: grid-gap(3);
  justify-content: end;
}

.sectionBoxWrapper {
  // TODO Below are added to have scroll inside box
  // In case it turns out every box should have its own scroll it can be added to section box component
  height: 100%;
  overflow: auto;
  padding: grid-gap(1.5) grid-gap(1);

  @include media(">=screen-md") {
    padding: grid-gap(1.5);
  }
}

.link {
  color: $color-brand-300-blue;
}
