@use "sass:math"; @import "variables.scss";
.creativeUpload {
  display: flex;
  flex-direction: column;
}

.imageUploadForms {
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: grid-gap(0.75);
  margin-bottom: grid-gap(0.85);
  align-items: flex-start;

  @include media(">screen-xs") {
    flex-direction: row;
    grid-template-columns: 0.6fr 1fr;
  }
}

.imageUploadForms > div {
  min-height: 42vh;
}

.imageUploadForms > div:first-child {
  min-height: 32vh;
}

.landingPageUrlWrapper {
  position: relative;
}
