@use "sass:math"; @import "variables.scss";
.headerCell {
  display: flex;
  position: relative;
  z-index: 1;

  &:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    background: $color-brand-100-blue;
    position: absolute;
    z-index: -1;
    left: -(grid-gap(0.5));
    top: -(grid-gap(0.25));
    padding: grid-gap(0.25) grid-gap(0.5);
    box-sizing: content-box;
    border-radius: 4px;
  }

  &:focus {
    outline: none;
  }
}

.activeSort {
  color: $color-neutrals-800-dark;
}

.infoLink {
  margin-left: grid-gap(0.25);
  text-transform: none;
}

.tooltipInfoIcon {
  width: 11px;
  height: 11px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
