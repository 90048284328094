@use "sass:math"; @import "variables.scss";
.campaignBlock {
  display: flex;
  flex-direction: row;
  padding: grid-gap(1);
  background-color: $color-neutrals-100-white;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  gap: grid-gap(2.75);
}

.marginTop {
  margin-top: grid-gap(1);
}
