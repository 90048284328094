@use "sass:math"; @import "variables.scss";
.adFormatSwitcher {
  width: auto;
  box-sizing: border-box;

  svg path {
    stroke: $color-neutrals-700-dark-grey;
  }

  p {
    margin-left: grid-gap(0.5);
  }

  [class*="tabItemsWrapper"] {
    height: 100%;
  }

  [class*="tabItem"] {
    height: 100%;
    box-sizing: border-box;

    [class*="active"] {
      svg path {
        stroke: $color-brand-600-primary-blue;
      }
    }
  }
}
