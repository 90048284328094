@use "sass:math"; @import "variables.scss";
.titleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: grid-gap(0.75);

  .fieldNumber {
    @include f-grotesk("demi");

    min-width: grid-gap(1.5);
    line-height: grid-gap(1.5);
    background: $color-brand-600-primary-blue;
    color: $color-neutrals-100-white;
    font-size: $font-size-large;
    text-align: center;
    border-radius: 50%;
    margin-right: grid-gap(0.5);

    &::before {
      counter-increment: field-counter;
      content: counter(field-counter);
    }
  }

  .title {
    @include f-grotesk("demi");

    font-size: 18px;
    color: $color-neutrals-800-dark;
  }

  &.disabled {
    .fieldNumber {
      background: $color-neutrals-600-medium-grey;
    }

    .title {
      color: $color-neutrals-700-dark-grey;
    }
  }
}

.subTitle {
  @include source-sans-pro("semi-bold");

  font-size: $font-size-large;
  line-height: 24px;
}

.description {
  line-height: 20px;
  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
  margin-bottom: grid-gap(0.5);
}

.information {
  margin-left: grid-gap(0.5);
}
