@use "sass:math"; @import "variables.scss";
.label {
  margin-bottom: grid-gap(0.75);
}

.calendar {
  width: 100%;
  margin-bottom: grid-gap(1);
}

.budgetAndImpressions {
  margin-bottom: grid-gap(1.5);
}

.reach {
  margin-bottom: grid-gap(0.5);
}
