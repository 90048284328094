@use "sass:math"; @import "variables.scss";
.filterBox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    "search"
    "responsible"
    "admarketStatus"
    "selectedFilters"
    "notificationWrapper";
  background-color: $color-brand-50-blue;
  padding: grid-gap(1);
  border-radius: 6px;
  margin-bottom: grid-gap(1.5);
  column-gap: grid-gap(1);
}

.searchFilter {
  grid-area: search;
}

.responsibleFilter {
  grid-area: responsible;
}

.admarketStatusFilter {
  grid-area: admarketStatus;
}

.selectedFilters {
  grid-area: selectedFilters;
  display: flex;
  padding-top: grid-gap(1.5);
  margin-top: grid-gap(1.5);
  border-top: 1px solid $color-neutrals-300-warm-grey;
  justify-content: space-between;
}

.removeFiltersButton.customSize {
  svg.icon.small {
    width: 9px;
    height: 9px;
  }
}

.pillsList {
  max-width: calc(100% - 132px);
}

.notificationWrapper {
  grid-area: notificationWrapper;
  margin-top: grid-gap(0.5);
}

@include media(">=screen-xs") {
  .filterBox {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "search search"
      "responsible admarketStatus"
      "notificationWrapper notificationWrapper"
      "selectedFilters selectedFilters";
  }
}

@include media(">=screen-md") {
  .filterBox {
    padding: grid-gap(1.5) grid-gap(1);
    grid-template-columns: 40% auto 1fr;
    grid-template-areas:
      "search responsible admarketStatus"
      "notificationWrapper notificationWrapper notificationWrapper"
      "selectedFilters selectedFilters selectedFilters";
  }
}

@include media(">=screen-lg") {
  .filterBox {
    padding: grid-gap(1.5) grid-gap(2);
    column-gap: grid-gap(1.5);
    grid-template-columns: 50% auto 1fr;
  }
}
