// Ads Identity Guidelines -https://schibstedglobal.invisionapp.com/boards/9Q33CUKP3WHT4

$font-family: "OpenSans", sans-serif;
// OpenSans only has 3 weights
// 100-300; 400-500; 600-900
// see @font-face in base.css
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-size-extra-small: 10px;
$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-large: 16px;
$font-size-extra-large: 18px;
$font-size-huge: 24px;
$font-size-extra-huge: 28px;
$font-size-super-huge: 32px;

$color-brand-blue: #1eb8cb;
$color-deep-blue: #05657e;
$color-primary: $color-brand-blue;
$color-secondary: $color-deep-blue;
$color-text: $color-secondary;
$color-green: #4de5c6;
$color-yellow: #f7e968;
$color-pale-yellow: #fdfbe1;
$color-pale-green: #dbfaf4;
$color-pale-blue: #ecf9fa;
$color-sky-blue: #d3f1f5;
$color-medium-blue: #8edbe5;
$color-pink: #ffe3e7;
$color-peach: #fee0d2;
$color-red: #ff7187;
$color-orange: #fc9262;
$color-dark-grey: #9c9c9c;
$color-cold-grey: #dfdfdf;
$color-shady-grey: #979797;
$color-medium-grey: #bbb;
$color-neutral-grey: #d2d2d0;
$color-pale-grey: #f3f3f3;
$color-light-grey: #f9f9f9;
$color-azure: #f9fdfe;
$color-white: #fff;
$modal-text-color: rgb(72, 73, 70);

@import "main";
