@use "sass:math"; @import "variables.scss";
.notification {
  display: flex;
  align-items: center;
  margin-bottom: grid-gap(1);

  div[class^="content"] {
    margin: 0;
  }
}
