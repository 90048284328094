@use "sass:math"; @import "variables.scss";
.modal {
  padding: 0;

  @include media(">=screen-xl") {
    min-width: 500px;
  }
}

.bodyWrapper {
  padding: grid-gap(1.5);
  display: flex;
  gap: grid-gap(1);
}

.iconWrapper {
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: $color-caution-100;
}

.icon {
  width: 16px;
  height: 16px;
  fill: $color-caution-500;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  line-height: 24px;
  color: $color-neutrals-800-dark;
  margin: grid-gap(0.5) 0;
}

.description {
  font-size: $font-size-normal;
  color: $color-neutrals-700-grey;
  line-height: 20px;
  margin-bottom: grid-gap(1);
}

.checkboxWrapper {
  width: fit-content;
  height: 24px;
}

.footer {
  padding: grid-gap(0.75) grid-gap(1.5);
  background-color: $color-caution-100;
  display: flex;
  justify-content: flex-end;
  gap: grid-gap(0.5);
}

.stayButton,
.quitButton {
  &:focus {
    box-shadow: 0 0 0 2px $color-caution-100;
  }
}

.stayButton span {
  color: $color-caution-500;
}

.quitButton div[class^="ripple"] {
  background-color: $color-caution-500;
}
