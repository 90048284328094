@use "sass:math"; @import "variables.scss";
.tabs {
  min-height: 36px;
  margin-bottom: grid-gap(1.5);

  div[class^="tabItemsWrapper"] {
    width: 100%;
    min-height: 36px;
    overflow: visible;
    display: flex;

    .tabItem {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      min-height: 36px;
      width: 50%;
      margin-right: 0;
    }
  }
}

.loader {
  margin-bottom: grid-gap(1.5);
}
