@use "sass:math"; @import "variables.scss";
.adBuilder {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.5);

  @include media(">screen-md") {
    flex-direction: row;
    gap: grid-gap(2);
    justify-content: space-between;
  }
}

.finalImageEditorAndAlignment {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.5);
}

.adBuilderAllInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.5);
  flex-basis: 100%;
  min-width: 0;
}

.logoAndButtonText {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.5);

  @include media(">screen-xs") {
    flex-direction: row;
  }
}

.colorAndShadow {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.5);

  [class*="shadow"] {
    @include media(">screen-xs") {
      margin-bottom: grid-gap(0.5);

      &::after {
        content: "";
        position: absolute;
        left: -10px;
        height: 40px;
        width: 1px;
        background-color: $color-neutrals-300-warm-grey;
      }
    }
  }

  @include media(">screen-xs") {
    flex-direction: row;
    align-items: end;
  }
}

.subFormSwitcherWrapper {
  width: 100%;
  margin-top: grid-gap(0.75);

  [class*="leftButton"],
  [class*="rightButton"] {
    display: none;
  }
}

.imageInfoBoxWrapper {
  margin-top: grid-gap(0.85);
}

.imageUploaderWithPreview {
  & > div:first-child {
    border: 1px solid $color-neutrals-400-cold-grey;
    border-radius: 6px;
  }
}

.notificationStyles.override {
  width: 100% !important;
  box-sizing: border-box;
}
