@use "sass:math"; @import "variables.scss";
.successIcon {
  fill: $color-system-status-bg-400-success;
}

.warningIcon {
  fill: $color-warning-600;
}

.cautionIcon {
  fill: $color-system-status-bg-500-caution;
}

.infoIcon {
  fill: $color-system-status-bg-400-info;
}
