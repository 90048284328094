@use "sass:math"; @import "variables.scss";
.customCarousel {
  width: grid-gap(18);
  padding: grid-gap(1.5);

  div[class^="slick-track"] {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  button[class*="customArrowPrev"],
  button[class*="customArrowNext"] {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }

  button[class*="customArrowPrev"] {
    left: -25px;
  }

  button[class*="customArrowNext"] {
    right: -25px;
  }

  @include media(">=screen-xs") {
    width: grid-gap(28.25);
  }

  @include media(">=screen-sm") {
    width: grid-gap(38.625);

    div[class^="slick-track"] {
      align-items: flex-start;
    }
  }

  @include media(">=screen-md") {
    width: grid-gap(51.625);
  }

  @include media(">=screen-xl") {
    width: grid-gap(73);
  }
}

.post {
  text-align: center;

  .image {
    margin: auto;
    max-width: 64px;
  }

  h3,
  p {
    margin-top: grid-gap(0.5);
    font-size: $font-size-large;
    line-height: 24px;
    color: $color-neutrals-800-dark;
    padding: 0 grid-gap(1);
  }

  h3 {
    @include source-sans-pro("regular");
  }

  p {
    @include source-sans-pro("semi-bold");
  }

  span {
    @include source-sans-pro("regular");

    text-transform: uppercase;
    font-size: $font-size-small;
    line-height: 16px;
    color: $color-neutrals-700-dark-grey;
  }
}
