@use "sass:math"; @import "variables.scss";
.notification.addOneLevelSpecificity.addTwoLevelSpecificity {
  display: flex;
  align-items: center;
  background-color: $color-warning-100;

  aside[class*="customIcon"] {
    background-color: $color-warning-100;
    margin: 0 grid-gap(0.5) 0 0;
    padding: 0;
    width: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &::after {
    background-color: $color-warning-100;
  }

  div[class^="content"] {
    margin: 0;
  }
}
