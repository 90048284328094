@use "sass:math"; @import "variables.scss";
.labelWrapper {
  display: flex;

  .infoLinkWrapper {
    display: flex;
    align-self: center;
    margin-left: grid-gap(0.5);

    div[class^="icon__"] svg {
      height: 14px;
    }

    div[class^="tooltip__"] {
      margin-bottom: 0;
    }
  }
}
