@use "sass:math"; @import "variables.scss";
.searchInput {
  input {
    padding: 0 grid-gap(3.1) 0 grid-gap(3);
    min-height: 48px;
  }

  span[class^="icon__"] {
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -8px;

    svg {
      stroke: $color-brand-600-primary-blue;
    }
  }
}
