@use "sass:math"; @import "variables.scss";
.icon {
  color: $color-neutrals-700-dark-grey;
  margin-left: grid-gap(0.5);
}

.title {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: grid-gap(1);
  font-size: $font-size-large;
  font-weight: $font-weight-normal;
  color: $color-neutrals-700-dark-grey;
}

.titleText {
  font-weight: $font-weight-semi-bold;
}

.fieldWrapper {
  @include source-sans-pro("regular");

  margin-bottom: grid-gap(2);

  &:last-child {
    margin-bottom: 0;
  }
}
