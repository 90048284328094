@use "sass:math"; @import "variables.scss";
.container {
  display: flex;
}

.icon {
  svg path {
    stroke: $color-neutrals-700-dark-grey;
  }
}

.device,
.dimensions {
  margin-left: grid-gap(0.25);
  font-size: $font-size-small;
  line-height: 24px;
  color: $color-neutrals-700-dark-grey;
}

.dimensions::before {
  content: "\2022";
  margin-right: grid-gap(0.25);
  font-size: $font-size-extra-large;
  line-height: $font-size-extra-large;
}
