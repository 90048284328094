@use "sass:math"; @import "variables.scss";
.container {
  display: flex;
}

.icon {
  width: 32px;
  height: 32px;
  opacity: 0.6;
}

.websiteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  padding-bottom: grid-gap(0.375);
  border-bottom: 1px solid $color-neutrals-400-cold-grey;
  margin-left: grid-gap(0.75);

  &:hover {
    border-bottom: 1px solid $color-brand-300-blue;

    .icon {
      opacity: 1;
    }
  }
}

.websiteButtonActive {
  border-bottom: 1px solid $color-brand-600-primary-blue;

  .icon {
    opacity: 1;
  }
}
