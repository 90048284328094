@use "sass:math"; @import "variables.scss";
.box {
  position: relative;
  width: 334px;
  box-sizing: border-box;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 grid-gap(-1.5) grid-gap(-1.5);
  padding: grid-gap(0.75);
  box-shadow: 0 -1px 4px rgba(120, 3, 3, 0.15);

  &.modalButtons {
    margin: 0 grid-gap(-0.5) grid-gap(-0.5);
    box-shadow: none;
  }

  .buttonsGroup {
    button {
      margin-right: grid-gap(0.75);
    }
  }
}
