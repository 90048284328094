@use "sass:math"; @import "variables.scss";
.notification {
  width: 856px;
}

.successNotification {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: grid-gap(0.75);

  &Bullet {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: grid-gap(0.25);

    &Title {
      font-weight: $font-weight-semi-bold;
      color: $color-neutrals-800-dark;
    }

    &Content::first-letter {
      text-transform: capitalize;
    }
  }
}

.errorNotificationButton {
  margin-top: grid-gap(1);
  background-color: $color-system-status-bg-500-caution !important;
}
