@use "sass:math"; @import "variables.scss";
.languageSwitcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: grid-gap(1.5);

  .language {
    display: flex;
    align-items: center;
    margin: 0 grid-gap(0.5);
    padding: grid-gap(0.25) grid-gap(0.75);
    border-radius: 6px;
    border: 2px transparent solid;
    background: none;
    outline: none;

    img {
      margin-right: 5px;
      width: 24px;
    }

    span {
      @include source-sans-pro("regular");

      color: $color-neutrals-800-dark;
      font-size: $font-size-normal;
    }

    &:hover {
      background: $color-brand-200-tertiary-blue;
    }

    &:focus {
      border: 2px $color-brand-300-blue solid;
    }

    &.selected {
      background: $color-brand-100-blue;
    }
  }
}

.tabs {
  width: fit-content;
}
