@use "sass:math"; @import "variables.scss";
.paymentSummaryHeadline {
  @include f-grotesk("demi");

  display: none;
  font-size: $font-size-extra-large;
  line-height: 24px;
  color: $color-neutrals-800-dark;
  padding-bottom: grid-gap(0.5);
}

.paymentSummaryAmount {
  @include f-grotesk("demi");

  font-size: 32px;
  line-height: 40px;
  color: $color-neutrals-800-dark;
  display: none;
}

.paymentSummaryDetails {
  width: 100%;
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-large;
  position: relative;
  z-index: 10;

  tr {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: grid-gap(1);
  }

  td {
    text-align: right;
    white-space: nowrap;
  }

  td,
  th {
    padding: grid-gap(0.5) 0;
    vertical-align: middle;
  }

  tr:first-child {
    td,
    th {
      padding-top: 0;
    }
  }

  tr:last-child {
    td,
    th {
      padding-bottom: 0;
    }
  }

  .bold {
    td,
    th {
      @include source-sans-pro("semi-bold");

      color: $color-neutrals-800-dark;
    }
  }

  .separator {
    td,
    th {
      border-top: solid 1px $color-neutrals-400-cold-grey;
    }
  }
}

.poweredBy {
  color: $color-neutrals-800-dark;
  font-size: $font-size-large;
  position: absolute;
  bottom: 24px;
  display: none;
}

.paymentSummaryInfo {
  display: none;
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-large;
  line-height: 24px;
  padding-top: grid-gap(5);
}

@include media(">=screen-xs") {
  .paymentSummaryHeadline {
    display: block;
  }

  .paymentSummaryAmount {
    display: block;
  }

  .paymentSummaryDetails {
    margin-top: grid-gap(2);
  }

  .paymentSummaryInfo {
    display: block;
  }

  .poweredBy {
    display: block;
  }
}
