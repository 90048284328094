@use "sass:math"; @import "variables.scss";
.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $color-brand-50-blue;
  color: $color-neutrals-800-dark;
  text-align: center;
}

.placeholderMain {
  min-height: 472px;

  @include media("<=screen-sm") {
    min-height: 362px;
  }
}

.placeholderThumb {
  border-radius: 100px;
  width: 56px;
  height: 56px;

  @include media("<=screen-sm") {
    width: 36px;
    height: 36px;
    border-radius: 100%;
  }
}

.title {
  margin-top: grid-gap(1);
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
}

.description {
  margin-top: grid-gap(0.25);
  line-height: 20px;
  font-size: $font-size-normal;
}

.selected {
  box-shadow: 0 0 0 2px $color-brand-600-primary-blue;
}
