@use "sass:math"; @import "variables.scss";
.signUp {
  padding: grid-gap(6) grid-gap(1) 0;
}

.description {
  max-width: grid-gap(22.5);
  margin: 0 auto grid-gap(1.5);
}

.nodeWrapper {
  @extend %baseGrid;

  grid-gap: 0;
  text-align: center;
  grid-template-columns: 1fr;
  grid-template-areas: "node";

  .node {
    @include source-sans-pro("semi-bold");

    grid-area: node;
    font-size: $font-size-extra-large;
    line-height: 28px;
    color: $color-neutrals-800-dark;

    .link {
      @include source-sans-pro("semi-bold");
    }
  }
}
