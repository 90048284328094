@use "sass:math"; @import "variables.scss";
.paginationWrapper {
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  [class^="page__"] {
    @include source-sans-pro("semi-bold");

    // Because of ads-ui-kit
    div {
      @include media(">=screen-sm") {
        font-size: $font-size-extra-large;
      }
    }
  }
}

.navButton {
  background-color: $color-white;

  &:hover {
    background-color: $color-white;
  }

  svg {
    fill: $color-brand-600-primary-blue;
  }
}

.pagination,
.loader {
  padding: grid-gap(1) 0;
}
