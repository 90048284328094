@use "sass:math"; @import "variables.scss";
.format,
.url,
.type {
  @include source-sans-pro("regular");

  margin-bottom: grid-gap(0.75);
  display: block;
  font-size: $font-size-normal;
  line-height: $font-size-large;
  color: $color-neutrals-800-dark;
  padding-right: grid-gap(1.5);
  position: relative;

  &::after {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $color-neutrals-700-dark-grey;
    right: grid-gap(0.75);
    margin-top: -1.5px;
    margin-right: -1.5px;
    top: 50%;
  }

  @include media(">=screen-xs") {
    display: inline-block;

    &::after {
      content: "";
    }
  }
}

.type,
.url {
  padding-right: 0;

  &::after {
    content: none;
  }
}

.bold {
  @include source-sans-pro("semi-bold");

  display: inline-block;
}

.type .bold {
  text-transform: capitalize;
}

.url {
  display: inline-block;
  width: 100%;
  word-break: break-word;
}

.textLink {
  @include source-sans-pro("semi-bold");

  display: inline-block;
  outline: none;

  &::after {
    content: none;
  }
}

.modalTitle {
  font-size: 18px;
  color: $color-neutrals-100-white;
  display: block;
  margin-bottom: grid-gap(1);
}
