@use "sass:math"; @import "variables.scss";
.container {
  display: flex;
  align-items: center;

  [class^="search__"] {
    width: 100%;
    min-width: 70px;
    max-width: 120px;
    display: block;
  }
}

.separator {
  display: block;
  font-size: $font-size-large;
  color: $color-neutrals-700-dark-grey;
  padding: 0 grid-gap(0.8);
}
