@use "sass:math"; @import "variables.scss";
.statusLabel {
  display: flex;
  align-items: center;
}

.icon {
  width: 8px;
  height: 8px;
  margin-left: grid-gap(0.25);
}

.pendingIcon {
  stroke: $color-campaign-status-pending-text;
}

.reservedIcon {
  stroke: $color-system-status-bg-400-info;
}
