@use "sass:math"; @import "variables.scss";
.dataTable {
  background: $color-white;
  border-radius: 6px;

  th,
  td {
    display: table-cell;
    vertical-align: middle;
  }

  .dataGrid {
    overflow-x: auto;
    scrollbar-color: $color-neutrals-400-cold-grey
      $color-neutrals-200-light-grey;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-corner {
      background: $color-neutrals-200-light-grey;
    }

    &::-webkit-scrollbar-track {
      background: $color-neutrals-200-light-grey;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-neutrals-400-cold-grey;
      width: 2px;
      height: 2px;
      border: 5px solid rgba(0, 0, 0, 0);
      border-radius: 10px;

      &:hover {
        background: $color-neutrals-500-grey;
        width: 6px;
        height: 6px;
        border: 5px solid rgba(0, 0, 0, 0);
        border-radius: 10px;
      }
    }

    &.disabledScroll {
      overflow-x: hidden;
    }
  }

  .headerCheckboxCell {
    min-width: 62px;
    padding: grid-gap(1) 0;
    z-index: 30;
  }

  .headerCheckboxCell,
  .bodyCheckboxCell {
    padding-right: 16px;
    border-right: none;
  }

  .bodyRowClassName {
    margin: 0;
    display: table-row;

    td:first-child {
      border-left: 1px solid transparent;

      @include media("<=screen-sm") {
        padding-left: grid-gap(1);
      }

      @include media(">=screen-xxl") {
        padding-left: 0;
      }
    }

    &:nth-child(odd) {
      td {
        background-color: $color-neutrals-100-white;
      }
    }

    &:nth-child(even) {
      td {
        background-color: $color-brand-25-blue;
      }
    }

    &:hover {
      td {
        background: $color-brand-50-blue;
      }
    }

    border-radius: 0;
    cursor: pointer;
  }

  [class^="cell__"] {
    margin: grid-gap(0.75) 0;
  }
}

.tableHeader {
  border-top: 1px solid $color-brand-100-blue;
  border-bottom: 1px solid $color-brand-100-blue;
  margin: 0;
  display: table-row;
  border-left: 1px solid transparent;

  th {
    @include z-index(table-header-cell);

    background: $color-brand-25-blue;
    top: 0;

    @include media(">=screen-xs") {
      position: sticky;
    }
  }
}

.noResult {
  padding: grid-gap(2) 0;
}
