@use "sass:math"; @import "variables.scss";
@mixin focus-outline {
  outline: none;
  border-radius: 6px;
  box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
}

.thumbnail {
  height: 64px;
  width: 96px;
}

.zipIcon {
  width: 32px;
  height: 32px;
  stroke: $color-neutrals-black;
}

.creative {
  width: 100%;
  padding: 0;
  border-radius: 6px;
  background-color: $color-neutrals-200-light-grey;
  border: none;
  margin: 2px;

  .thumbnailImageWrapper {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;

    .photo {
      width: 100%;
      min-height: 64px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .iconWrapper {
      width: 100%;
      min-height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-neutrals-300-warm-grey;

      &.liteGray {
        background-color: $color-neutrals-200-light-grey;
      }

      .placeholder {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        color: $color-neutrals-800-dark;
        font-weight: 600;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      .expandIcon,
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:focus {
    @include focus-outline;
  }
}

.iframeWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
}
