@use "sass:math"; @import "variables.scss";
.detailsItem {
  display: flex;
  justify-content: space-between;

  &Name {
    color: $color-neutrals-700-dark-grey;
    line-height: 24px;
    margin-right: grid-gap(1);
  }

  &Content {
    line-height: 24px;
    font-weight: $font-weight-semi-bold;
    color: $color-neutrals-800-dark;
  }
}
