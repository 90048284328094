@use "sass:math"; @import "variables.scss";
.fieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.75);
}

.addFieldButton {
  margin-top: 0;

  &Margin {
    margin-top: grid-gap(0.75);
  }

  .buttonLabel {
    font-size: $font-size-normal;
    font-weight: $font-weight-normal;
    color: $color-neutrals-700-dark-grey;
  }
}

.crossIcon {
  width: 15px;
  height: 15px;

  g {
    stroke: $color-neutrals-700-dark-grey;
  }
}

.textInput {
  width: 100%;

  input::placeholder {
    color: $color-neutrals-500-grey;
  }
}

.removeFieldButton {
  border: none;
  border-radius: 4px;
  display: flex;
  padding: grid-gap(0.25);
}

.removalIcon {
  stroke: $color-warning-500;
  fill: none;
}
