@use "sass:math"; @import "variables.scss";
.budget {
  width: 50%;
}

.budgetInput {
  margin-bottom: grid-gap(0.5);

  input {
    min-height: 48px;
  }
}

.budgetInputLoading div[class^="inputContainer"] {
  span[class^="icon"] {
    left: calc(50% - 12px);
    top: calc(50% - 24px);
  }

  input[class^="input"] {
    background-color: $color-neutrals-100-white;
    color: $color-neutrals-100-white;
  }
}

.currency {
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-small;
  line-height: 16px;

  @include source-sans-pro("demi");
}

.minBudgetWrapper {
  font-size: $font-size-small;
  line-height: 16px;
  color: $color-neutrals-700-dark-grey;

  @include source-sans-pro("regular");

  span {
    @include source-sans-pro("semi-bold");
  }
}

.tooltip {
  div[class^="notification"] {
    margin-top: grid-gap(-0.25);

    div[class^="content"] {
      margin-right: grid-gap(0.25);
    }
  }
}
