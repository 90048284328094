@use "sass:math"; @import "variables.scss";
.icon {
  fill: $color-white;
  position: relative;
  top: -3px;
}

.statusField {
  [class^="search__"] {
    &::placeholder {
      color: $color-neutrals-700-grey;
    }
  }
}

.tabWrapper {
  box-sizing: border-box;

  div[class*="tabItemsWrapper__"] {
    display: flex;
    width: 100%;
  }

  div[class*="tabItem__"] {
    width: 100%;
    justify-content: center;
  }

  div[class*="active"] {
    svg path {
      stroke: $color-brand-600-primary-blue;
    }
  }
}
