@use "sass:math"; @import "variables.scss";
.statusLabel {
  display: flex;
  align-items: center;
}

.statusName {
  @include source-sans-pro("regular");

  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-neutrals-800-dark;
  line-height: 24px;
  padding: 0 grid-gap(0.75);
  border-radius: 100px;
  font-size: $font-size-large;
  white-space: nowrap;
}

.active {
  background-color: $color-campaign-status-active-background;
  color: $color-campaign-status-active-text;
}

.paused {
  background-color: $color-campaign-status-cancelled-background;
  color: $color-campaign-status-cancelled-text;
}

.pending {
  background-color: $color-campaign-status-pending-background;
  color: $color-campaign-status-pending-text;
}

.stopped {
  background-color: $color-campaign-status-cancelled-background;
  color: $color-campaign-status-cancelled-text;
}

.cancelled {
  background-color: $color-campaign-status-cancelled-background;
  color: $color-campaign-status-cancelled-text;
}

.ended {
  background-color: $color-campaign-status-ended-background;
  color: $color-campaign-status-ended-text;
}

.offer {
  background-color: $color-neutrals-300-warm-grey;
  color: $color-neutrals-700-dark-grey;
}

.reserved {
  background-color: $color-neutrals-300-warm-grey;
  color: $color-system-status-bg-400-info;
}

.statusBadge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: grid-gap(0.5);
  width: 12px;
  height: 12px;
  stroke: $color-brand-accent-blue;
  stroke-width: 2px;
}

.menuIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  path {
    stroke: $color-neutrals-800-dark;
  }
}
