@use "sass:math"; @import "variables.scss";
.wrapper {
  @extend %mainUltraLargeGrid;

  margin-bottom: grid-gap(1.5);
  grid-template-columns: 1fr;

  .notification {
    box-sizing: border-box;
    margin-top: grid-gap(1.5);

    div[class^="content__"] {
      max-width: 100%;
    }
  }
}

.link {
  color: $color-neutrals-800-dark;
}

.emailLink {
  .link {
    color: $color-brand-700-blue;
    text-decoration: none;

    &:hover {
      color: $color-brand-accent-blue;
    }
  }
}
