// Brand
$color-brand-700-blue: rgb(2, 89, 220); // #0259DC
$color-brand-600-primary-blue: rgb(15, 103, 236); // #0F67EC
$color-brand-500-secondary-blue: rgb(69, 143, 255);
$color-brand-400-blue: rgb(86, 178, 255); //  #56B2FF
$color-brand-300-blue: rgb(139, 200, 255); // #8BC8FF
$color-brand-200-tertiary-blue: rgb(196, 220, 245);
$color-brand-100-blue: rgb(231, 238, 245); // #E7EEF5
$color-brand-50-blue: rgb(243, 246, 250); // #F3F6FA
$color-brand-25-blue: rgb(252, 252, 255); //#FCFCFF
$color-brand-accent-red: rgb(255, 113, 107);
$color-brand-accent-blue: rgb(48, 88, 142); // #30588E
$color-brand-accent-norstat-blue: rgba(48, 88, 142, 0);
$color-brand-accent-light-blue: rgb(19, 130, 182); // #1382B6
$color-brand-100-green: rgb(231, 245, 235); // #E7F5EB
$color-brand-200-green: rgb(191, 238, 205); // #BFEECD
$color-brand-300-green: rgb(65, 180, 99); // #41B463
$color-brand-500-green: rgb(66, 112, 80); // #427050
$color-brand-700-violet: #465abf;
$color-brand-600-violet: #e2ddff;

// Neutrals
$color-neutrals-black: rgb(0, 0, 0);
$color-neutrals-900-black: rgb(24, 24, 23); // #181817
$color-neutrals-800-dark: rgb(49, 49, 47); // #31312F
$color-neutrals-800-medium-dark: rgb(56, 56, 53);
$color-neutrals-800-light-dark: rgb(89, 89, 89); //595959
$color-neutrals-700-dark-grey: rgb(100, 100, 95); // #64645F
$color-neutrals-700-grey: rgb(108, 108, 103); // #6C6C67
$color-neutrals-600-medium-grey: rgb(135, 135, 135); // #878787
$color-neutrals-500-grey: rgb(177, 177, 177); // #B1B1B1
$color-neutrals-400-cold-grey: rgb(223, 223, 223); //#DFDFDF
$color-neutrals-300-warm-grey: rgb(234, 229, 224); // #EAE5E0
$color-neutrals-200-light-grey: rgb(248, 246, 245); // #F8F6F5
$color-neutrals-175-light-grey: rgb(236, 241, 245); // #ECF1F5
$color-neutrals-150-light-grey: rgb(232, 232, 232);
$color-neutrals-125-light-grey: rgb(240, 240, 240); // #F0F0F0
$color-neutrals-100-white: rgb(255, 255, 255); // #FFFFFF

// New Campaign status
$color-campaign-status-active-background: rgb(208, 245, 202); // #D0F5CA
$color-campaign-status-active-text: rgb(24, 109, 32); // #186D20
$color-campaign-status-pending-background: rgb(229, 235, 255);
$color-campaign-status-pending-text: rgb(81, 97, 178);
$color-campaign-status-approved-background: rgb(226, 221, 255);
$color-campaign-status-approved-text: rgb(70, 90, 191);
$color-campaign-status-cancelled-background: rgb(251, 229, 193);
$color-campaign-status-cancelled-text: rgb(152, 98, 8);
$color-campaign-status-cancelled-background: rgb(251, 218, 194);
$color-campaign-status-cancelled-text: rgb(139, 109, 87);
$color-campaign-status-ended-background: rgb(184, 241, 224); // #B8F1E0
$color-campaign-status-ended-text: rgb(24, 120, 91); // #18D25B
$color-campaign-status-cancelled: rgb(119, 119, 114);

// System status (background)
$color-system-status-bg-500-success: rgb(64, 100, 75);
$color-system-status-bg-400-success: rgb(75, 129, 91); // #4B815B
$color-system-status-bg-250-success: rgb(176, 223, 189); // #B0DFBD
$color-system-status-bg-100-success: rgb(231, 245, 235);
$color-system-status-bg-success: rgb(97, 222, 134); // #61DE86
$color-system-status-bg-500-caution: rgb(148, 92, 0); // #A46907
$color-system-status-bg-400-caution: rgb(206, 133, 13); // #CE850D
$color-system-status-bg-250-caution: rgb(247, 219, 173); // #F7DBAD
$color-system-status-bg-200-caution: rgb(251, 229, 193);
$color-system-status-bg-100-caution: rgb(253, 239, 217); // #FDEFD9
$color-system-status-bg-caution: rgb(242, 172, 56); // #f2ac38
$color-system-status-bg-200-warning: rgb(250, 216, 216);
$color-system-status-bg-100-warning: rgb(245, 231, 231);
$color-system-status-bg-warning: rgb(195, 23, 5);
$color-system-status-bg-400-info: rgb(100, 98, 96); // #646260
$color-system-status-bg-info: rgb(234, 229, 223); // #EAE5DF

// Badges background
$color-badge-dimmed: rgb(196, 220, 245);

$color-placeholder-grey: rgb(208, 208, 208);
$color-placeholder-light-grey: rgb(118, 118, 118); // #767676

$color-warning-100: rgb(245, 231, 231); // #F5E7E7;
$color-warning-200: rgb(250, 216, 216);
$color-warning-300: rgb(255, 71, 71);
$color-warning-400-red: rgb(230, 33, 15); // #E6210F
$color-warning-500: rgb(192, 27, 12); // #C01B0C
$color-warning-600: rgb(153, 21, 9); // #991509

$color-caution-100: rgb(253, 239, 217); // #FDEFD9
$color-caution-500: rgb(164, 105, 7);

$overlay-color: rgba(0, 0, 0, 0.65);
$color-light-shadow: rgba(0, 0, 0, 0.102);

$blue-indicator-gradient: rgba(69, 143, 255, 1); // #458FFF;
$gray-loader-gradient: rgba(230, 230, 230, 1); // #e6e6e6;

$missing-value-indicator-font: rgba(164, 105, 7, 1); //#a46907
$missing-value-indicator-background: rgba(251, 229, 193, 1); //#fbe5c1

@mixin spinnerLinearGradient($color) {
  background: linear-gradient(left, $color 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $color 10%, rgba(255, 255, 255, 0) 42%);
}
