@use "sass:math"; @import "variables.scss";
.metricBox {
  margin: 0 0 grid-gap(1);

  .number {
    @include f-grotesk("demi");

    font-size: $font-size-huge;
    color: $color-neutrals-800-dark;
    line-height: 32px;
  }

  .smallTxt {
    font-size: $font-size-large;
    padding: 0 0 0 grid-gap(0.1);
  }

  [class^="icon__"] {
    position: relative;
    top: -2px;
    right: -1px;
  }

  [class^="tooltipInfoIcon__-_SEA"] {
    width: 15px;
    height: 15px !important;
  }
}

.tooltip {
  [class^="notification__"] {
    width: 296px !important;
    max-width: none;
    box-sizing: border-box;

    [class^="tooltipInfoIcon__"] {
      width: 22px !important;
      height: 22px !important;
    }
  }
}
