@use "sass:math"; @import "variables.scss";
.whatsNewSection {
  padding: grid-gap(3) 0 grid-gap(7) 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(">=screen-xs") {
    padding: grid-gap(3) 0 grid-gap(7) 0;
    align-items: flex-start;
  }

  @include media(">=screen-xl") {
    max-width: 1168px;
  }
}

.header {
  @include f-grotesk("demi");

  font-size: $font-size-extra-huge;
  line-height: 32px;
  color: $color-neutrals-800-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: grid-gap(1);

  @include media(">=screen-xs") {
    @include f-grotesk("bold");

    font-size: 40px;
    line-height: 48px;
    justify-content: flex-start;
    padding: grid-gap(0) grid-gap(2.5);
  }

  @include media(">=screen-lg") {
    padding: 0;
    position: relative;
    left: calc(50vw - 587px);
  }

  &::before {
    content: "";
    display: inline-block;
    height: 48px;
    width: 48px;
    background-image: url("../../../../../assets/img/voice-illustration.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: grid-gap(0.5);

    @include media(">=screen-xs") {
      height: 64px;
      width: 64px;
      margin-right: grid-gap(1);
    }
  }
}

.customCarousel {
  width: 100vw;

  // Important to set the same cards height
  [class^="slick-track"],
  [class^="slick-slide"] {
    display: flex;
    height: unset;

    @include media(">=screen-md") {
      margin-bottom: grid-gap(0.5);
    }
  }

  [class^="slick-track"] {
    left: 8px;

    @include media(">=screen-xs") {
      left: 40px;
    }

    @include media(">=screen-lg") {
      left: calc(50vw - 591px);
    }
  }

  // necessary to can drag only two cards
  @include media(">=screen-ul") {
    [class^="slick-slide"]:last-of-type [class^="card"] {
      margin-right: 1000px;
    }
  }
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: $color-neutrals-400-cold-grey;
  cursor: pointer;
}

.dots {
  margin-top: grid-gap(0.5);
  width: 100vw;
  display: flex !important; //needed to overwrite Ads UI Kit styles
  justify-content: center;
  align-items: center;
  column-gap: grid-gap(0.75);

  @include media(">=screen-md") {
    margin-top: grid-gap(1);
  }

  [class*="slick-active"] {
    .dot {
      background-color: $color-brand-600-primary-blue;
    }
  }
}
