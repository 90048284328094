@use "sass:math"; @import "variables.scss";
.radioGroup,
.checkboxGroup {
  margin-bottom: grid-gap(1);

  & > div {
    display: flex;
    flex-wrap: wrap;
  }
}

.radio,
.checkbox {
  margin-right: grid-gap(0.5);
  margin-bottom: grid-gap(0.5);

  &:last-of-type {
    margin-right: 0;
  }
}

.subtitle {
  line-height: 20px;
  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
  margin-top: grid-gap(1);
  margin-bottom: grid-gap(0.5);
}
