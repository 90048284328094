@use "sass:math"; @import "variables.scss";
.moreOptionsMenu {
  border-radius: 6px;
  background-color: $color-white;
  width: 300px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.optionBtn {
  font-size: $font-size-large;
  color: $color-neutrals-800-dark;
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  position: relative;
  padding: grid-gap(0.75) grid-gap(1) grid-gap(0.75) grid-gap(2.5);

  &:hover {
    background-color: $color-brand-50-blue;
  }
}

.listItem {
  .optionBtn {
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  &:not(:last-child) {
    .optionBtn {
      border-bottom: solid 1px $color-neutrals-200-light-grey;

      &.withDarkBorder {
        border-bottom: solid 1px $color-neutrals-400-cold-grey;
      }
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  path {
    stroke: $color-neutrals-800-dark;
  }
}

.infoLink {
  display: block;

  // Ugly one to override InfoLink styles
  [class^="linkContentWrapper__"] > div {
    width: 100%;
  }
}
