@use "sass:math"; @import "variables.scss";
.mainPageWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "headerSection" "whatsNewSection" "alternatingSections" "adFormatSection" "customerSection" "boxSection" "signup";
  padding-top: grid-gap(2);

  @include media(">=screen-xs") {
    padding-top: grid-gap(3);
  }

  @include media(">=screen-md") {
    padding-top: grid-gap(4);
  }

  .headerSection {
    grid-area: headerSection;

    @include media(">=screen-xl") {
      max-width: 1168px;
      margin: auto;
    }
  }

  .whatsNewSection {
    grid-area: whatsNewSection;
    background-color: $color-brand-50-blue;
  }

  .whatsNewButton {
    margin-top: 0;
    padding: grid-gap(0.5) grid-gap(1.5);

    &::before,
    &::after {
      content: none;
    }
  }

  .customerSection {
    grid-area: customerSection;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .alternatingSections {
    grid-area: alternatingSections;
    padding: grid-gap(7) grid-gap(1) 0;
    width: 100%;
    box-sizing: border-box;

    @include media(">=screen-xs") {
      section[class*="section__"] {
        max-width: 448px;
      }
    }

    @include media(">=screen-md") {
      padding-top: 128px;

      section[class*="section__"] {
        max-width: 944px;
      }
    }

    @include media(">=screen-xl") {
      section[class*="section__"] {
        max-width: 1168px;
      }
    }
  }

  .alternatingSectionsMainPage {
    @include media(">=screen-md") {
      &:nth-child(1) {
        grid-template-columns: 40% 60%;
      }

      &:nth-child(2) {
        grid-template-columns: 60% 40%;

        ul {
          margin: 0 grid-gap(2.75) 0 0;
        }
      }
    }

    [class*="listItem__"] {
      svg {
        min-width: 48px;
      }

      span {
        color: $color-neutrals-800-dark;
      }
    }
  }

  .adFormatSection {
    grid-area: adFormatSection;
  }

  .boxSection {
    grid-area: boxSection;
    padding-top: grid-gap(5.5);
    padding-bottom: grid-gap(5.5);

    @include media(">=screen-xs") {
      padding-top: grid-gap(7);
      padding-bottom: grid-gap(7);
    }
  }

  .signupSection {
    grid-area: signup;
  }
}

.withBg {
  background-color: $color-brand-50-blue;
  padding-top: grid-gap(5.5);
  padding-bottom: grid-gap(5.5);

  @include media(">=screen-xs") {
    padding-top: grid-gap(7);
    padding-bottom: grid-gap(6);
  }
}

.link::after {
  content: none;
}

.learnMoreLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: grid-gap(2.5);
  font-weight: $font-weight-semi-bold;
  color: $color-brand-600-primary-blue;
  padding: grid-gap(0.125) grid-gap(0.25);

  svg {
    width: 16px;
    height: 16px;
    margin-left: grid-gap(0.5);

    path {
      stroke: $color-brand-600-primary-blue;
    }
  }

  &:hover {
    color: $color-brand-accent-blue;

    svg path {
      stroke: $color-brand-accent-blue;
    }
  }

  &:focus {
    border-radius: 4px;
    box-shadow: 0 0 0 2px $color-brand-300-blue;
  }
}
