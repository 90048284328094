@use "sass:math"; @import "variables.scss";
.content {
  max-width: 854px;
}

.selectors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: grid-gap(1);
}

.customTabContainer {
  background-color: transparent;
  border: none;

  [class*="tabItemsWrapper"] {
    height: 100%;
    padding-left: 0;
  }

  [class*="tabItem"] {
    height: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 2px solid transparent;

    [class*="active"] {
      color: $color-neutrals-800-dark;
      border: 2px solid $color-neutrals-400-cold-grey;
      box-shadow: none;

      svg path {
        stroke: $color-neutrals-800-dark;
      }
    }
  }

  [class*="tabItem_"] {
    padding: grid-gap(0.25) grid-gap(0.75);

    &:hover {
      color: $color-brand-600-primary-blue;
      background-color: transparent;
      box-shadow: none;

      svg path {
        stroke: $color-brand-600-primary-blue;
      }
    }
  }
}
