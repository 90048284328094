@use "sass:math"; @import "variables.scss";
.overlay {
  position: absolute;
  height: 100%;
  width: 100%;

  .overlayLeft,
  .overlayRight,
  .overlayTop,
  .overlayBottom {
    position: absolute;
    opacity: 0.4;
    background: $color-warning-400-red;
  }

  .overlayLeft {
    top: 0;
    left: 0;
  }

  .overlayRight {
    top: 0;
    right: 0;
  }

  .overlayTop {
    top: 0;
    left: 0;
    width: 100%;
  }

  .overlayBottom {
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
