html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  background-color: white;
  font-family: OpenSans, sans-serif;
  font-weight: 400;
  overflow-y: scroll;
  overflow-x: hidden;
}

body.ReactModal__Body--open {
  overflow-y: hidden;
  padding-right: 15px;
}

@media only screen and (max-width: 580px) {
  body.ReactModal__Body--open {
    overflow: hidden;
  }
}

body.schibstedTheme {
  background: rgb(248, 246, 245);
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  font-weight: 400;
}

button {
  cursor: pointer;
}

@font-face {
  font-family: OpenSans;
  src: local("Open Sans"), local("OpenSans"),
    url("/assets/fonts/OpenSans.woff2") format("woff2"),
    url("/assets/fonts/OpenSans.woff") format("woff"),
    url("/assets/fonts/OpenSans.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans;
  font-weight: 300;
  src: local("Open Sans Light"), local("Open-Sans-Light"),
    url("/assets/fonts/OpenSans-Light.woff2") format("woff2"),
    url("/assets/fonts/OpenSans-Light.woff") format("woff"),
    url("/assets/fonts/OpenSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("Open-Sans-Semibold"),
    url("/assets/fonts/OpenSans-Semibold.woff2") format("woff2"),
    url("/assets/fonts/OpenSans-Semibold.woff") format("woff"),
    url("/assets/fonts/OpenSans-Semibold.ttf") format("truetype");
}

.basketItem-enter {
  opacity: 0.01;
}

.basketItem-enter.basketItem-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.basketItem-exit {
  opacity: 1;
}

.basketItem-exit.basketItem-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.basketItem-appear {
  opacity: 0.01;
}

.basketItem-appear.basketItem-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.pulsate {
  animation: pulsate 1.25s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.25;
}

@keyframes pulsate {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.overflow-visible {
  overflow: visible !important;
}

.ReactVirtualized__Table__Grid,
.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Table__headerTruncatedText {
  padding: 3px 0 !important;
}
