@use "sass:math"; @import "variables.scss";
.startHub {
  width: 100%;
  min-height: calc(100vh - 152px);
  background-color: $color-neutrals-100-white;
}

.wrapper {
  @extend %mainGrid;

  grid-template-columns: 1fr;
  grid-gap: grid-gap(1);
  margin-bottom: grid-gap(3);
}

.title {
  @include f-grotesk("demi");

  font-size: $font-size-huge;
  line-height: 32px;
  margin-top: grid-gap(2);
}

.smallTitle {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  line-height: 32px;
  margin-top: grid-gap(0.5);
}

.content {
  display: grid;
  grid-gap: grid-gap(0.5);
}

.active {
  background-color: $color-brand-25-blue;
  border-color: $color-brand-600-primary-blue;
}

.infoLink {
  @include f-grotesk("demi");

  div[class^="linkTitle__"] {
    color: $color-neutrals-800-dark;
    font-size: $font-size-extra-large;
    line-height: 24px;
    font-weight: $font-weight-semi-bold;
  }

  &:hover {
    color: inherit;
  }
}

.infoLinkLabel {
  color: $color-brand-accent-blue;

  div[class^="linkTitle__"] {
    font-size: $font-size-small;
    line-height: 16px;
  }

  .icon {
    width: 12px;
    height: 12px;

    g {
      stroke: $color-brand-accent-blue;
    }
  }

  &:hover {
    color: inherit;
  }
}

.label {
  background-color: $color-brand-100-green;
  color: $color-brand-500-green;
  padding: grid-gap(0.125) grid-gap(0.5);
  margin-left: grid-gap(0.5);
  display: none;
  border-radius: 4px;
  line-height: 16px;
  font-size: $font-size-small;
  text-transform: uppercase;
}

@include media(">=screen-xs") {
  .wrapper {
    grid-gap: grid-gap(1.5);
  }

  .label {
    display: flex;
  }

  .smallTitle {
    font-size: $font-size-huge;
  }

  .title {
    font-size: $font-size-extra-huge;
    margin-top: grid-gap(3);
  }

  .content {
    grid-template-columns: 1fr 1fr;
    grid-gap: grid-gap(1);

    &.advancedWrapper {
      grid-template-columns: 1fr;
    }
  }
}

@include media(">=screen-sm") {
  .content.advancedWrapper {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  .content.twoColumns {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.labelWrapper {
  display: flex;
}

.labelElement {
  background-color: $color-brand-100-blue;
  color: $color-brand-accent-blue;
  font-size: $font-size-small;
  line-height: 16px;
  padding: grid-gap(0.125) grid-gap(0.5);
  border-radius: 6px;
  text-transform: uppercase;

  div[class^="notification_"] {
    text-transform: none;
  }

  &:first-of-type {
    margin-right: grid-gap(0.5);
  }

  div[class^="infoLink__"] {
    color: $color-brand-accent-blue;
  }
}

.featureIndicator {
  border-radius: 4px;
  background-color: $color-brand-100-green;
  color: $color-brand-500-green;
  text-transform: uppercase;
  padding: grid-gap(0.125) grid-gap(0.5);
  font-size: $font-size-small;
  line-height: 16px;
}

.infoLinkContent {
  display: flex;
  align-items: center;
  gap: grid-gap(0.5);
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
}
