@use "sass:math"; @import "variables.scss";
.container {
  box-sizing: border-box;
  margin: 0 grid-gap(-1.5) 0;
  padding: 0 grid-gap(1.5) grid-gap(4);
  height: calc(100vh - 151px);
  overflow-y: scroll;

  @include media(">=screen-md") {
    margin: grid-gap(-0.75) grid-gap(-1.5) 0;
    padding: 0 grid-gap(1.5) grid-gap(1.5);
    max-height: 408px;
    height: auto;
  }
}

.embeddedContainer {
  overflow-y: visible;
  height: fit-content;
  padding: 0 grid-gap(1.5) grid-gap(1.5);

  @include media(">=screen-md") {
    max-height: none;
    height: fit-content;
  }
}
