@use "sass:math"; @import "variables.scss";
.link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  text-align: center;
  display: block;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: default;
}

.header,
.description {
  font-size: $font-size-large;
  line-height: 24px;
}

.header {
  margin: grid-gap(1) 0 grid-gap(0.25) 0;
  font-weight: 600;
  color: $color-neutrals-800-dark;
}

.description {
  font-weight: 400;
  color: $color-neutrals-700-grey;
  text-align: center;
}
