@use "sass:math"; @import "variables.scss";
.loadingStatus {
  background-color: $color-brand-100-blue;
  border-radius: 50%;
  padding: grid-gap(0.75);

  &Icon {
    width: 16px;
    height: 16px;

    &::after {
      background-color: $color-brand-100-blue;
    }
  }
}

.errorStatus {
  padding: grid-gap(0.75);
  border-radius: 50%;
  background: $color-system-status-bg-100-warning;

  &Icon {
    width: 16px;
    height: 16px;
  }
}

.successStatus {
  padding: grid-gap(0.5);
  border-radius: 50%;
  background: $color-system-status-bg-100-success;

  &Icon {
    fill: $color-system-status-bg-500-success;
    width: 24px;
    height: 24px;
  }
}
