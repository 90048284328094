@use "sass:math"; @import "variables.scss";
.ad {
  :global(.ad-native) {
    transform-origin: left top;
  }

  &.disabledLink a {
    pointer-events: none;
  }
}

@include media("<screen-lg") {
  // Override dynamicaly set inline styles
  .ad {
    height: auto !important;

    :global(.ad-native) {
      transform: none !important;
    }
  }
}
