@use "sass:math"; @import "variables.scss";
.campaignNameModal {
  background: $color-neutrals-200-light-grey;
  max-width: 548px;
  width: calc(100% - 32px);

  .form {
    width: 100%;
  }

  .campaignName {
    font-style: italic;
  }

  .header {
    margin-bottom: grid-gap(0.75);
    font-weight: 500;
    font-size: $font-size-extra-large;
    line-height: 24px;
    color: $color-neutrals-800-dark;
  }

  .description {
    margin-bottom: grid-gap(0.5);
    font-size: $font-size-normal;
    line-height: 20px;
    color: $color-neutrals-700-dark-grey;
    word-break: break-all;
  }

  .input {
    width: 100%;

    textarea {
      overflow: hidden;
      font-family: inherit;
    }
  }

  .notification {
    margin-top: grid-gap(1);
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 24px;

    @include media(">=screen-xs") {
      justify-content: flex-end;
    }

    .cancelButton {
      margin-right: grid-gap(0.75);
    }
  }
}
