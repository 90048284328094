@use "sass:math"; @import "variables.scss";
.loaderBox {
  margin-top: grid-gap(0.25);
  border-radius: 4px;
  max-width: 100%;
}

.box {
  width: 100%;
  max-width: 385px;
  position: relative;
}

.innerBox {
  display: flex;
  align-items: center;
  padding: grid-gap(1) grid-gap(0.5) grid-gap(1) grid-gap(0.5);
  border-radius: 6px;
  cursor: pointer;
  border: none;
  width: 100%;
  background-color: transparent;
  position: relative;
  max-width: 385px;

  &:hover,
  &.open {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
    outline: none;
  }

  @include media(">=screen-md") {
    padding: grid-gap(1) grid-gap(0.5) grid-gap(1) grid-gap(1);
  }
}

.text {
  font-size: $font-size-small;
  color: $color-neutrals-700-dark-grey;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0 0 grid-gap(0.25);
  text-align: left;
}

.iconBox {
  min-width: 48px;
  height: 48px;
  margin: 0 grid-gap(1) 0 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.success {
    background-color: $color-system-status-bg-100-success;

    svg,
    path {
      stroke: $color-brand-500-green;
    }
  }

  &.warn {
    background-color: $color-system-status-bg-100-warning;
    position: relative;

    svg,
    path {
      stroke: $color-warning-500;
    }

    &::after {
      display: block;
      content: "";
      width: 12px;
      height: 12px;
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: $color-warning-400-red;
      border-radius: 100%;
    }
  }
}

.contentBox {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.number {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
}
