@use "sass:math"; @import "variables.scss";
.bold {
  font-weight: $font-weight-semi-bold;
}

.indicator {
  background-color: $color-brand-50-blue;
  padding: grid-gap(1);
  margin-bottom: grid-gap(1.5);
  border-radius: 6px;
}

.range {
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  margin-bottom: grid-gap(0.25);

  .loader {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
    background: linear-gradient(270deg, $color-brand-accent-red 0.28%, $blue-indicator-gradient 19.5%, $blue-indicator-gradient 84.94%, $color-brand-accent-red 100%);

    &.updating {
      background: $color-brand-200-tertiary-blue;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba($gray-loader-gradient, 0) 0,
          rgba($gray-loader-gradient, 0.2) 20%,
          rgba($gray-loader-gradient, 0.5) 60%,
          rgba($gray-loader-gradient, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
      }
    }
  }
}

.legend {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.legendItem {
  display: flex;
  color: $color-neutrals-700-grey;
  font-size: $font-size-small;
  line-height: 20px;
}

.cursor {
  margin-left: grid-gap(-0.625);
  left: 0;
  top: grid-gap(-0.5);
  position: absolute;
  transition: left ease-in-out 0.3s;
}

.loader {
  margin-bottom: grid-gap(1.5);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
