@use "sass:math"; @import "variables.scss";
.container {
  @extend %ultraLargeWrapper;

  max-width: calc(100vw - 48px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconWrapper {
  background-color: $color-warning-200;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: grid-gap(1);

  .icon {
    width: 22px;
    height: 22px;
    fill: $color-warning-500;
  }
}

.content {
  max-width: 430px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  @include f-grotesk("demi");

  margin-bottom: grid-gap(0.25);
  line-height: 24px;
  text-align: center;
}

.description {
  color: $color-neutrals-800-dark;
  text-align: center;
  line-height: 24px;
  margin-bottom: grid-gap(1.5);
}

.linkWrapper {
  display: inline-block;

  .link {
    color: $color-brand-700-blue;
    text-decoration: none;

    &:hover {
      color: $color-brand-accent-blue;
    }
  }
}

.button {
  margin-bottom: grid-gap(0.5);
}
