$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

@mixin f-grotesk($variant: "bold") {
  @if $variant == "bold" {
    font-family: "F Grotesk", sans-serif;
    font-weight: $font-weight-bold;
  }

  @if $variant == "demi" {
    font-family: "F Grotesk Demi", sans-serif;
    font-weight: $font-weight-semi-bold;
  }
}

@mixin source-sans-pro($variant: "regular") {
  font-family: "Source Sans Pro", sans-serif;

  @if $variant == "regular" {
    font-weight: $font-weight-normal;
  }

  @if $variant == "semi-bold" {
    font-weight: $font-weight-semi-bold;
  }

  @if $variant == "bold" {
    font-weight: $font-weight-bold;
  }

  @if $variant == "italic" {
    font-weight: $font-weight-normal;
    font-style: italic;
  }

  @if $variant == "semi-bold-italic" {
    font-weight: $font-weight-semi-bold;
    font-style: italic;
  }
}
