@use "sass:math"; @import "variables.scss";
.logo {
  z-index: 3;

  img {
    height: 16px;

    @include media(">=screen-sm") {
      height: 24px;
    }
  }
}

.title {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  font-size: $font-size-extra-large;
  font-weight: 600;
  line-height: 24px;

  @include media("<=screen-xs") {
    display: none;
  }
}
