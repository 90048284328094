@use "sass:math"; @import "variables.scss";
.togglerWrapper {
  display: flex;
  align-items: center;

  .buttonWrapper .menuToggler {
    padding: 0;
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 32px;

    div[class^="ripple__"] {
      padding: 0 grid-gap(0.375);
      display: flex;
      align-items: center;

      span[class^="zIndex__"] {
        display: flex;
      }
    }

    .icon {
      vertical-align: middle;
      width: 20px;

      &,
      :global(g) {
        fill: $color-neutrals-800-dark;
        stroke: none;
      }
    }

    &:hover {
      background-color: $color-brand-100-blue;
    }
  }
}

.drawerWrapper {
  box-sizing: border-box;
  padding: grid-gap(1.5) grid-gap(2.5);
  width: 100vw;

  @include media(">=screen-xs") {
    width: 300px;
  }

  div[class^="drawerInner"] {
    min-height: fit-content;
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 276px;
}

.backButton {
  margin-bottom: grid-gap(2);

  @include media(">=screen-xs") {
    display: none;
  }
}

.arrowIcon g {
  stroke: $color-brand-600-primary-blue;
  stroke-width: 3;
}
