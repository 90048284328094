@use "sass:math"; @import "variables.scss";
.item {
  background-color: $color-brand-50-blue;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: grid-gap(2);
  min-height: 190px;
  justify-content: space-between;
  box-sizing: border-box;

  .text {
    display: flex;
    flex-direction: column;
  }

  h3 {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-extra-large;
    line-height: 1.5em;
    color: $color-neutrals-800-dark;
    margin-bottom: 0;
  }

  p {
    @include source-sans-pro("regular");

    font-size: $font-size-large;
    line-height: 1.5em;
    color: $color-neutrals-700-dark-grey;
    margin-bottom: 0;
  }

  svg {
    width: 30px;
    height: 30px;
    margin-bottom: grid-gap(3);

    path,
    g path {
      stroke: $color-brand-700-blue;
    }
  }

  @include media(">=screen-sm") {
    min-height: 260px;

    svg {
      margin-bottom: grid-gap(4);
    }
  }
}
