@use "sass:math"; @import "variables.scss";
.trackWrapper {
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s ease-in-out;
  margin-bottom: grid-gap(1.5);

  &:last-of-type {
    margin-bottom: 0;
  }

  @include media(">=screen-md") {
    margin-bottom: grid-gap(10);
  }
}

.visible {
  opacity: 1;
  transform: translateY(0);
}
