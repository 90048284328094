@use "sass:math"; @import "variables.scss";
.sections {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @include media(">=screen-xl") {
    grid-template-columns: 2fr 1fr;
  }
}

.sectionBox {
  min-height: calc(100vh - 152px);
}

.previewInContextContainer {
  margin-top: grid-gap(3);

  @include media("<=screen-xl") {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
