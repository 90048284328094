@use "sass:math"; @import "variables.scss";
.stepField {
  margin: grid-gap(1) 0 grid-gap(3) 0;
}

.tooltip {
  .notification {
    margin-top: grid-gap(0.25);
  }
}
