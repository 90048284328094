@use "sass:math"; @import "variables.scss";
.campaignList {
  padding-bottom: grid-gap(0.25);
  max-width: 100vw;
  margin: 0 auto;

  @include media(">=screen-xxl") {
    max-width: calc(100vw - 96px);
    margin: 0 grid-gap(3);
  }

  @include media(">=screen-ul") {
    max-width: $max-content-width-ultra-large;
    margin: 0 auto;
  }
}

.headerRow {
  padding-left: 0;
  max-width: none;

  th {
    border-right: 1px solid $color-brand-100-blue;
    border-top: 1px solid $color-brand-100-blue;
    border-bottom: 1px solid $color-brand-100-blue;
    margin: 0;
    padding: 0 grid-gap(1.5);
    height: 48px;
    box-sizing: border-box;

    &:last-child {
      border-right: transparent;
    }
  }

  .nameIdHeaderCell {
    z-index: 30;

    @include media("<=screen-sm") {
      padding-left: grid-gap(1);
    }

    @include media(">=screen-xxl") {
      padding-left: 0;
    }

    [class^="headerLabel__"] {
      @include media(">=screen-sm") {
        padding-left: grid-gap(1);
      }

      @include media(">=screen-lg") {
        padding-left: grid-gap(1.5);
      }

      @include media(">=screen-xxl") {
        padding-left: 0;
      }
    }
  }
}

.rowOdd td {
  background-color: $color-neutrals-100-white;
}

.rowEven td {
  background-color: $color-brand-25-blue;
}

.bodyRow {
  max-width: none;
  margin-bottom: 0;
  grid-gap: 0;

  td {
    min-height: 72px;
    border-right: 1px solid $color-brand-100-blue;
    margin: 0;
    padding: 0 grid-gap(1.5);
    box-sizing: border-box;
    align-items: center;
    white-space: nowrap;

    &:last-child {
      border-right: none;
    }
  }

  .nameIdBodyCell {
    white-space: normal;
  }
}

.actionButtonIcon {
  g,
  path {
    stroke: $color-white;
  }
}

.editCampaignActionButton,
.creativeActionButton {
  &[class^="buttonWrapper"] {
    display: flex;

    &::after {
      content: "";
      background: $color-brand-100-blue;
      width: 1px;
      height: 24px;
      display: inline-block;
      margin-left: grid-gap(1);
      vertical-align: top;
      margin-top: grid-gap(0.25);
    }
  }
}

.checkboxBodyCell {
  border-right: none;
}

.startDateHeaderCell,
.startDateBodyCell,
.endDateHeaderCell,
.endDateBodyCell,
.advertiserHeaderCell,
.advertiserBodyCell {
  position: relative;

  &:focus {
    div[class^="withSort__"] {
      ::before {
        content: "";
        width: 100%;
        position: absolute;
        border-radius: 4px;
        padding: grid-gap(0.25) grid-gap(0.5);
        left: -(grid-gap(0.5));
        top: -(grid-gap(0.25));
        height: 14px;
        box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
        box-sizing: content-box;
      }
    }
  }
}

.toastContainer {
  @include z-index(toast);

  top: grid-gap(5.5);
}

@include media(">=screen-xs") {
  .toastContainer {
    width: 372px;
    right: grid-gap(1.5);
  }
}

.dataGrid {
  max-height: calc(100vh - 304px);
}

.customTable {
  max-width: $max-content-width-ultra-large;
}

.dailyStamp {
  margin-left: grid-gap(0.5);
  width: 42px;
  height: 16px;
  text-align: center;
  font-size: $font-size-small;
  line-height: 16px;
  color: $color-neutrals-700-dark-grey;
  border: 1px solid $color-neutrals-400-cold-grey;
  border-radius: 100px;
}

.infinityIcon {
  margin-right: grid-gap(0.5);
}

.nested {
  [class^="nameIdBodyCell__"] {
    padding-left: grid-gap(2);
  }
}

.icon {
  margin-left: grid-gap(0.25);
}
