@use "sass:math"; @import "variables.scss";
.thirdPartyWrapper {
  border-radius: 4px;
  border: 2px dashed $color-neutrals-400-cold-grey;
  transform-origin: left top;
  overflow: hidden;
  background: $color-white;
  box-sizing: border-box;

  // Override dynamicaly set inline styles
  height: auto !important;

  :global(.ad-native) {
    transform: none !important;
  }
}

.transformWrapper {
  transform-origin: left top;
}
