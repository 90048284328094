@use "sass:math"; @import "variables.scss";
.notification.addOneLevelSpecificity.addTwoLevelSpecificity {
  box-sizing: border-box;
  width: max-content;
  background-color: $color-warning-100;

  aside[class*="customIcon"] {
    background-color: $color-warning-100;
  }

  aside[class*="content"] {
    color: $color-neutrals-700-dark-grey;
  }

  &::after {
    background-color: $color-warning-100;
  }

  @include media("<screen-md") {
    max-width: 100%;
  }
}
