@use "sass:math"; @import "variables.scss";
.weekCheckbox {
  margin: 0 grid-gap(1.5) grid-gap(1.5) 0;

  [class^="checkbox"] {
    min-width: 24px;
    min-height: 24px;
  }

  [class^="checkboxLabel"] {
    display: flex;
    align-items: center;
  }
}

.checkboxGroup {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  flex-wrap: nowrap;

  @include media(">=screen-md") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
