@use "sass:math"; @import "variables.scss";
.card {
  height: calc(100% - 32px);
  box-sizing: border-box;
  border-radius: 12px;
  background-color: $color-neutrals-100-white;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  padding: grid-gap(1);
  margin: grid-gap(1.5) grid-gap(0.5) grid-gap(0.5) grid-gap(0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 32px);
  max-width: 900px;

  @include media(">=screen-xs") {
    width: calc(100vw - 160px);
    padding: grid-gap(1.5);
  }

  @include media(">=screen-sm") {
    width: 55vw;
  }

  @include media(">=screen-md") {
    width: 80vw;
    margin: grid-gap(2) grid-gap(0.75) grid-gap(0.5) grid-gap(0.75);
    flex-direction: row-reverse;
    padding: grid-gap(3) grid-gap(3) grid-gap(1.5);
  }

  @include media(">=screen-lg") {
    width: 60vw;
  }
}

.withoutImage {
  @include media(">=screen-md") {
    width: 40vw;
  }

  @include media(">=screen-lg") {
    width: 30vw;
    max-width: 500px;
  }
}

.imageWrapper {
  width: 100%;
  height: 206px;
  margin-bottom: grid-gap(1);
  position: relative;
  display: flex;
  justify-content: center;

  @include media(">=screen-xs") {
    margin-bottom: grid-gap(2);
  }

  @include media(">=screen-md") {
    margin: 0;
  }
}

.defaultImage {
  align-items: center;

  &::after {
    content: "";
    height: 120px;
    width: 120px;
    background-image: url("../../../../../../assets/img/voice-illustration.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @include media(">=screen-xs") {
      height: 160px;
      width: 160px;
    }
  }

  @include media(">=screen-md") {
    display: none;
  }
}

.image {
  max-height: 246px;
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  @include media(">=screen-xs") {
    max-height: 252px;
  }

  @include media(">=screen-md") {
    max-height: 288px;
    left: initial;
    right: 0;
    transform: none;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  @include media(">=screen-md") {
    align-items: flex-start;
  }
}

.header {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  line-height: 24px;
  color: $color-neutrals-800-dark;
  margin-bottom: grid-gap(0.5);

  @include media(">=screen-xs") {
    font-size: $font-size-huge;
    line-height: 32px;
  }
}

.description {
  color: $color-neutrals-700-dark-grey;
  text-align: center;
  font-size: $font-size-large;
  line-height: 24px;
  padding-bottom: grid-gap(0.75);
  margin-bottom: auto;

  @include media(">=screen-md") {
    text-align: left;
    padding-bottom: grid-gap(1.5);
    margin-right: grid-gap(1.5);
  }
}
