@use "sass:math"; @import "variables.scss";
.container {
  text-transform: none;
  padding: grid-gap(0.125) grid-gap(0.5);
  margin-left: grid-gap(0.25);
}

.textCapitalize {
  display: inline-block;
  text-transform: capitalize;
}

.textNormal {
  text-transform: none;
}
