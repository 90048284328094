@use "sass:math"; @import "variables.scss";
.notification {
  justify-content: space-between;

  &[class*="success"] {
    background-color: $color-brand-100-green;

    &::before {
      content: none;
    }
  }

  div[class*="content"] {
    max-width: unset;
    color: $color-neutrals-800-dark;
    margin: 0;
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    line-height: 24px;
  }

  div[class*="iconButton"] {
    height: 16px;
    width: 16px;
    margin: 0;
  }
}

.notificationContent {
  display: flex;
  align-items: center;
  gap: grid-gap(0.5);
  color: $color-neutrals-800-dark;
}

.icon {
  height: 16px;
  width: 16px;
  overflow: visible;

  path {
    fill: $color-brand-500-green;
  }
}
