@use "sass:math"; @import "variables.scss";
.notification {
  width: 400px;
  box-sizing: border-box;

  b {
    font-weight: $font-weight-semi-bold;
  }
}

.supportEmail {
  &::after {
    content: none;
  }
}
