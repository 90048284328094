@use "sass:math"; @import "variables.scss";
.radioButtonsWrapper {
  flex-direction: row;
  gap: grid-gap(0.5);
  margin-top: grid-gap(0.75);

  div[class*="radioContainer"] {
    width: auto;
  }
}
