@use "sass:math"; @import "variables.scss";
.tooltipContent {
  white-space: initial;
  width: 100%;
}

.container {
  position: relative;
}

.editButton {
  position: absolute;
  bottom: 100%;
  right: 0;
  padding: 0 0 grid-gap(0.5) 0;

  &.wallpaper {
    right: auto;
    bottom: grid-gap(-2.625);
  }
}

.notification.wallpaper {
  margin-top: grid-gap(2.625);
}
