@use "sass:math"; @import "variables.scss";
$header-vertical-padding: grid-gap(2);
$header-content-height: grid-gap(6.5);

$tab-height: grid-gap(2.5);

$button-container-margin: grid-gap(2);
$button-container-bottom-spacing: grid-gap(0.75);
$button-height: grid-gap(2.5);
$button-margin: grid-gap(1);

$header-height: 2*$header-vertical-padding+$header-content-height; // 10.5
$button-section-height: $button-container-margin+2*$button-height+$button-margin+$button-container-bottom-spacing;//8.75

$max-tab-height: $header-height + 2*$tab-height + $button-section-height;
$no-timeline-max-tab-height: 310px;

.customTabs {
  width: fit-content;
  box-sizing: border-box;
  height: 40px;
}

.customTabItem {
  height: 32px;
}

.timeline {
  @include f-grotesk("demi");

  font-size: 18px;
  line-height: 24px;
  color: $color-neutrals-800-dark;
  margin-bottom: grid-gap(1);
}

.accordionBody {
  padding: grid-gap(2);
  max-height: calc(100vh - $max-tab-height);//#{grid-gap(25.75)}
  box-sizing: border-box;
  overflow-y: auto;
  opacity: inherit;

  &.noTimelineViewMode {
    max-height: calc(100vh - $no-timeline-max-tab-height);
  }
}

.accordionStylesReset {
  padding: 0;
  opacity: inherit;
}

.tabsDescription {
  color: $color-neutrals-700-dark-grey;
  margin: 0 0 grid-gap(0.75);
}

.tooltipText {
  &:not(:last-child) {
    margin: 0 0 grid-gap(1);
  }
}
