@use "sass:math"; @import "variables.scss";
.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div[class*="content"] {
    color: $color-neutrals-800-dark !important;
  }
}

.notificationContent {
  display: flex;
  align-items: center;
  gap: grid-gap(0.5);
  color: $color-neutrals-700-dark-grey;
}

.icon {
  height: 16px;
  width: 16px;
  overflow: visible;

  path {
    fill: $color-system-status-bg-400-info;
  }
}
