@use "sass:math"; @import "variables.scss";
.expandButton {
  margin: 0 grid-gap(0.5) 0 0;
}

.itemsCountIcon {
  display: flex;
  align-items: center;
  padding-left: grid-gap(0.125);
  margin-right: grid-gap(-0.125);
}

.arrowIcon {
  max-width: 4px;
  max-height: 4px;
  margin: 0 grid-gap(0.125);
  stroke: none;
  fill: $color-brand-accent-blue;
}

.isExpanded {
  transform: rotate(180deg);
}
