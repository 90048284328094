@use "sass:math"; @import "variables.scss";
.redirectButton {
  flex-shrink: 0;
  margin: 0 grid-gap(0.5) 0 0;
}

.pencilIcon {
  path {
    stroke: $color-brand-accent-blue;
  }
}

.disabled {
  border-color: $color-neutrals-400-cold-grey;
  background: $color-neutrals-400-cold-grey;

  path {
    stroke: $color-neutrals-500-grey;
  }
}

.notificationTooltip {
  min-width: 296px;
  box-sizing: border-box;
}
