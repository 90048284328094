@use "sass:math"; @import "variables.scss";
.loader {
  .previewContainer {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .headerData {
    display: inline-flex;
  }

  .headerIcon {
    margin-right: grid-gap(0.75);

    svg {
      width: 55px;
    }
  }

  .nameType {
    svg {
      width: 80px;
    }
  }

  .buttons {
    svg {
      max-width: 80px;
      height: 40px;
    }

    svg:first-child {
      max-width: 140px;
      margin-right: grid-gap(1);
    }
  }
}
