@use "sass:math"; @import "variables.scss";
.ctr {
  @include source-sans-pro("semi-bold");

  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: $font-size-normal;
  color: $color-neutrals-800-dark;
  line-height: 16px;
  margin: grid-gap(0.75) 0;
}
