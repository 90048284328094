@use "sass:math"; @import "variables.scss";
.container {
  margin: 0 0 grid-gap(1) 0;

  .linkContent {
    margin-right: grid-gap(0.5);
    font-size: $font-size-large;
    color: $color-neutrals-800-dark;
    font-weight: $font-weight-semi-bold;
  }

  .paragraph {
    font-size: $font-size-normal;
    line-height: 20px;
    color: $color-neutrals-700-dark-grey;
  }

  .infoLink {
    font-size: $font-size-large;
    margin: 0 0 4px;

    [class^="linkContentWrapper__"] {
      flex-direction: row-reverse;
    }

    svg.infoIcon {
      height: 18px;

      g {
        stroke: $color-neutrals-700-grey;
      }
    }
  }
}
