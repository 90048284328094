@use "sass:math"; @import "variables.scss";
.addCampaignWrapper {
  display: flex;
  padding: grid-gap(1.5) 0 grid-gap(0.5);
  margin: 0 grid-gap(1);

  @include media(">=screen-xxl") {
    max-width: $max-content-width-ultra-large;
  }
}

.button {
  font-size: $font-size-large;
  float: left;
}
