@use "sass:math"; @import "variables.scss";
.landingPageUrlWrapper {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;

  & > div {
    width: 100%;
  }

  & > [data-tippy-root] {
    transform: translate3d(0, 97px, 0) !important;
  }
}

.landingPageUrlInput {
  width: 100%;

  input::placeholder {
    color: $color-neutrals-500-grey;
  }

  [class*="label"] {
    width: 100%;
  }

  .landingPageUrlLabel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .landingPageUrlLabelLinkDisabled {
      color: $color-medium-grey;
      cursor: not-allowed;
    }
  }
}

.labelTitle {
  display: flex;
  align-items: center;
  gap: grid-gap(0.25);
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
  color: $color-neutrals-700-dark-grey;
  line-height: 24px;
  position: relative;
}

.infoLink {
  &Icon g {
    stroke: $color-neutrals-700-dark-grey;
  }

  &Tooltip {
    [class*="title"] {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      color: $color-neutrals-100-white;
      line-height: 24px;
    }

    [class*="content"] {
      font-size: $font-size-normal;
      color: $color-neutrals-400-cold-grey;
      line-height: 20px;
    }
  }
}
