@use "sass:math"; @import "variables.scss";
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: grid-gap(0.25);
  background: $color-neutrals-600-medium-grey;

  .text {
    text-transform: uppercase;
    color: $color-neutrals-100-white;
    font-size: 11px;
    font-weight: bold;
  }
}
