@use "sass:math"; @import "variables.scss";
.loader {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-neutrals-100-white;

  .spinner {
    width: 64px;
    height: 64px;
  }
}
