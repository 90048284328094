@use "sass:math"; @import "variables.scss";
.adBlockNotificationWrapper {
  @extend %mainGrid;

  grid-row-gap: grid-gap(0.5);
  margin-top: grid-gap(2);
  grid-template-areas:
    "image image image image"
    "header header header header"
    "description description description description"
    "instruction instruction instruction instruction";
}

.notificationImage {
  grid-area: image;
  padding: 0 grid-gap(2);
  margin-top: grid-gap(2);
}

.notificationHeader {
  grid-area: header;
  text-align: center;
  margin-top: grid-gap(1);

  h1 {
    @include f-grotesk("regular");

    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.2px;
  }
}

.notificationDescription {
  grid-area: description;
  text-align: center;
  padding: 0 grid-gap(1.5);

  p {
    @include source-sans-pro("regular");

    font-size: 16px;
    line-height: 23px;
    color: $color-neutrals-700-dark-grey;
  }
}

.notificationInstructions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-area: instruction;
  margin-top: grid-gap(1.5);

  .instructionItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: grid-gap(1);

    div {
      @include source-sans-pro("semi-bold");

      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-neutrals-800-dark;
      background-color: $color-neutrals-300-warm-grey;
      font-size: 16px;
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    p {
      @include source-sans-pro("semi-bold");

      margin-top: grid-gap(1);
      font-size: 16px;
      line-height: 22px;
      color: $color-neutrals-800-dark;

      a {
        @include source-sans-pro("semi-bold");

        color: $color-neutrals-800-dark;

        &::after {
          background-color: $color-neutrals-800-dark;
        }

        &:hover {
          color: $color-neutrals-800-dark;

          &::after {
            background-color: $color-neutrals-800-dark;
          }
        }
      }
    }
  }
}

@include media(">=screen-xs") {
  .adBlockNotificationWrapper {
    grid-template-areas:
      "... image image image image image image ..."
      "... header header header header header header ..."
      "... description description description description description description ..."
      "instruction instruction instruction instruction instruction instruction instruction instruction";
  }

  .notificationInstructions {
    flex-direction: row;
    align-items: baseline;

    .instructionItem {
      width: 170px;
    }
  }
}

@include media(">=screen-sm") {
  .adBlockNotificationWrapper {
    grid-template-areas:
      "... ... image image image image ... ..."
      "... ... header header header header ... ..."
      "... ... description description description description ... ..."
      "... instruction instruction instruction instruction instruction instruction ...";
  }

  .notificationImage {
    padding: 0 grid-gap(2);
  }

  .notificationInstructions {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;

    .instructionItem {
      width: 170px;
    }
  }
}

@include media(">=screen-md") {
  .adBlockNotificationWrapper {
    grid-template-areas:
      "... ... ... ... image image image image ... ... ... ..."
      "... ... ... header header header header header header ... ... ..."
      "... ... ... description description description  description description description ... ... ..."
      "... ... instruction instruction instruction instruction instruction instruction instruction instruction ... ...";
  }

  .notificationImage {
    padding: 0 grid-gap(1);
    margin-top: grid-gap(4);
  }

  .notificationDescription {
    padding: 0 grid-gap(3);
  }
}
