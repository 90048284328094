@use "sass:math"; @import "variables.scss";
.rangeComponent {
  position: relative;
  width: 100%;
  margin-bottom: grid-gap(0.5);
}

.label {
  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
  margin-bottom: grid-gap(0.5);
}

.calendarWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1001;
}

.input {
  width: 100%;

  [class^="icon"] {
    width: 18px;
    height: 18px;
    top: 50%;
    margin-top: -9px;
    cursor: pointer;
  }

  [class^="input__"] {
    @include source-sans-pro("regular");
  }
}

.calendarIcon {
  cursor: pointer;
}
