@use "sass:math"; @import "variables.scss";
.form {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0 grid-gap(1.5) grid-gap(1.5) grid-gap(1.5);
  overflow-y: auto;
  height: 100%;

  .bottomSpace {
    padding-bottom: grid-gap(3);
  }

  .fieldsWrapper {
    width: 100%;
  }
}
