@use "sass:math"; @import "variables.scss";
.summaryInfo {
  text-align: justify;
  margin-top: grid-gap(0.5);
  margin-right: 0;
  width: auto;
  font-weight: $font-weight-normal;
  font-size: $font-size-normal;
  line-height: 20px;
  color: $color-neutrals-700-dark-grey;
}

.termsAndConditions {
  color: $color-brand-600-primary-blue;
}
