@use "sass:math"; @import "variables.scss";
.overlay {
  background: rgba(0, 0, 0, 0.65);
}

.modal {
  background: transparent;
  justify-content: center;
  border: none;

  .modalWrapper {
    position: relative;
    max-width: 60vw;
    max-height: 60vh;
    width: 100%;
    height: 100%;
  }

  .modalHeader {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .modalTitle {
      font-size: 18px;
      color: $color-neutrals-100-white;
    }

    .modalCloseIcon {
      fill: $color-neutrals-100-white;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    .closeButton {
      position: relative;
      cursor: pointer;
      display: flex;

      &:hover {
        .modalCloseIcon {
          fill: $color-neutrals-400-cold-grey;
        }
      }

      &:focus {
        outline: none;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          left: grid-gap(-0.25);
          top: grid-gap(-0.25);
          padding: grid-gap(0.25);
          box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
        }
      }
    }
  }
}
