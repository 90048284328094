@use "sass:math"; @import "variables.scss";
.title {
  display: flex;
  align-items: center;
  gap: grid-gap(0.5);
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
  color: $color-neutrals-800-dark;
  line-height: 24px;
}

.secondaryTitle {
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  line-height: 20px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}

.regularText {
  display: contents;
  font-weight: $font-weight-normal;
  color: $color-neutrals-700-dark-grey;
}

.boldText {
  font-weight: $font-weight-semi-bold;
}

.description {
  font-size: $font-size-small;
  color: $color-neutrals-700-dark-grey;
  line-height: 24px;
  margin-top: grid-gap(0.25);
  margin-bottom: grid-gap(0.75);
}

.infoLink {
  &Tooltip {
    text-transform: none;

    section {
      [class*="title"] {
        font-size: $font-size-large;
        font-weight: $font-weight-normal;
        color: $color-neutrals-100-white;
        line-height: 24px;
      }
    }

    [class*="content"] {
      font-size: $font-size-normal;
      color: $color-neutrals-400-cold-grey;
      line-height: 20px;
    }

    aside {
      .infoLinkTooltipIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.loader {
  margin-bottom: grid-gap(0.75);

  &Left {
    margin-bottom: grid-gap(0.75);
    margin-right: grid-gap(0.5);
  }
}
