@use "sass:math"; @import "variables.scss";
.wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonIcon g {
  stroke: $color-neutrals-100-white;
}

.button.reviewButton.mobile {
  span[class^="icon__"] {
    margin-left: 0;
  }
}

.leftWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advertiser {
  margin: 0 grid-gap(0.5);
}

@include media(">=screen-xs") {
  .advertiser {
    margin: 0 grid-gap(1);
  }
}

.notification {
  width: 856px;
}
