@use "sass:math"; @import "variables.scss";
.listTitle {
  font-weight: $font-weight-semi-bold;
  text-indent: each-line;
}

.list {
  list-style: disc;
  margin-left: grid-gap(1.5);
}
