@use "sass:math"; @import "variables.scss";
.container {
  width: calc(100% - 48px);
  overflow: auto;
  box-sizing: border-box;
  padding-top: grid-gap(1.5);
}

.counterClassName {
  min-width: grid-gap(2);
  line-height: grid-gap(2);
}

.titleClassName {
  font-size: 24px;
  line-height: 32px;
}

.topSpace {
  margin-top: grid-gap(1);
}

.bottomSpace {
  padding-bottom: grid-gap(2.5);
}

.field {
  padding-bottom: 0;

  &:last-of-type {
    padding-bottom: grid-gap(1.5);
  }
}

.subtitle {
  @include source-sans-pro("semi-bold");

  line-height: 24px;
  margin-bottom: grid-gap(0.5);
  color: $color-neutrals-800-dark;
}

.content {
  line-height: 24px;
  margin: 0;
  color: $color-neutrals-800-dark;

  &.topSpace {
    margin-top: grid-gap(0.5);
  }
}

.extraSpace {
  margin-bottom: grid-gap(0.5);
}

.list {
  color: $color-neutrals-800-dark;
  list-style: disc;
  padding-left: 16px;
  line-height: 24px;
}

.link {
  display: inline-block;

  &::after {
    content: none;
  }
}

@include media(">=screen-sm") {
  .wrapper {
    padding: 0 grid-gap(1.5);
  }

  .container {
    background-color: $color-brand-50-blue;
    display: flex;
    box-shadow: inset 0 1px 4px 1px rgba($color-neutrals-black, 0.1);
    border-radius: 6px;
    padding: grid-gap(1.5);
  }
}
