@use "sass:math"; @import "variables.scss";
.actionsBox {
  position: sticky;
  bottom: 0;
  background-color: $color-white;
  border-top: solid 1px $color-neutrals-400-cold-grey;
  padding-top: grid-gap(1.5);
  padding-bottom: grid-gap(1.5);
  z-index: 2;
}

.topBox {
  margin-bottom: grid-gap(1);
}

.bottomBox {
  display: flex;
  justify-content: space-between;
  gap: grid-gap(1);

  > div {
    width: 100%;
  }
}

.actionBtn {
  width: 100%;

  [class^="spinner___"] {
    width: 5em;
    height: 5em;
  }

  &InfoLink {
    div[class*="linkContentWrapper"] {
      width: 100%;

      & > div {
        width: 100%;
      }
    }
  }
}

.btn {
  width: 100%;
  display: block;
}

.iconBtn {
  margin: 0 grid-gap(0.5) 0 0;

  g,
  path {
    stroke: $color-brand-accent-blue;
  }
}

.iconBtnDisabled {
  g,
  path {
    stroke: $color-neutrals-600-medium-grey;
  }
}

.notificationTooltip {
  min-width: 296px;
  box-sizing: border-box;
  top: -(grid-gap(0.5));

  &.shareReportNotificationTooltip {
    margin-left: 0;

    .notificationTooltipIcon {
      width: 23px;
      height: 23px;

      &.customDimensions {
        width: 23px;
        height: 23px;
      }
    }
  }
}
