@use "sass:math"; @import "variables.scss";
.sections {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(">=screen-xl") {
    display: grid;
    align-items: stretch;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.sectionBox {
  max-width: 490px;
  width: 100%;

  @include media(">=screen-xl") {
    max-width: unset;
  }
}
