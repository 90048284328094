/* stylelint-disable */

@font-face {
  font-family: "F Grotesk Demi";
  src: local("F Grotesk Demi"),
    url("https://s3-eu-west-1.amazonaws.com/static.mpt-adsol.schibsted.io/rose-client-app/fonts/f_grotesk_demi.otf")
    format("opentype");
  font-weight: $font-weight-semi-bold;
  font-style: normal;
}

@font-face {
  font-family: "F Grotesk";
  src: local("F Grotesk"),
    url("https://s3-eu-west-1.amazonaws.com/static.mpt-adsol.schibsted.io/rose-client-app/fonts/f_grotesk_bold.otf")
    format("opentype");
  font-weight: $font-weight-bold;
  font-style: normal;
}
/* stylelint-enable */
