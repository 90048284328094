@use "sass:math"; @import "variables.scss";
.header {
  @include z-index(header);

  height: $header-height;
  background: $color-neutrals-100-white;
  position: relative;

  &.sticky {
    @include z-index(header);

    position: sticky;
    top: 0;
  }
}

.headerContent {
  @extend %ultraLargeWrapper;

  display: flex;
  justify-content: space-between;
  align-items: center;

  // background overlay with header size
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-neutrals-100-white;
    border-bottom: 1px solid $color-neutrals-300-warm-grey;
  }
}
