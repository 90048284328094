@use "sass:math"; @import "variables.scss";
.loadMoreBox {
  background-color: $color-white;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top: dashed 1px $color-neutrals-300-warm-grey;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 0;
}

.button {
  min-width: 167px;
}
