@use "sass:math"; @import "variables.scss";
.loaderDesktop {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  grid-template-areas:
    "aside content"
    "aside content";
  grid-auto-columns: 1.3fr 2.3fr;
  padding: 0;
  grid-gap: 0;

  rect {
    width: 100%;
  }
}

.aside {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.5);
  grid-area: aside;
  background-color: $color-brand-50-blue;
  padding: grid-gap(5) grid-gap(2.5) grid-gap(2);

  svg:nth-child(2) {
    margin-bottom: grid-gap(1.5);
  }

  svg:nth-child(4) {
    margin-bottom: grid-gap(5);
  }

  svg:nth-child(6) {
    margin-bottom: grid-gap(1.75);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.75);
  grid-area: content;
  padding: grid-gap(1.5);

  &Group {
    display: flex;
    flex-direction: row;
    gap: grid-gap(1);
  }

  &TitleBlock {
    margin-bottom: grid-gap(0.75);
  }

  &CheckboxBlock {
    margin-right: grid-gap(0.75);
  }
}
