@use "sass:math"; @import "variables.scss";
.excessIndicatorIcon {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-weight: $font-weight-semi-bold;
  background-color: $color-brand-100-blue;
  color: $color-brand-accent-blue;
}

.tooltipTargeting {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.5);
  margin-bottom: grid-gap(1);

  &Title {
    font-size: $font-size-large;
  }

  &Value {
    color: $color-neutrals-400-cold-grey;
  }
}
