@use "sass:math"; @import "variables.scss";
.footer {
  height: 79px;
  position: sticky;
  bottom: 0;
  border-top: 1px solid $color-neutrals-400-cold-grey;
  background-color: $color-neutrals-100-white;
}

.wrapper {
  height: 100%;

  @extend %ultraLargeWrapper;
}
