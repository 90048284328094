@use "sass:math"; @import "variables.scss";
.quickActionButton {
  flex-shrink: 0;
  margin: 0 grid-gap(0.5) 0 0;

  .itemsCountIcon {
    display: flex;
    align-items: center;
    padding-left: grid-gap(0.125);
    margin-right: grid-gap(-0.125);
  }

  svg.reportIcon {
    stroke: $color-brand-accent-blue;
    fill: none;

    &.disabled {
      stroke: $color-neutrals-500-grey;
    }
  }

  &.disabled {
    border-color: $color-neutrals-400-cold-grey;
    background: $color-neutrals-400-cold-grey;

    path {
      stroke: $color-neutrals-500-grey;
    }

    div[class^="containedAlt__"] {
      visibility: hidden;
    }

    &:hover {
      background: $color-neutrals-400-cold-grey;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.buttonContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
}
