@use "sass:math"; @import "variables.scss";
.sidebarItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.sidebarItemInner {
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;

  .text {
    height: 100%;
    box-sizing: border-box;
    transform: rotate(-180deg);
    position: relative;
    text-align: center;

    span {
      @include source-sans-pro("semi-bold");

      font-size: $font-size-large;
      color: $color-neutrals-700-dark-grey;
      text-transform: uppercase;
      font-weight: $font-weight-semi-bold;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      height: 90%;
      bottom: -5%;
    }
  }
}

.spacingLine {
  height: 2px;
  border-bottom: dashed 1px $color-neutrals-300-warm-grey;
  width: 1704px;
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  pointer-events: none;
}
