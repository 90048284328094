@use "sass:math"; @import "variables.scss";
.icon {
  width: 16px;
  height: 16px;
}

.toast {
  width: 100%;
}

.notification {
  margin-top: calc($header-height + grid-gap(0.5));
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toastNotification {
  width: 400px;
  box-sizing: border-box;
}
