$area-header: head;
$area-content: main;
$area-footer: foot;

$columns-number: 12;

$grid-gap: 16px;

$min-content-width: 320px;
$max-content-width: 1168px;
$max-content-width-large: 1440px;
$max-content-width-ultra-large: 1704px;

$screen-xs: 580px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1200px;
$screen-xl: 1440px;
$screen-xxl: 1704px;
$screen-ul: 1800px;

$breakpoints: (
  "screen-xs": $screen-xs,
  "screen-sm": $screen-sm,
  "screen-md": $screen-md,
  "screen-lg": $screen-lg,
  "screen-xl": $screen-xl,
  "screen-xxl": $screen-xxl,
  "screen-ul": $screen-ul,
);

$z-index: (
  booking-track-selector: 0,
  table-header-cell: 10,
  table-sticky-body-cell: 20,
  table-sticky-header-cell: 30,
  booking-field: 1001,
  booking-copy-overlay: 1050,
  booking-copy-notification: 1025,
  booking-stepper: 1100,
  select-in-modal: 1001,
  toast: 1110,
  header: 1200,
  drawer: 9999,
  drawer-overlay: 9998,
  timeline-campaign: 9999,
  notification: 10000
);

$header-height: 72px;

@function grid-gap($exponent) {
  @return ($grid-gap * $exponent);
}

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

%wrapper {
  margin: 0 grid-gap(1);
  max-width: $max-content-width;

  @include media(">=screen-sm") {
    margin: 0 grid-gap(2.5);
  }

  @include media(">=screen-lg") {
    margin: 0 auto;
  }
}

%ultraLargeWrapper {
  margin: 0 grid-gap(1);
  max-width: $max-content-width-ultra-large;

  @include media(">=screen-sm") {
    margin: 0 grid-gap(2.5);
  }

  @include media(">=screen-lg") {
    margin: 0 grid-gap(3);
  }

  @include media(">=screen-ul") {
    margin: 0 auto;
  }
}

%baseGrid {
  @extend %wrapper;

  display: grid;
  grid-gap: grid-gap(0.5);

  @include media(">=screen-xs") {
    grid-gap: grid-gap(1);
  }

  @include media(">=screen-sm") {
    grid-gap: grid-gap(1);
  }

  @include media(">=screen-md") {
    grid-gap: grid-gap(1.5);
  }

  @include media(">=screen-lg") {
    grid-gap: grid-gap(2);
  }
}

%baseUltraLargeGrid {
  @extend %ultraLargeWrapper;

  display: grid;
  grid-gap: grid-gap(0.5);

  @include media(">=screen-xs") {
    grid-gap: grid-gap(1);
  }

  @include media(">=screen-sm") {
    grid-gap: grid-gap(1);
  }

  @include media(">=screen-md") {
    grid-gap: grid-gap(1.5);
  }

  @include media(">=screen-lg") {
    grid-gap: grid-gap(2);
  }
}

%mainGrid {
  @extend %baseGrid;

  grid-template-columns: repeat(4, 1fr);

  @include media(">=screen-xs") {
    grid-template-columns: repeat(8, 1fr);
  }

  @include media(">=screen-md") {
    grid-template-columns: repeat(12, 1fr);
  }
}

%mainUltraLargeGrid {
  @extend %baseUltraLargeGrid;

  grid-template-columns: repeat(4, 1fr);

  @include media(">=screen-xs") {
    grid-template-columns: repeat(8, 1fr);
  }

  @include media(">=screen-md") {
    grid-template-columns: repeat(12, 1fr);
  }
}

@mixin spinnerColor($darker, $lighter) {
  background: $darker;
  background: linear-gradient(
    to right,
    $darker 10%,
    rgba(255, 255, 255, 0) 42%
  );

  &::before {
    background: $darker;
  }

  &::after {
    background: $lighter;
  }
}
