@use "sass:math"; @import "variables.scss";
.imageUploadInputWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-neutrals-400-cold-grey;
  border-radius: 6px;
}

.imageUploadInputError {
  border: 1px solid $color-warning-500;
}

.imageUploadInput {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.imageErrorNotification.override {
  box-sizing: border-box;
  width: calc(100% - grid-gap(1)) !important;
  margin: grid-gap(0.5) auto;
}

.imageInfoBoxWrapper {
  box-sizing: border-box;
  padding: grid-gap(0.75) 0 grid-gap(0.75) grid-gap(0.75);
}
