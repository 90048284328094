@use "sass:math"; @import "variables.scss";
.container {
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
  color: $color-neutrals-800-dark;
  margin-bottom: grid-gap(0.75);
}

.title {
  font-size: $font-size-small;
  color: $color-neutrals-700-dark-grey;
  margin-bottom: grid-gap(0.25);
  line-height: 24px;
  text-transform: uppercase;
}
