@use "sass:math"; @import "variables.scss";
.headerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: grid-gap(1.5);

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.closeButtonWrapper {
  display: flex;
  flex-direction: column;

  .closeButton {
    display: flex;
    cursor: pointer;
    position: relative;
    margin-left: grid-gap(1.5);

    &::after {
      position: absolute;
      left: grid-gap(-0.25);
      top: grid-gap(-0.25);
      padding: grid-gap(0.25);
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
      border-radius: 4px;
    }

    &:focus {
      outline: none;

      &::after {
        content: "";
      }
    }

    &:hover::after {
      content: "";
    }

    .icon {
      fill: $color-neutrals-700-dark-grey;
      width: 12px;
      height: 12px;
    }
  }
}
