@use "sass:math"; @import "variables.scss";
.cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 340px;
  position: relative;
  padding-left: 0;

  @include media(">=screen-sm") {
    padding-left: grid-gap(1);
  }

  @include media(">=screen-lg") {
    padding-left: grid-gap(1.5);
  }

  @include media(">=screen-xxl") {
    padding-left: 0;
  }

  .checkbox {
    margin-right: grid-gap(1);
  }

  .content {
    margin-right: grid-gap(0.75);
    max-width: calc(100% - 92px);
    max-height: 52px;

    .topContent {
      font-size: $font-size-small;
      line-height: 14px;
      color: $color-neutrals-700-dark-grey;
      margin-bottom: grid-gap(0.3125);
    }
  }

  .id {
    @include source-sans-pro("regular");

    margin-bottom: 2px;
  }

  .flag {
    border: 1px solid $color-neutrals-400-cold-grey;
    border-radius: grid-gap(6.25);
    margin-left: grid-gap(0.25);
    padding: 0 grid-gap(0.5);
  }
}
