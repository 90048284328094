@use "sass:math"; @import "variables.scss";
.actionButtons {
  display: flex;
  gap: 8px;

  .infoButton,
  .sentEmailButton {
    height: 40px;

    > div {
      padding: 0 12.5px !important;
    }

    span {
      font-weight: $font-weight-semi-bold;
    }

    svg g,
    path {
      stroke: $color-brand-accent-blue;
    }
  }

  .infoButton span[class^="buttonContent"] span[class^="icon"] svg,
  .sentEmailButton span[class^="buttonContent"] span[class^="icon"] svg {
    width: 15px;
    height: 15px;
  }

  .infoButton span[class^="buttonContent"] span[class^="icon"],
  .sentEmailButton span[class^="buttonContent"] span[class^="icon"] {
    margin-right: 0;
  }

  .inviteButtonText {
    display: none;
    white-space: nowrap;
  }

  @include media(">=screen-md") {
    .inviteButtonText {
      display: block;
    }

    .sentEmailButton span[class^="buttonContent"] span[class^="icon"] {
      margin-right: 8px;
    }
  }
}
