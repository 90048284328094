@use "sass:math"; @import "variables.scss";
.container {
  background-color: $color-brand-100-blue;
  border-radius: 6px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    "timeSection"
    "weekDaySection"
    "buttonsSection";

  @include media(">=screen-sm") {
    grid-template-areas:
      "weekDaySection timeSection"
      "weekDaySection timeSection"
      "buttonsSection buttonsSection";
  }
}

.section {
  padding: grid-gap(1) 0;
  margin: 0 grid-gap(1);

  @include media(">=screen-sm") {
    padding: 0;
    margin: 0;
  }
}

.weekDaysSection {
  border-bottom: 1px solid $color-cold-grey;
  grid-area: weekDaySection;

  @include media(">=screen-sm") {
    border-bottom: none;
    padding: 0 grid-gap(1.5) 0;
    margin: grid-gap(1.5) 0;
  }
}

.timeSection {
  border-bottom: 1px solid $color-cold-grey;
  grid-area: timeSection;

  @include media(">=screen-sm") {
    border-left: 1px solid $color-cold-grey;
    padding: 0 grid-gap(1.5);
    margin: grid-gap(1.5) 0;
    border-bottom: none;
  }
}

.buttonsSection {
  grid-area: buttonsSection;

  @include media(">=screen-sm") {
    border-top: 1px solid $color-cold-grey;
    padding: grid-gap(1.5) 0;
    margin: 0 grid-gap(1.5);
  }

  .submitButton {
    margin: 0 grid-gap(0.5) 0 0;
  }
}

.timeFieldContainer {
  margin: 0 0 grid-gap(1) 0;
}

.boldText {
  font-weight: $font-weight-bold;
}

.articleLink.custom {
  color: $color-brand-500-secondary-blue;
  text-decoration: underline;

  &::after,
  &::before {
    content: none;
  }
}
