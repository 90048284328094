@use "sass:math"; @import "variables.scss";
.calendar {
  display: grid;
  grid-template-rows: repeat(7, 88px [row1-start]);
  min-height: 440px; // 5 * 88

  @include media(">=screen-xl") {
    min-height: 528px; // 6 * 88
  }

  @include media(">=screen-xxl") {
    min-height: 616px; // 7 * 88
  }

  &::after {
    display: block;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    position: absolute;
    background: linear-gradient(270deg, $color-white 0, rgba($color-white, 0) 100%);
    z-index: 101;
  }

  &:not(.withAdditionalInfo) {
    position: relative;
  }
}

.infoBox {
  position: absolute;
  transform: translate(-50%, -45%);
  top: 50%;
  left: 50%;
  text-align: center;
}
