@use "sass:math"; @import "variables.scss";
.textContainer {
  padding: grid-gap(0.75);

  .title {
    font-size: $font-size-large;
    color: $color-neutrals-800-dark;
    font-weight: 600;
    margin-bottom: grid-gap(0.5);
  }

  .advertiser {
    font-family: Arial, sans-serif;
    font-size: $font-size-extra-small;
    font-weight: 700;
    color: $color-neutrals-600-medium-grey;
    text-transform: uppercase;
  }
}
