@use "sass:math"; @import "variables.scss";
.offersBox {
  box-sizing: border-box;
  padding: grid-gap(2) grid-gap(1) grid-gap(1.5) grid-gap(1);
  background: $color-neutrals-100-white;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100vw;

  @include media(">=screen-sm") {
    padding: grid-gap(2) 40px grid-gap(1.5) 40px;
  }

  @include media(">=screen-lg") {
    padding: grid-gap(2) grid-gap(3) grid-gap(1.5) grid-gap(3);
  }

  @include media(">=screen-ul") {
    max-width: $screen-xxl;
    padding-left: 0;
    padding-right: 0;
  }

  .titleSection {
    display: flex;
    align-items: center;
    margin-bottom: grid-gap(0.5);

    .titleText {
      font-size: $font-size-extra-large;
      line-height: 24px;
      font-weight: $font-weight-semi-bold;
      color: $color-neutrals-700-dark-grey;
      margin-right: grid-gap(0.5);
    }

    .campaignsNumber {
      width: 24px;
      height: 24px;
      background: $color-warning-400-red;
      border-radius: 24px;
      color: $color-neutrals-100-white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .descriptionSection {
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    line-height: 150%;
    color: $color-neutrals-700-dark-grey;
    margin-bottom: grid-gap(0.75);
  }

  .campaignsContents {
    display: flex;
    width: auto;

    div[class^="carouselContainer"] {
      margin: 0 grid-gap(-0.25);
      width: calc(100% + 0.5 * $grid-gap);
    }

    @include media(">=screen-md") {
      .twoSlides div[class*="slick-active"] {
        max-width: calc((100vw - 5.5 * $grid-gap) / 2);
      }

      div[class*="slick-active"] + div[class*="slick-active"] {
        margin-left: grid-gap(1);
      }
    }

    @include media(">=screen-lg") {
      .twoSlides div[class*="slick-active"] {
        max-width: calc((100vw - 6.5 * $grid-gap) / 2);
      }
    }

    @include media(">=screen-xxl") {
      .twoSlides div[class*="slick-active"] {
        max-width: calc(($screen-xxl - 0.5 * $grid-gap) / 2);
      }
    }

    .customCarousel {
      width: 100%;
      padding: 0;
      margin: 0;

      div[class^="slick-track"] {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        div[class^="slick-slide"] {
          position: relative;
          z-index: 10;
        }
      }

      div[class*="slick-next"] {
        right: -8px;
      }

      div[class*="slick-prev"] {
        left: -8px;
        transform: translate(0, -50%) rotate(-180deg);
      }

      div[class^="slick-arrow"] {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
        height: 24px;
        width: 24px;
        background: $color-neutrals-100-white;
        border-radius: 24px;
      }

      div[class*="slick-disabled"] {
        visibility: hidden;
      }

      .arrow {
        height: 20px;
        width: 11px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          fill: $color-brand-600-primary-blue;
        }
      }
    }
  }
}
