@use "sass:math"; @import "variables.scss";
.discountInfo {
  display: flex;
  align-items: center;
  gap: grid-gap(0.5);

  svg {
    height: 15px;
    width: 15px;

    g {
      stroke: $color-neutrals-700-dark-grey;
    }
  }
}

.tooltipDescription {
  white-space: break-spaces;
  width: 270px;
}
