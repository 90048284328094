@use "sass:math"; @import "variables.scss";
.description {
  p {
    margin: grid-gap(0.5) 0;
  }
}

.icon {
  fill: $color-neutrals-700-grey;
  margin-right: grid-gap(0.5);
  width: 16px;
  height: 16px;
}
