@use "sass:math"; @import "variables.scss";
.cell {
  @include source-sans-pro("semi-bold");

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: $font-size-normal;
  color: $color-neutrals-800-dark;
  line-height: 16px;

  &.left {
    justify-content: left;
  }

  &.right {
    justify-content: right;
  }

  &.center {
    justify-content: center;
  }
}
