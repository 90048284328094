@use "sass:math"; @import "variables.scss";
.table {
  max-width: $max-content-width;
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
}

.tableHeaderRow {
  border-top: 1px solid $color-brand-100-blue;

  @extend %mainGrid;
}

.tableBodyRow {
  @extend %mainGrid;

  margin-bottom: grid-gap(0.5);
  border-radius: 6px;
  position: relative;
}

.rowLoader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color-white, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodySubRow {
  margin-top: grid-gap(-0.5);
  margin-bottom: grid-gap(0.5);
  border-radius: 0 0 4px 4px;
}

.tableHeaderCell {
  @include source-sans-pro("regular");

  display: flex;
  text-transform: uppercase;
  font-size: $font-size-small;
  line-height: 14px;
  color: $color-neutrals-700-dark-grey;
  text-align: left;
  padding: grid-gap(1) grid-gap(0.25);
  align-items: center;
  white-space: nowrap;

  .withSort {
    position: relative;
    display: inline-flex;
    padding-right: grid-gap(0.75);
    cursor: pointer;
  }
}

.tableBodyCell {
  @include source-sans-pro("regular");

  display: flex;
  padding: grid-gap(1) grid-gap(0.25);
  align-items: center;
}

.isExpanded {
  td:first-child {
    border-left: 1px solid transparent !important;
  }
}

.headerLabel {
  position: relative;
}

@include media(">=screen-xs") {
  .tableHeaderRow,
  .tableBodyRow {
    grid-gap: grid-gap(0.5);
  }

  .table {
    td.isSticky {
      @include z-index(table-sticky-body-cell);
    }

    th.isSticky {
      @include z-index(table-sticky-header-cell);
    }

    .isSticky {
      position: sticky;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0;
        right: -6px;
        background: linear-gradient(
          to right,
          rgba(100, 100, 100, 0.1) 0%,
          rgba(100, 100, 100, 0) 100%
        );
        opacity: 0;
        transition: opacity 0.2s;
      }
    }

    &.hasBeenScrolled {
      .isSticky {
        &::after {
          opacity: 1;
          transition: opacity 0.2s;
        }
      }
    }
  }
}

@include media(">=screen-sm") {
  .tableHeaderRow,
  .tableBodyRow {
    grid-gap: grid-gap(0.5);
  }
}

@include media(">=screen-md") {
  .tableHeaderRow,
  .tableBodyRow {
    grid-gap: grid-gap(1);
  }
}

@include media(">=screen-lg") {
  .tableHeaderRow,
  .tableBodyRow {
    grid-gap: grid-gap(1);
  }
}
