@use "sass:math"; @import "variables.scss";
.file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: grid-gap(4) grid-gap(2);

  .zipIcon {
    margin-bottom: grid-gap(1);
    stroke: $color-neutrals-black;
  }

  .title {
    @include source-sans-pro("semi-bold");

    color: $color-neutrals-black;
    font-size: $font-size-large;
    line-height: 24px;
  }

  .size {
    @include source-sans-pro();

    color: $color-neutrals-black;
    font-size: $font-size-normal;
    line-height: 20px;
    margin-bottom: grid-gap(1);
  }

  .button.withIcon {
    height: 32px;
    line-height: 32px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: grid-gap(0.5);
      margin-bottom: grid-gap(-0.125);
    }
  }
}

.empty {
  width: 100%;
  height: 100%;
  background-color: $color-neutrals-200-light-grey;
  border-radius: 4px;
  border: 1px dashed $color-neutrals-400-cold-grey;
  min-height: 192px;
  padding: 0 grid-gap(1);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;

  p {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-large;
    line-height: 24px;
    color: $color-neutrals-600-medium-grey;
  }
}
