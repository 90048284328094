@use "sass:math"; @import "variables.scss";
@mixin rightSeparator {
  position: relative;
  margin-right: grid-gap(1);
  overflow: visible;

  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 40px;
    background-color: $color-neutrals-300-warm-grey;
    position: absolute;
    right: -16px;
    bottom: 0;
  }
}

@mixin leftSeparator {
  position: relative;
  margin-left: grid-gap(1);
  overflow: visible;

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 40px;
    background-color: $color-neutrals-300-warm-grey;
    position: absolute;
    left: -16px;
    bottom: 0;
  }
}

.filtersForm {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: grid-gap(1);
}

.firstBox {
  display: flex;
  align-items: flex-end;
  gap: grid-gap(1);
  flex-direction: column;
  width: 100%;
}

.firstBoxItem {
  width: 100%;
}

.secondBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: grid-gap(1);
}

.search {
  width: 100%;
}

.moreFiltersButton,
.preferencesButton {
  &.button.ripple {
    min-width: 40px;

    [class^="ripple"] {
      padding: 0 grid-gap(0.75);
    }
  }
}

.moreFiltersButtonTitle {
  display: inline-block;
  white-space: nowrap;
  font-weight: $font-weight-semi-bold;
  margin-left: grid-gap(0.5);
}

.preferencesButton {
  display: none;
}

@include media(">=screen-xs") {
  .moreFiltersButtonTitle {
    display: none;
  }

  .search {
    @include rightSeparator;
  }

  .secondBox {
    flex-direction: row;
    align-items: end;
  }

  .firstBox {
    flex-direction: row;
  }
}

@include media(">=screen-sm") {
  .firstBoxItem.singleAdvertiserMode {
    width: 100%;
  }

  .preferencesButton {
    display: inline-block;
  }
}

@include media(">=screen-md") {
  .preferencesButton.button.ripple {
    order: 3;
  }

  .moreFiltersButton.button.ripple {
    order: 1;
  }

  .search {
    @include leftSeparator;

    order: 2;
  }

  .firstBox {
    width: 60%;

    &.isSingleAdvertiserMode {
      width: 30%;
    }
  }

  .firstBoxItem {
    width: 50%;
  }

  .secondBox {
    width: 60%;

    &.isSingleAdvertiserMode {
      width: 70%;
    }
  }

  .filtersForm {
    flex-wrap: initial;
  }
}

@include media(">=screen-xl") {
  .moreFiltersButtonTitle {
    display: inline-block;
  }

  .preferencesButton.button.ripple {
    min-width: 156px;
  }

  .moreFiltersButton.button.ripple {
    min-width: 110px;
  }

  .moreFiltersButton,
  .preferencesButton {
    &.button.ripple {
      [class^="ripple"] {
        padding: 0 grid-gap(1.5);
      }
    }
  }
}

.icon {
  min-width: 20px;
  min-height: 20px;
}
