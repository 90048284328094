@use "sass:math"; @import "variables.scss";
.timeAxisItem {
  grid-row: third-line / 1;
  position: relative;

  &.isCurrentWeek {
    &::after {
      display: block;
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px;
      background-color: $color-neutrals-700-dark-grey;
    }
  }
}

.columnCurrentWeek {
  display: block;
  content: "";
  position: absolute;
  top: 49px;
  bottom: 0;
  width: 1px;
  border-left: 2px dashed $color-neutrals-600-medium-grey;
  left: 0;
  transform: translateX(-50%);
  min-height: 780px;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5.5px 0;
    border-color: $color-neutrals-600-medium-grey transparent transparent transparent;
    transform: translateX(-60%);
  }
}

.columnHeader {
  padding: 0 0 grid-gap(1);
  text-align: center;
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-large;
  position: sticky;
  top: 0;
  background-color: $color-white;
  z-index: 1;
}

.monthBox {
  height: 25px;
  margin: 0 0 grid-gap(0.75);

  .text {
    color: $color-neutrals-700-dark-grey;
    font-weight: $font-weight-semi-bold;
    line-height: 24px;
    text-align: center;
    grid-row: 1;
  }
}
