@use "sass:math"; @import "variables.scss";
.copyCampaignOverlay {
  z-index: 100000;
  position: fixed;
  background-color: $color-white;
  width: 100%;
  height: calc(100% - 79px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progressBoxTitle {
  font-size: $font-size-extra-large;
  font-weight: $font-weight-semi-bold;
  color: $color-neutrals-700-dark-grey;
}

.progressBox {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: grid-gap(1.5);
}
