@use "sass:math"; @import "variables.scss";
.defaultPreview {
  min-height: 192px;
  background-color: $color-neutrals-200-light-grey;
  border-radius: 4px;
  border: 1px dashed $color-neutrals-400-cold-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .icon g {
    stroke: $color-neutrals-500-grey;
  }
}

.defaultImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: $color-neutrals-200-light-grey;
  color: $color-neutrals-800-dark;
  min-height: 170px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  width: 100%;

  @include media(">=screen-xs") {
    min-height: 240px;
    padding: grid-gap(1.5) grid-gap(1.5);
  }

  @include media(">=screen-sm") {
    min-height: 345px;
    padding: grid-gap(2) grid-gap(2.5);
  }

  @include media(">=screen-md") {
    min-height: 345px;
    padding: grid-gap(3) grid-gap(4.5);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
  }

  &.withoutCreatives {
    min-height: 170px;
    cursor: default;

    @include media(">=screen-xs") {
      min-height: 400px;
    }

    &::after {
      content: none;
    }
  }

  h2 {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-large;
    line-height: 24px;
    margin-bottom: grid-gap(0.25);
  }

  p {
    text-align: center;
    font-size: $font-size-normal;
    line-height: $font-size-large;
    margin-bottom: grid-gap(0.75);
    display: none;

    @include media(">=screen-xs") {
      display: block;
    }
  }

  .icon {
    margin-bottom: grid-gap(1.25);
  }

  .description {
    line-height: 24px;
    font-size: $font-size-large;
    color: $color-neutrals-700-grey;
  }

  &:hover {
    .expandIcon,
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.thumbnailDefaultImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: $color-neutrals-200-light-grey;
  min-height: 64px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0;

  .icon {
    margin-bottom: 0;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }

  &:focus-within {
    box-shadow: 0 0 0 1px $color-brand-600-primary-blue;
  }
}
