@use "sass:math"; @import "variables.scss";
.creative {
  border-radius: 4px;
  border: 1px dashed $color-neutrals-400-cold-grey;
  padding: grid-gap(0.25);
  background: $color-neutrals-200-light-grey;
  max-width: 320px;
  margin: auto;
}

.link {
  display: block;
}

.link:nth-child(1) {
  margin-bottom: grid-gap(2);

  &.withoutLogo {
    margin-bottom: 0;
  }

  [class^="imageContainer__"] {
    height: 124px;
  }
}

.link:nth-child(2) {
  .content {
    display: flex;
    justify-content: space-between;

    [class^="textContainer__"] {
      padding: 10px;
    }

    [class^="imageContainer__"] {
      flex: 0 0 90px;
      height: 68px;
    }
  }
}
