@use "sass:math"; @import "variables.scss";
.modalContent {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1);
}

.fieldLabel {
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
}

.notification {
  width: 400px;
  box-sizing: border-box;
}
