@use "sass:math"; @import "variables.scss";
.inputSection {
  height: 40px;
  display: flex;

  .selectField {
    flex: 1;
    margin-right: grid-gap(0.5);
  }
}

.advertiserFormWrapper {
  margin-bottom: grid-gap(2);
}

.addButton {
  &.disabled {
    [class^="plusIcon__"] {
      path {
        stroke: $color-neutrals-600-medium-grey;
      }
    }
  }
}

.searchInput {
  [class^="header__"] {
    height: 40px;
  }

  [class^="showIcon__"] {
    fill: $color-neutrals-700-dark-grey;
  }
}

.inputDropdown {
  z-index: 9999;

  [class^="scrollableBox__"] {
    max-height: 200px !important;
  }
}

.plusIcon {
  path {
    stroke: $color-neutrals-100-white;
  }
}
