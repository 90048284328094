@use "sass:math"; @import "variables.scss";
.section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
  justify-content: center;

  &:not(:first-child) {
    padding-top: grid-gap(3);
  }

  .listSide {
    justify-self: center;

    p,
    span {
      @include source-sans-pro("regular");

      font-size: $font-size-large;
      line-height: 24px;
      color: $color-neutrals-700-dark-grey;
    }

    .listHeader {
      h2 {
        @include f-grotesk("demi");

        font-size: $font-size-extra-large;
        color: $color-neutrals-800-dark;
        line-height: 24px;
      }

      &.listHeaderBig {
        h2 {
          @include f-grotesk("demi");

          font-size: $font-size-extra-huge;
          line-height: 32px;

          @include media(">=screen-xs") {
            @include f-grotesk("bold");

            font-size: 40px;
            line-height: 48px;
          }
        }
      }

      p {
        margin-top: grid-gap(0.5);
      }
    }

    .listItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        min-width: grid-gap(2.5);
        min-height: grid-gap(2.5);
        margin-top: grid-gap(2);
      }

      span {
        margin-top: grid-gap(0.75);
      }
    }
  }

  .imageSide {
    grid-row: 1;
    margin-bottom: grid-gap(2);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: grid-gap(12.5);

    img {
      max-width: 100%;
    }
  }

  @include media(">=screen-xs") {
    max-width: grid-gap(30.125);
    margin: auto;

    &:not(:first-child) {
      padding-top: grid-gap(4);
    }

    .imageSide {
      margin-bottom: grid-gap(2.5);
    }
  }

  @include media(">=screen-sm") {
    &:not(:first-child) {
      padding-top: grid-gap(5);
    }
  }

  @include media(">=screen-md") {
    grid-template-columns: repeat(2, 1fr);
    max-width: grid-gap(59);

    &:not(:first-child) {
      padding-top: grid-gap(6);
    }

    .imageSide {
      grid-row: initial;

      &:nth-child(odd) {
        margin-right: grid-gap(4.375);
        justify-content: start;
      }

      &:nth-child(even) {
        margin-left: grid-gap(4.375);
        justify-content: end;
      }
    }

    .listSide {
      text-align: left;

      .listItem {
        display: flex;
        flex-direction: row;
        margin-top: grid-gap(2.25);

        svg,
        span {
          margin: 0;
        }

        svg {
          margin-right: grid-gap(1);
        }
      }
    }
  }

  @include media(">=screen-xl") {
    max-width: grid-gap(64.625);

    &:not(:first-child) {
      padding-top: grid-gap(7);
    }
  }
}
