@use "sass:math"; @import "variables.scss";
.topDescription {
  margin-bottom: grid-gap(0.75);
}

.qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.changeSizeIcon {
  height: 15px;
  width: 15px;
  stroke: $color-neutrals-100-white;
  margin-right: 10px;
}

.expandButton {
  margin-top: grid-gap(1);
}
