@use "sass:math"; @import "variables.scss";
.emailWrapper {
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: grid-gap(1.5);
}

.emailContainer {
  background-color: $color-brand-50-blue;
  display: flex;
  justify-content: center;
  box-shadow: inset 0 1px 4px 1px rgba($color-neutrals-black, 0.1);
  border-radius: 6px;
}

.emailContent {
  width: 100%;
  max-width: 544px;
  padding: grid-gap(1.5);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  max-width: 180px;
  margin: 0 auto grid-gap(1.5);
}

.content {
  padding: grid-gap(4);
  background-color: $color-neutrals-100-white;
  display: flex;
  flex-direction: column;

  .header {
    @include f-grotesk("demi");

    font-size: 24px;
    line-height: 32px;
    margin-bottom: grid-gap(1.5);
  }

  .description {
    line-height: 24px;
    margin-bottom: grid-gap(1.5);
  }
}
