@use "sass:math"; @import "variables.scss";
.footer {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: grid-gap(1) grid-gap(1.5);
  background-color: $color-brand-50-blue;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2000;
}
