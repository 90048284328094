@use "sass:math"; @import "variables.scss";
.spinner {
  display: flex;
  margin-top: grid-gap(0.5);
  width: 24px;
  height: 24px;
  background: linear-gradient(
    to right,
    $color-neutrals-600-medium-grey 10%,
    $color-neutrals-400-cold-grey 42%
  );

  &::before {
    background: $color-neutrals-600-medium-grey;
  }

  &::after {
    background: $color-neutrals-400-cold-grey;
  }
}
