@use "sass:math"; @import "variables.scss";
.errorBox {
  @extend %ultraLargeWrapper;

  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: grid-gap(1);
}
