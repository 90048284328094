@use "sass:math"; @import "variables.scss";
.sidebar {
  position: sticky;
  width: 46px;
  float: left;
  left: 0;
  z-index: 3;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // 7 * 88
  min-height: 616px;

  &:not(&.singleAdvertiserSidebar) {
    box-shadow: 7px 0 12px -9px rgb(66 68 90 / 0.68);
  }

  &.singleAdvertiserSidebar {
    position: relative;

    &::after {
      display: block;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      width: 40px;
      position: absolute;
      background: linear-gradient(270deg, $color-white 0, rgba($color-white, 0) 100%);
      z-index: 101;
    }
  }

  &.unStickySidebarOnMedium {
    position: initial;

    @include media(">=screen-xl") {
      position: sticky;
    }
  }
}
