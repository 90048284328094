@use "sass:math"; @import "variables.scss";
.title {
  margin-bottom: grid-gap(1);
  font-size: $font-size-extra-large;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
}

.actionButtons {
  display: grid;
  grid-template-areas: "backButton backButton" "copyButton bookButton";

  @include media("<=screen-xs") {
    display: flex;
    flex-direction: column;
  }
}

.button {
  &:first-child {
    margin-bottom: grid-gap(1);
  }

  &:nth-child(2) {
    margin-right: grid-gap(1);

    @include media("<=screen-xs") {
      margin-right: 0;
    }
  }

  @include media("<=screen-xs") {
    margin: 0 0 grid-gap(1) 0;
    margin-right: 0;
  }
}

.backButton {
  grid-area: backButton;
}

.copyButton {
  grid-area: copyButton;
}

.bookButton {
  grid-area: bookButton;
}

.icon {
  margin-right: grid-gap(0.5);

  g,
  path {
    stroke: $color-brand-accent-blue;
    stroke-width: 2px;
  }
}
