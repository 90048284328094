@use "sass:math"; @import "variables.scss";
.bottomSpace {
  margin-bottom: grid-gap(1.5);
}

.advertiserInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 grid-gap(1.5) grid-gap(1.5) grid-gap(1.5);
  overflow: auto;
}

@include media(">=screen-sm") {
  .advertiserInfo {
    max-height: 540px;
  }
}

@include media(">=screen-xs") {
  .bottomSpace {
    margin-bottom: grid-gap(2);
  }

  .separator {
    margin-bottom: grid-gap(2);
  }
}
