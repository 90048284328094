@use "sass:math"; @import "variables.scss";
.list {
  background-color: $color-white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  position: absolute;
  top: 100%;
  z-index: 103;
  width: 100%;
  left: 0;
  transform: translate(0, 3px);
  max-height: 240px;
  overflow: auto;
  outline: none;
}

.emptyBox {
  text-align: center;
  line-height: 24px;
  padding: grid-gap(3.5) grid-gap(4);
}

.emptyMsg {
  @include source-sans-pro();

  font-size: $font-size-large;
  color: $color-neutrals-700-dark-grey;
  line-height: 24px;
}

.emptyIcon {
  fill: $color-neutrals-600-medium-grey;
  width: 24px;
  height: auto;
}
