@use "sass:math"; @import "variables.scss";
.content {
  @include source-sans-pro("semi-bold");

  color: $color-neutrals-800-medium-dark;
  font-size: $font-size-normal;
  display: flex;
  align-items: center;

  .icon {
    fill: $color-neutrals-700-grey;
    margin-right: grid-gap(0.5);
    width: 16px;
    height: 16px;
  }
}

.description {
  p {
    margin: grid-gap(0.5) 0;
  }
}
