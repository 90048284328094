@use "sass:math"; @import "variables.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0 0 grid-gap(2) 0;
}

.checkboxWrapper {
  display: flex;
  width: 100%;
  padding: grid-gap(1) 0 0 0;

  .checkbox {
    margin-left: auto;

    span[class^="checkboxLabel__"] {
      font-size: $font-size-normal;
      color: $color-neutrals-700-dark-grey;
    }
  }
}

.isHidden {
  visibility: hidden;
}

.imageFrame {
  position: relative;
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: center;
}

.cropTool {
  img {
    max-height: 30vh;
  }

  div[class^="ReactCrop__selection-addon"] {
    width: 100%;
    height: 100%;
    pointer-events: none;

    div[class^="overlay__"] {
      width: 100%;
      height: 100%;
    }
  }
}

.imagePlaceholder {
  position: absolute;
}
