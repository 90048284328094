@use "sass:math"; @import "variables.scss";
.navBtnWrapper {
  position: absolute;
  height: 47px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 102;

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.navIcon {
  width: 16px;
  height: 16px;
}

.navBtn {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  z-index: 3;
  border: none;
  background-color: $color-white;

  &:hover {
    background-color: $color-brand-100-blue;
  }

  &:focus {
    background: transparent;
    outline-color: rgba($color-brand-500-secondary-blue, 0.5);
  }

  &.prev {
    left: 8px;
  }

  &.next {
    right: 10px;
  }

  &.disabled {
    opacity: 0.4;

    &:hover {
      background-color: $color-white;
      cursor: initial;
    }
  }
}
