@use "sass:math"; @import "variables.scss";
.additionalInfoAboutImage {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.25);
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-small;
  line-height: 16px;

  &Item {
    display: flex;
    align-items: center;
    gap: grid-gap(0.25);

    &Icon {
      g {
        stroke: $color-neutrals-700-dark-grey;
      }
    }
  }
}
