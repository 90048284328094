@use "sass:math"; @import "variables.scss";
.previewInContextButton {
  background-color: transparent;

  &:hover {
    border-color: transparent;
  }

  div[class*="ripple"] {
    padding: 0 grid-gap(0.75) !important;
  }

  &Icon g {
    stroke: $color-brand-600-primary-blue;
  }

  span[class*="buttonContent"] {
    color: $color-brand-600-primary-blue;
  }
}

.modal {
  margin: 0;
  padding: grid-gap(1.5) grid-gap(1.5) grid-gap(1) grid-gap(1.5);
  max-width: 854px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-start;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: grid-gap(1);
}

.closeButton {
  width: 24px;
  height: 24px;
}

.closeIcon {
  path {
    fill: $color-brand-accent-blue;
  }
}

.title {
  font-size: $font-size-extra-large;
  line-height: 24px;
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
}

.bottomDescription {
  text-align: left;
  margin-top: grid-gap(0.5);
  font-size: $font-size-small;
  color: $color-neutrals-700-dark-grey;
  line-height: 20px;
}
