@use "sass:math"; @import "variables.scss";
.footer {
  background-color: $color-neutrals-200-light-grey;

  .footerBody {
    @extend %wrapper;

    padding: grid-gap(1.5) 0;
    margin-top: grid-gap(6);
    border-top: solid 1px $color-neutrals-300-warm-grey;
  }

  .links {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: grid-gap(1);
  }

  .linkWrapper {
    border-bottom: solid 1px $color-neutrals-300-warm-grey;

    @include media(">=screen-sm") {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:nth-last-child(1) {
        text-align: right;
      }
    }
  }

  .link {
    font-weight: $font-weight-semi-bold;
    color: $color-neutrals-800-dark;
    display: inline-block;
    margin: grid-gap(0.5) 0;

    &::after {
      content: none;
    }

    .linkIcon {
      margin-left: grid-gap(0.5);
    }

    &:hover {
      .linkIcon path {
        fill: $color-brand-accent-blue;
      }
    }
  }

  .copy {
    font-size: $font-size-normal;
    color: $color-neutrals-700-dark-grey;
  }

  .copyLink::after {
    content: none;
  }

  @include media(">=screen-sm") {
    .links {
      justify-content: space-between;
    }

    .linkWrapper {
      border: none;
    }

    .copy {
      text-align: center;
    }
  }

  &.landing {
    background-color: $color-brand-50-blue;

    .footerBody {
      border: none;
      padding-top: grid-gap(3);
      margin-top: 0;
    }

    .copy {
      text-align: center;
      line-height: 20px;
    }
  }

  &.whiteFooter {
    background-color: $color-neutrals-100-white;
  }
}

.stickyFooterEnabled {
  margin-bottom: grid-gap(5);
}
