@use "sass:math"; @import "variables.scss";
.label {
  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
  line-height: 20px;
  margin-bottom: grid-gap(0.5);
}

.sliderInputWrapper {
  :global(.rc-slider-track) {
    height: grid-gap(0.5);
    background-color: $color-brand-600-primary-blue;
  }

  :global(.rc-slider-step) {
    height: grid-gap(0.5);
    cursor: pointer;
  }

  :global(.rc-slider-rail) {
    height: grid-gap(0.5);
    border: 0.5px solid $color-neutrals-400-cold-grey;
    background-color: $color-neutrals-100-white;
    border-radius: 4px;
  }

  :global(.rc-slider-handle) {
    width: 24px;
    height: 24px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    background-color: $color-brand-600-primary-blue;
    border-radius: 100%;
    margin-top: -(grid-gap(0.5));
    cursor: pointer;
    border: none;
  }

  :global(.rc-slider-dot) {
    display: none;
  }

  :global(.rc-slider-mark) {
    top: grid-gap(1.5);
  }

  :global(.rc-slider-mark-text) {
    font-size: $font-size-normal;
    color: $color-neutrals-700-dark-grey;
    font-weight: $font-weight-semi-bold;
    text-align: center;
    cursor: pointer;
  }

  :global(.rc-slider-disabled) {
    background-color: transparent;

    :global(.rc-slider-handle) {
      background-color: $color-neutrals-500-grey;
    }

    :global(.rc-slider-track) {
      background-color: $color-neutrals-300-warm-grey;
    }
  }
}
