@use "sass:math"; @import "variables.scss";
.selectedFilters {
  min-height: 32px;
  display: flex;
  margin-top: grid-gap(0.5);

  // For ads ui kit overriding purpose
  [class^="pill__"] {
    > span {
      &:not([class^="pill__"]) {
        max-width: 50px;

        @include media(">=screen-xxl") {
          max-width: 60px;
        }
      }
    }
  }

  .removeFiltersButton {
    border: none;

    [class^="ripple__"] {
      display: flex;
      align-items: center;

      span {
        color: $color-neutrals-700-dark-grey;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &[class*="disabled__"] {
      background: transparent;

      [class^="ripple__"] {
        span {
          color: $color-neutrals-500-grey;
        }
      }
    }
  }
}

.pillsList {
  max-width: calc(100% - 132px);
  margin: 0 grid-gap(0.5) 0 0;
}
