@use "sass:math"; @import "variables.scss";
.container {
  position: relative;
  max-width: 143px;
}

.title {
  color: $color-neutrals-800-dark;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.value {
  @include f-grotesk("demi");

  font-size: 28px;
  font-weight: $font-weight-semi-bold;
  line-height: 32px;

  .small {
    font-size: $font-size-extra-large;
    padding-left: 2px;
  }
}

.description {
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-normal;
  line-height: 20px;
  padding-top: grid-gap(0.25);
  position: relative;
  text-align: center;

  &::before {
    display: block;
    content: "";
    height: 1px;
    width: 49px;
    background-color: $color-neutrals-500-grey;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
