@use "sass:math"; @import "variables.scss";
.link {
  color: $color-brand-600-primary-blue;
}

.label {
  margin-bottom: grid-gap(0.75);
}

.creativeSectionAdditionalInfo {
  width: 100%;
  display: flex;
  align-items: start;
  gap: grid-gap(2);
  font-size: $font-size-normal;
  margin-top: grid-gap(1.5);
  color: $color-neutrals-700-dark-grey;
  line-height: 20px;

  &ListedWebsites {
    [class*="publisherLogo"] {
      width: 32px;
      height: 32px;
    }

    [class*="excessIndicatorIcon"] {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
}
