@use "sass:math"; @import "variables.scss";
.input {
  width: 100%;
  height: 100%;

  &::placeholder {
    color: $color-neutrals-600-medium-grey;
  }

  input {
    box-sizing: border-box;
    min-height: unset;
  }

  [class^="title"] {
    line-height: unset;
  }

  [class*="inputContainer"] {
    height: 100%;
  }
}
