@use "sass:math"; @import "variables.scss";
.title {
  margin-top: grid-gap(1.25);

  @include source-sans-pro("regular");

  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  line-height: 20px;
  padding-bottom: grid-gap(0.75);
  display: flex;
  color: $color-neutrals-700-dark-grey;
  border-bottom: 1px $color-neutrals-400-cold-grey;

  div:nth-child(2) {
    flex: 1;
    margin-left: 24px;
  }
}
