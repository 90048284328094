@use "sass:math"; @import "variables.scss";
.paymentContainer {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  padding: grid-gap(1);
  grid-gap: grid-gap(1.5);

  @include media(">=screen-xs") {
    grid-template-areas:
      "aside content"
      "aside content";
    grid-auto-columns: 1.3fr 2.3fr;
    padding: 0;
    grid-gap: 0;
  }
}

.paymentSummary {
  display: flex;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  flex-flow: column;
  background: $color-brand-50-blue;
  padding: grid-gap(1);
  border-radius: 6px;

  &::before {
    content: "";
    width: 0;
    border-bottom: 65px solid $color-brand-100-blue;
    border-right: 235px solid transparent;
    left: 0;
    bottom: 0;
    position: absolute;
    display: none;
  }

  @include media(">=screen-xs") {
    background-color: $color-brand-50-blue;
    box-shadow: none;
    border-radius: 0;
    align-items: flex-start;
    padding: grid-gap(7) grid-gap(1) grid-gap(5);
    grid-area: aside;

    &::before {
      display: block;
    }
  }

  @include media(">=screen-md") {
    padding-inline: grid-gap(2.5);
  }
}

@include media(">=screen-xs") {
  .paymentFormContainer {
    padding: grid-gap(2);
  }

  .paymentFormContainer {
    min-height: 307px;
    padding-top: 0;
  }
}
