@use "sass:math"; @import "variables.scss";
.campaignContent {
  display: flex;
  flex-direction: column;
  padding: grid-gap(1);
  background: $color-brand-50-blue;
  border-radius: 6px;
  position: relative;
  flex-basis: 100%;
  transition: 0.2s ease-in-out;
  z-index: 20;
  margin: grid-gap(0.25);
  border: 2px solid transparent;

  @include media(">screen-xs") {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background: $color-warning-400-red;
    border-radius: 8px;
    transform: translate(-40%, -40%);
    z-index: 30;
  }

  .contentSection {
    display: flex;
    flex-direction: column;

    @include media(">screen-xs") {
      max-width: 60%;
    }

    .campaignInfo {
      display: flex;
      font-size: $font-size-small;
      line-height: 16px;
      color: $color-neutrals-700-dark-grey;

      .id {
        padding-right: grid-gap(0.75);
        border-right: 1px solid $color-neutrals-400-cold-grey;
      }

      .period {
        padding-left: grid-gap(0.75);
      }
    }

    .name {
      margin-top: grid-gap(0.5);
      margin-bottom: grid-gap(0.25);
      font-size: $font-size-large;
      line-height: 125%;
      font-weight: $font-weight-semi-bold;
      color: $color-neutrals-800-dark;
    }

    .description {
      font-size: $font-size-normal;
      line-height: 20px;
      font-weight: $font-weight-normal;
      color: $color-neutrals-700-dark-grey;

      .expire {
        display: inline;

        @include media(">screen-xs") {
          text-transform: lowercase;
        }
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: grid-gap(0.25) grid-gap(1);
    background: $color-brand-100-blue;
    border-radius: 6px;
    font-size: $font-size-normal;
    font-weight: $font-weight-semi-bold;
    line-height: 24px;
    color: $color-brand-accent-blue;
    width: fit-content;

    @include media("<=screen-xs") {
      margin-top: grid-gap(1.5);
    }

    .arrowIcon {
      margin-left: grid-gap(0.5);

      g {
        stroke: $color-brand-accent-blue;
        stroke-width: 3;
      }
    }
  }

  &:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  &:focus-within {
    border-color: $color-brand-200-tertiary-blue;
  }
}

.dot::before {
  content: "";
}
