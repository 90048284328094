@use "sass:math"; @import "variables.scss";
.icon {
  width: 16px;
  height: 16px;
}

.tooltipIcon {
  width: 24px;
  height: 24px;
}
