@use "sass:math"; @import "variables.scss";
.title {
  color: $color-neutrals-800-dark;
  line-height: 24px;
  font-size: 18px;
  margin-bottom: grid-gap(1);
  font-weight: $font-weight-bold;
}

.sectionTitle {
  color: $color-neutrals-800-dark;
  line-height: 16px;
  text-transform: uppercase;
  font-size: $font-size-small;
  margin-bottom: grid-gap(1.5);
  border-bottom: 1px solid $color-neutrals-400-cold-grey;
  padding-bottom: grid-gap(0.5);
}

.value {
  color: $color-neutrals-800-dark;
  line-height: 24px;
  margin-bottom: grid-gap(0.25);
  word-break: break-word;

  &::after {
    content: none;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: grid-gap(2);
    padding-right: grid-gap(1);
  }
}

.button {
  margin-top: grid-gap(0.25);
  min-width: 110px;
}

.notification {
  display: flex;
  align-items: center;
}

@include media(">=screen-xs") {
  .content {
    .item {
      width: 50%;
    }
  }
}
