@use "sass:math"; @import "variables.scss";
.loginButton {
  align-self: baseline;

  @include media(">=screen-xs") {
    align-self: center;
    margin-left: grid-gap(1);
  }

  .arrowIcon {
    width: 9px;
    margin-left: grid-gap(0.5);

    :global(path) {
      fill: $color-neutrals-700-grey;
    }
  }
}
