@use "sass:math"; @import "variables.scss";
.customTabs {
  height: 48px;
  width: fit-content;
  box-sizing: border-box;

  div[class^="tabItemsWrapper"] {
    height: 100%;
  }

  div[class^="tabItem"] {
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
