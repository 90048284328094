@use "sass:math"; @import "variables.scss";
.genericPreview {
  background: $color-neutrals-200-light-grey;
  border-radius: 4px;
  overflow: hidden;
  border: 1px dashed $color-neutrals-400-cold-grey;
}

.image {
  padding: grid-gap(0.125);
  max-width: calc(100% - 4px);
  height: auto;
}

.imagePlaceholder {
  display: flex;
  height: 180px;
  align-items: center;
  justify-content: center;
  background: $color-neutrals-200-light-grey;
  border-bottom: 1px dashed $color-neutrals-400-cold-grey;

  .icon g {
    stroke: $color-neutrals-500-grey;
  }
}

.content {
  padding: grid-gap(0.5) grid-gap(1) grid-gap(1);
  color: $color-neutrals-800-dark;
}

.sponsoredBy {
  font-size: 14px;
  display: inline-flex;
  padding: grid-gap(0.125) grid-gap(0.375);
  margin-bottom: grid-gap(1);
  margin-left: grid-gap(-0.375);
  border-radius: 2px;
  background: $color-system-status-bg-200-caution;
}

.title {
  font-weight: 600;
}

.title,
.description {
  margin-bottom: grid-gap(0.75);
}

.columns {
  display: flex;
  justify-content: space-between;

  .columnLeft {
    padding-right: grid-gap(2);
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: grid-gap(1);
}

.callToAction {
  display: inline-flex;
  padding: 0 grid-gap(1);

  span {
    display: block !important;
  }
}

.logo {
  max-height: 40px;
  margin-left: auto;
  // Fix for preview on list
  width: auto !important;
}
