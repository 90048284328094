@use "sass:math"; @import "variables.scss";
.number {
  @include source-sans-pro("semi-bold");

  color: $color-brand-600-primary-blue;
}

.icon {
  width: 16px;
  height: 16px;
}

.websiteName {
  @include source-sans-pro("semi-bold");

  color: $color-neutrals-800-medium-dark;
}
