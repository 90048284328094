@use "sass:math"; @import "variables.scss";
.currentTime {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 2px dashed $color-neutrals-700-dark-grey;
  left: 28px;
  transform: translateX(-50%);
}

.tickerWrapper {
  position: absolute;
  z-index: 5;
  height: auto;
  width: 1px;
  top: 0;
  bottom: 0;

  &::before {
    display: block;
    content: "";
    position: sticky;
    top: 70px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5.5px 0;
    border-color: $color-neutrals-700-dark-grey transparent transparent transparent;
    transform: translateX(-55%);
  }
}
