@use "sass:math"; @import "variables.scss";
.tabsWrapper {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.5);
  margin-top: grid-gap(0.75);

  @include media(">=screen-xs") {
    flex-direction: row;
    gap: grid-gap(1.5);
  }
}

.tab {
  width: 100%;

  div[class*="tabItemsWrapper__"] {
    width: 100%;
    display: flex;
  }

  div[class*="tabItem__"] {
    width: 100%;
    min-width: 24px;
    justify-content: center;
  }

  div[class*="active"] {
    svg path {
      stroke: $color-brand-600-primary-blue;
    }
  }

  @include media(">=screen-xs") {
    width: 190px;
  }
}
