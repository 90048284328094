@use "sass:math"; @import "variables.scss";
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .iconWrapper {
    background-color: $color-caution-100;
    border-radius: 50%;
    padding: grid-gap(0.75);
    display: flex;
    margin-bottom: grid-gap(1);

    .alertIcon {
      width: 16px;
      height: 16px;

      path {
        fill: $color-caution-500;
      }
    }
  }

  .title {
    @include f-grotesk("demi");

    font-size: 18px;
    line-height: 24px;
    margin-bottom: grid-gap(0.25);
  }

  .text {
    @include source-sans-pro("regular");

    text-align: center;
    font-size: $font-size-large;
    margin-bottom: grid-gap(2);
    color: $modal-text-color;
    line-height: 24px;

    &.noMargin {
      margin-bottom: 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .cancelButton,
    .endButton,
    .backButton.smallSpace {
      width: 100%;

      div[class^="ripple__"] {
        padding: 0 grid-gap(0.5);
      }
    }

    .backButton {
      margin-bottom: $grid-gap;
      margin-right: 0;
      background-color: $color-brand-100-blue;
      color: $color-brand-accent-blue;
      border: 0;
    }
  }
}

@include media(">=screen-xs") {
  .modal {
    .buttons {
      flex-direction: row;
      width: 100%;

      .backButton {
        margin-right: $grid-gap;
        margin-bottom: 0;
      }
    }
  }
}
