@use "sass:math"; @import "variables.scss";
.signOutWrapper {
  .signOut {
    width: 100%;
    line-height: 24px;
    padding: grid-gap(0.5) grid-gap(0.75);
    text-align: left;
    font-size: $font-size-large;
    color: $color-neutrals-700-grey;

    &:hover {
      background-color: $color-brand-100-blue;
      color: $color-neutrals-800-medium-dark;
    }

    &:focus {
      box-shadow: 0 0 0 2px $color-brand-300-blue;
    }

    & > div {
      padding: 0 !important;
      display: flex;
      align-items: center;

      span[class^="zIndex"] {
        font-weight: $font-weight-normal;
      }
    }
  }
}
