@use "sass:math"; @import "variables.scss";
.adFormatSection {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(1, 1fr);
  padding: grid-gap(3.5) grid-gap(1) grid-gap(5.5);

  h2 {
    @include f-grotesk("demi");

    font-size: $font-size-extra-huge;
    line-height: 32px;
    color: $color-neutrals-800-dark;
    margin-bottom: grid-gap(0.5);
  }

  @include media(">=screen-xs") {
    padding-bottom: grid-gap(5.5);

    h2 {
      @include f-grotesk("bold");

      font-size: 40px;
      line-height: 48px;
    }
  }

  @include media(">=screen-md") {
    padding: grid-gap(7) grid-gap(2.5) grid-gap(6.5);
    grid-template-columns: repeat(1, grid-gap(59));
  }

  @include media(">=screen-xl") {
    padding: grid-gap(7) 0 grid-gap(6.5);
    grid-template-columns: repeat(1, grid-gap(73));
  }
}

.adFormatPreview {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;

  p {
    @include source-sans-pro("regular");

    font-size: $font-size-large;
    line-height: 24px;
    color: $color-neutrals-700-dark-grey;
  }

  @include media(">=screen-xs") {
    grid-template-columns: repeat(1, grid-gap(29.25));
    margin: 0 auto;
  }

  @include media(">=screen-md") {
    grid-template-columns: grid-gap(29.25) 1fr;
    width: 100%;
    text-align: left;
  }
}

.customRadioButton {
  display: flex !important;
  align-items: center;
  min-width: grid-gap(8.75);
  height: grid-gap(3);
  border-radius: 6px;
  margin: grid-gap(0.5) 0;

  span[class^="radio__"] {
    min-width: grid-gap(1);
    min-height: grid-gap(1);
  }
}

.adFormatLabel {
  display: flex;
  align-items: center;

  h3 {
    @include source-sans-pro("regular");

    font-size: $font-size-large;
    line-height: 24px;
    color: $color-neutrals-800-dark;
  }

  svg {
    margin-right: grid-gap(0.5);
    min-width: grid-gap(1.5);
    min-height: grid-gap(1.5);

    path {
      stroke: $color-neutrals-800-dark;
    }
  }
}

.adFormatMockup {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-left: grid-gap(3.625);

  img {
    max-width: 100%;
    max-height: grid-gap(25);
  }
}

.adFormatInfo {
  border-radius: 6px;
  border: 1px solid $color-neutrals-400-cold-grey;
  margin-top: grid-gap(1.5);
  margin-bottom: grid-gap(1);
  padding: grid-gap(1.5);
  text-align: left;

  h3 {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-extra-large;
  }

  p {
    margin-top: grid-gap(0.5);
    margin-bottom: grid-gap(0.875);
    height: grid-gap(8.75);
  }

  .websiteLogo {
    width: grid-gap(2);
    height: grid-gap(2);
    border-radius: 50%;
  }

  @include media(">=screen-xs") {
    .websiteLogo {
      margin-right: grid-gap(1.25);
    }
  }
}

.customCarousel {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: grid-gap(1);
  padding: grid-gap(1.5) 0;

  button[class*="slick-arrow"]::after {
    box-shadow: none;
  }

  div[class^="slick-slide"] {
    padding-left: grid-gap(1);
  }

  button[class*="slick-prev"] {
    left: -5px;
  }

  button[class*="slick-next"] {
    right: -4px;
  }

  &::before,
  &::after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 1px;
    background-color: $color-neutrals-100-white;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.11);
  }

  &::before {
    left: 4px;
  }

  &::after {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    right: 8px;
  }
}
