@use "sass:math"; @import "variables.scss";
.providerSelect {
  max-width: 480px;
  margin: grid-gap(4) auto grid-gap(2);
}

.heading {
  @include f-grotesk("bold");

  font-size: 18px;
  color: $color-neutrals-800-dark;
  text-align: center;
  margin-bottom: grid-gap(1);
}

.provider {
  width: 360px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-neutrals-400-cold-grey;
  border-radius: 6px;
  position: relative;
  background-color: $color-neutrals-100-white;
  overflow: hidden;
  transition: all 0.2s;
  padding: grid-gap(1) 0;
  margin: 0 auto;

  &:hover {
    border-color: $color-brand-500-secondary-blue;
  }
}

.providerLogo {
  height: 100%;
}

.listItem {
  margin-bottom: grid-gap(1);
}
