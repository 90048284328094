@use "sass:math"; @import "variables.scss";
.content {
  @extend %baseGrid;

  grid-gap: grid-gap(1.5);
  grid-template-columns: 1fr;
  grid-template-areas: "headerStickyWrapper" "itemsWrapper";

  .headerStickyWrapper {
    grid-area: headerStickyWrapper;

    .stickyHeader {
      position: sticky;
      top: grid-gap(3.5);
      bottom: auto;
    }
  }

  .itemsWrapper {
    grid-area: itemsWrapper;
    display: grid;
    grid-gap: grid-gap(1.5);
    grid-template-columns: 1fr;
    grid-template-areas: "leftSection" "rightSection";
  }

  .leftSection {
    grid-area: leftSection;
  }

  .rightSection {
    grid-area: rightSection;

    div[class^="trackWrapper__"]:first-of-type {
      margin-top: 0;
    }

    @include media(">=screen-md") {
      div[class^="trackWrapper__"]:first-of-type {
        margin-top: grid-gap(11.5);
      }
    }
  }

  h2 {
    @include f-grotesk("demi");

    font-size: $font-size-extra-huge;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: $color-neutrals-800-dark;
    margin-bottom: grid-gap(0.5);
    text-align: left;
  }

  @include media(">=screen-xs") {
    h2 {
      @include f-grotesk("bold");

      font-size: 40px;
      line-height: 48px;
    }
  }

  @include media(">=screen-md") {
    .itemsWrapper {
      grid-template-columns: repeat(2, grid-gap(23.75));
      grid-template-areas: "leftSection rightSection";
    }

    .headerStickyWrapper {
      max-width: grid-gap(23.75);
    }
  }

  @include media(">=screen-lg") {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "headerStickyWrapper itemsWrapper itemsWrapper";

    h2 {
      text-align: left;
    }
  }
}
