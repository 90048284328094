@use "sass:math"; @import "variables.scss";
.title,
.value {
  color: $color-neutrals-800-dark;
  line-height: 16px;
}

.title {
  text-transform: uppercase;
  font-size: $font-size-small;
  margin-bottom: grid-gap(0.5);
}

.value {
  line-height: 24px;
}
