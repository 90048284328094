@use "sass:math"; @import "variables.scss";
.icon {
  stroke: $color-white;
}

.tooltip {
  div[class^="notification__"] {
    margin-top: grid-gap(-1);
  }
}

.status {
  div:last-of-type {
    @include source-sans-pro("semi-bold");

    font-size: $font-size-small;
  }
}
