@use "sass:math"; @import "variables.scss";
.filterWrapper {
  display: flex;
  flex-direction: column;
}

.filterName {
  font-size: $font-size-small;
  line-height: 16px;
  font-weight: $font-weight-normal;
  color: $color-neutrals-700-dark-grey;
  text-transform: uppercase;
  margin-bottom: 4px;
}
