@use "sass:math"; @import "variables.scss";
.button {
  margin-left: grid-gap(-1);
  margin-bottom: grid-gap(0.75);

  &.custom {
    color: $color-neutrals-800-dark;
    font-size: $font-size-large;

    span[class^="buttonContent__"] {
      font-weight: $font-weight-normal;
    }

    .icon {
      stroke: $color-neutrals-800-dark;

      &.visible {
        transform: rotate(-180deg);
      }
    }
  }
}

.details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-bottom: grid-gap(0.75);
}

@include media(">=screen-xs") {
  .column {
    flex-basis: 50%;
  }
}
