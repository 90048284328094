@use "sass:math"; @import "variables.scss";
.stepNavigation {
  height: 79px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid $color-neutrals-400-cold-grey;
  background-color: $color-neutrals-100-white;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
}

.wrapper {
  @extend %ultraLargeWrapper;

  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepLabel {
  width: 100%;
  margin-bottom: grid-gap(0.75);
  font-size: $font-size-normal;
  color: $color-neutrals-700-dark-grey;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  text-align: end;

  .buttonsContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;

    .nextButton {
      width: fit-content;
    }

    @include media(">=screen-xs") {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;

      .nextButton {
        margin: 0 0 0 grid-gap(1);
      }
    }
  }
}

.spinner {
  width: 24px;
  height: 24px;
  margin: grid-gap(0.5) auto 0 auto;
  background: $color-neutrals-500-grey;
  background: linear-gradient(
    to right,
    $color-neutrals-500-grey 10%,
    rgba(255, 255, 255, 0) 42%
  );
}

.spinner::before {
  background: $color-neutrals-500-grey;
}

.spinner::after {
  background: $color-neutrals-200-light-grey;
}

.spinnerSecondary {
  margin-top: 6px;

  &.largeSpace {
    margin-top: 8px;
  }
}

.isLoading {
  .spinnerSecondary::after {
    background: $color-neutrals-400-cold-grey;
  }
}
