@use "sass:math"; @import "variables.scss";
.listLoader {
  display: table-row-group;
  margin: grid-gap(4.875) auto 0;
  max-width: $max-content-width;
}

.elementLoader {
  display: table-row;
  margin-bottom: grid-gap(0.75);
  min-height: 72px;
  background-color: $color-neutrals-100-white;
  position: relative;

  &:nth-child(odd) {
    background-color: $color-neutrals-100-white;
  }

  &:nth-child(even) {
    background-color: $color-brand-25-blue;
  }

  > div {
    display: table-cell;
    vertical-align: middle;
    padding: grid-gap(0.75) grid-gap(2) grid-gap(0.75) grid-gap(1.5);
    border-right: 1px solid $color-brand-100-blue;
  }

  .quickActionButton {
    margin-left: grid-gap(5.5);
  }

  .thumbnailAndName {
    align-items: center;
    vertical-align: bottom;
    padding-right: grid-gap(1.5);
    position: sticky;
    top: 0;
    background-color: inherit;

    @include media("<=screen-sm") {
      padding-left: grid-gap(1);
    }

    @include media(">=screen-xxl") {
      padding-left: 0;
    }

    .thumbnailAndNameContent {
      display: flex;
      align-items: center;

      @include media(">=screen-sm") {
        padding-left: grid-gap(1);
      }

      @include media(">=screen-lg") {
        padding-left: grid-gap(1.5);
      }

      @include media(">=screen-xxl") {
        padding-left: 0;
      }
    }
  }

  .thumbnail {
    margin-right: grid-gap(0.5);
  }

  .icon {
    margin-right: 5px;
  }

  .progress {
    display: flex;
    height: 48px;
    align-items: center;

    .percentage {
      margin-right: grid-gap(0.5);
    }
  }
}
