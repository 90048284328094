@use "sass:math"; @import "variables.scss";
.loaderMobile {
  display: flex;
  flex-direction: column;
  gap: grid-gap(1.5);
  padding: grid-gap(1.5) grid-gap(1);
}

.info {
  display: flex;
  flex-direction: column;
  gap: grid-gap(0.5);
  padding: grid-gap(0.75);
  background-color: $color-brand-50-blue;
}

.titleBlock {
  margin-bottom: grid-gap(0.75);
}

.blocksGroup {
  display: flex;
  flex-direction: row;
  gap: grid-gap(1);
}
