@use "sass:math"; @import "variables.scss";
.infoLink {
  b {
    font-weight: $font-weight-bold;
  }
}

.linkContent {
  @include source-sans-pro("semi-bold");

  color: $color-brand-700-blue;
}

.descriptionText {
  display: block;
  word-wrap: anywhere;

  &:first-of-type {
    margin-bottom: grid-gap(0.5);
  }
}

.label {
  font-style: italic;
}

.email {
  text-decoration: none;
  color: $color-neutrals-100-white;
  cursor: pointer;
}
