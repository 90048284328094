@use "sass:math"; @import "variables.scss";
.campaignBlock {
  box-sizing: border-box;
  padding: grid-gap(0.875);
  background-color: $color-neutrals-100-white;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    border: 2px solid $color-brand-300-blue;
  }
}

.statusRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: grid-gap(1);

  div[class*="statusName__"] {
    font-weight: $font-weight-semi-bold;
  }
}

.campaignName {
  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
  text-align: left;
}

.dateIdRow {
  font-size: $font-size-small;
  line-height: 16px;
  color: $color-neutrals-700-dark-grey;
  margin-top: grid-gap(0.25);
  text-align: left;
}

.spacer {
  color: $color-neutrals-400-cold-grey;
  margin: 0 grid-gap(0.75);
}
