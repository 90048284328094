@use "sass:math"; @import "variables.scss";
.tooltip {
  .notification {
    margin-top: grid-gap(0.25);

    &.fullSize {
      max-width: none;
    }
  }
}

.searchWrapper {
  position: relative;

  div[class^="root__"] {
    width: 100%;
  }
}

.resetButton {
  position: absolute;
  top: 2px;
  right: 2px;
  background: transparent;
  border: 0;
  height: calc(100% - 4px);
  padding: 0 grid-gap(1.25);
  border-radius: 0 6px 6px 0;

  .closeIcon {
    width: 9px;
    height: 9px;
    fill: $color-neutrals-800-dark;
  }
}
