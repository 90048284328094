@use "sass:math"; @import "variables.scss";
.filterBox {
  @extend %ultraLargeWrapper;

  padding: grid-gap(1) 0 grid-gap(0.75);
  background-color: $color-white;
  z-index: 106;
}

@include media(">=screen-sm") {
  .filterBox {
    max-width: calc(100vw - 96px);
  }
}

@include media(">=screen-xl") {
  .filterBox {
    max-width: $max-content-width-ultra-large;
  }
}

.containerInner {
  display: flex;
  flex-direction: column;
}
