@use "sass:math"; @import "variables.scss";
.timelineTooltip {
  display: block;
  position: fixed;
  z-index: 9999;

  [class^="content__"] {
    margin-right: 0;
  }

  .moreInfoBoxText {
    white-space: nowrap;
  }
}
