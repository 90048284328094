@use "sass:math"; @import "variables.scss";
.paymentModal {
  width: 100%;
  max-width: 930px;
  min-width: 320px;
  padding: 0;

  @include media(">=screen-xs") {
    width: calc(100% - 16px);
  }

  @include media(">=screen-sm") {
    width: calc(100% - 32px);
  }
}

.closeButton {
  position: absolute;
  top: grid-gap(1.5);
  right: grid-gap(1.5);
  padding: grid-gap(0.4);
  z-index: 1;
  cursor: pointer;
  display: flex;

  &:hover {
    .modalCloseIcon {
      fill: $color-neutrals-600-medium-grey;
    }
  }

  &:focus {
    outline: none;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      left: grid-gap(-0.25);
      top: grid-gap(-0.25);
      padding: grid-gap(0.25);
      box-shadow: 0 0 0 2px $color-brand-200-tertiary-blue;
    }
  }
}

.closeIcon {
  fill: $color-neutrals-700-dark-grey;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
