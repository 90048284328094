@use "sass:math"; @import "variables.scss";
.tooltipTitle {
  text-transform: none;
  margin: 0 grid-gap(0.25) 0 0;
}

.linkClassName {
  display: flex;
  align-items: center;
}
