@use "sass:math"; @import "variables.scss";
.aspectRatioContainer {
  background: $color-neutrals-300-warm-grey;
  font-size: 40px;
  color: $color-neutrals-600-medium-grey;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;

  .image {
    width: 100%;
    max-width: 400px;
    height: auto;
    // Fix for creatives gallery on list
    border-radius: 0 !important;
  }
}
