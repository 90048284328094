@use "sass:math"; @import "variables.scss";
/* stylelint-disable no-descending-specificity */
div[class^="carouselContainer__"] {
  .customArrow {
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    background-color: $color-neutrals-100-white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    &::after {
      box-shadow: 0 0 0 2px $color-brand-400-blue;
      position: absolute;
      top: -3px;
      left: -3px;
      width: grid-gap(2);
      height: grid-gap(2);
      border-radius: 4px;
    }

    .icon {
      display: flex;
      fill: $color-brand-600-primary-blue;
      width: 12px;
      height: 12px;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: $color-brand-600-primary-blue;
      }
    }

    &:hover {
      background-color: $color-brand-600-primary-blue;

      .icon {
        fill: $color-neutrals-100-white;

        path {
          fill: $color-neutrals-100-white;
        }
      }
    }

    &:focus::after,
    &:focus-within::after {
      content: "";
    }
  }

  .customArrowNext {
    right: 16px;

    .icon {
      margin-right: -1px;
    }
  }

  .customArrowPrev {
    left: 16px;

    .icon {
      transform: rotate(180deg) translate(50%, 50%);
      margin-left: -1px;
    }
  }

  .customArrowModal {
    &.customArrowNext {
      right: -44px;

      @include media("<screen-xs") {
        right: 0;
      }

      .icon {
        margin-right: 0;
      }
    }

    &.customArrowPrev {
      left: -44px;

      @include media("<screen-xs") {
        left: 0;
      }

      .icon {
        transform: rotate(180deg) translate(50%, 50%);
        margin-left: 0;
      }
    }

    &.customArrow {
      background-color: transparent;
      box-shadow: none;
      top: 50%;
      bottom: auto;

      .icon {
        fill: $color-neutrals-100-white;
        width: 16px;
        height: 16px;

        path {
          fill: $color-neutrals-100-white;
        }
      }

      &:hover {
        background-color: transparent;

        .icon path {
          fill: $color-brand-600-primary-blue;
        }
      }
    }
  }
}
