@use "sass:math"; @import "variables.scss";
.loader {
  width: 803px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  @include spinnerLinearGradient($color-neutrals-700-dark-grey);

  margin-bottom: grid-gap(1);

  &::after {
    background: $color-white;
  }

  &::before {
    background-color: $color-neutrals-700-grey;
  }
}
