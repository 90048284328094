@use "sass:math"; @import "variables.scss";
.label {
  margin-bottom: grid-gap(0.75);
}

.icon {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.labelTooltipNotification {
  min-width: 378px;
}

.notification {
  display: flex;
  align-items: center;

  div[class^="content"] {
    margin: 0;
  }
}

.warningList {
  margin-left: grid-gap(1.75);
  list-style-type: disc;
}

.loader {
  display: flex;
  padding: grid-gap(0.25) 0;
  line-height: 24px;
  align-items: center;
  color: $color-neutrals-700-dark-grey;

  .spinner {
    width: 20px;
    height: 20px;
    background-color: $color-brand-50-blue;
    margin-left: grid-gap(0.5);
  }
}

.toastNotification {
  width: 856px;
}
