@use "sass:math"; @import "variables.scss";
.fieldCounter {
  @include f-grotesk("demi");

  width: grid-gap(1.5);
  line-height: grid-gap(1.5);
  background: $color-brand-600-primary-blue;
  color: $color-neutrals-100-white;
  font-size: $font-size-large;
  text-align: center;
  border-radius: 50%;
  margin-right: grid-gap(0.5);

  &::before {
    counter-increment: field-counter;
    content: counter(field-counter);
  }
}

.field {
  padding-bottom: grid-gap(2);
}

.titleWrapper {
  display: flex;
  align-items: center;

  .title {
    @include source-sans-pro("semi-bold");

    color: $color-neutrals-800-dark;
    line-height: 24px;
  }
}

.description {
  font-size: $font-size-large;
  line-height: 24px;
  margin-bottom: grid-gap(0.75);
  color: $color-neutrals-700-dark-grey;
}
